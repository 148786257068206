import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconMegaphoneComponent = ({
  width = 24,
  height = 24,
  strokeWidth = '2',
  fillColor = '#713AE0',
  strokeColor = '#713AE0',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.2461 8.27405C22.4356 8.27405 23.3999 9.2339 23.3999 10.4178C23.3999 11.6017 22.4356 12.5615 21.2461 12.5615V8.27405Z"
      fill={fillColor}
    />
    <path
      d="M19.8218 2.05831L9.3999 5.5944L4.38844 5.97813C3.26629 6.06388 2.3999 6.99533 2.3999 8.11543V12.1842C2.3999 13.3043 3.26629 14.2358 4.38844 14.3215H4.39113L4.95167 19.3422C5.03136 20.0582 5.63983 20.6 6.36406 20.6C7.14859 20.6 7.78506 19.9665 7.78506 19.1857V14.5809L9.40044 14.7047L19.8224 18.2408C20.5207 18.4777 21.2466 17.9611 21.2466 17.2263V3.07284C21.2461 2.33807 20.5202 1.82089 19.8218 2.05831Z"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
