import { CourseModel } from '../../features';
import { Nullable } from '../../models';

export enum ProductMetaTypes {
  product = 'Product',
  rating = 'AggregateRating',
  offer = 'AggregateOffer',
}

export type ProductMetaModel = {
  '@context': string;
  '@type': ProductMetaTypes.product;
  name: string;
  url: Nullable<string>;
  image: string;
  description: string;
  aggregateRating: {
    '@type': ProductMetaTypes.rating;
    ratingValue: string;
    ratingCount: string;
    bestRating: string;
  };
  offers: {
    '@type': ProductMetaTypes.offer;
    lowPrice: string;
    priceCurrency: string;
  };
};

export type HeadProductMetaModel = {
  course: CourseModel;
};
