import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconCheckedComponent = ({
  width = 24,
  height = 24,
  strokeWidth = '2',
  strokeColor = '#713AE0',
}: IconProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <path
      stroke={strokeColor}
      strokeMiterlimit="10"
      strokeWidth={strokeWidth}
      d="M19.712 21H5.087A1.687 1.687 0 0 1 3.4 19.312V4.688C3.4 3.756 4.155 3 5.087 3h14.625c.933 0 1.688.755 1.688 1.688v14.625c0 .932-.755 1.687-1.688 1.687Z"
    />
    <path
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth={strokeWidth}
      d="M8.4 12.278 10.86 14.5l5.539-5"
    />
  </svg>
);
