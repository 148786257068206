import { NonRequiredString } from '../../models';
import { ReactElement } from 'react';

export type AccordionModel = {
  title: ReactElement | string;
  content: ReactElement | NonRequiredString;
};

export enum AccordionVersions {
  default = 'default',
  v2 = 'v2',
  v3 = 'v3',
}

export enum AccordionSpoilerIconVersions {
  v1 = 'v1',
  v2 = 'v2',
  v3 = 'v3',
}

export type AccordionVersionModel = keyof typeof AccordionVersions;

export type AccordionSpoilerIconVersionModel =
  keyof typeof AccordionSpoilerIconVersions;

export type AccordionContainerProps = {
  items: AccordionModel[];
  autoClose?: boolean;
  version?: AccordionVersionModel;
  spoilerIconVersion?: AccordionSpoilerIconVersionModel;
};
