import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconBrowserTabComponent = ({
  width = 24,
  height = 24,
  fillColor = '#201926',
  strokeColor = '#201926',
  strokeWidth = '2',
}: IconProps): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
    >
      <path
        stroke={strokeColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth={strokeWidth}
        d="M21.21 5.81v12.836c0 1.134-.942 2.054-2.105 2.054H4.895c-1.163 0-2.105-.92-2.105-2.054V5.81c0-1.134.942-2.053 2.105-2.053h14.21c1.163 0 2.106.92 2.106 2.053Z"
      />
      <path
        fill={fillColor}
        d="M19.105 3.5H4.895C3.299 3.5 2 4.767 2 6.324V7.35c0 .425.354.77.79.77h18.42a.78.78 0 0 0 .79-.77V6.324C22 4.767 20.701 3.5 19.105 3.5ZM4.895 6.58a.78.78 0 0 1-.79-.77.78.78 0 0 1 .79-.77.78.78 0 0 1 .79.77.78.78 0 0 1-.79.77Zm2.631 0a.78.78 0 0 1-.79-.77.78.78 0 0 1 .79-.77.78.78 0 0 1 .79.77.78.78 0 0 1-.79.77Z"
      />
    </svg>
  );
};
