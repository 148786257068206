import React, { ReactElement } from 'react';
import { IconProps } from '../../../features';

export const IconForChildrenComponent = ({
  width = 24,
  height = 24,
  fillColor = '#713AE0',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.023 3.333a1.25 1.25 0 0 0-.532.108L4.907 9.93a1.25 1.25 0 0 0-.742 1.122 1.25 1.25 0 0 0 0 .376v17.497a1.25 1.25 0 0 0 .742 1.142l14.414 6.416a1.25 1.25 0 0 0 1.345.005l14.425-6.42a1.25 1.25 0 0 0 .74-1.143V11.074a1.25 1.25 0 0 0-.74-1.142L20.508 3.44a1.25 1.25 0 0 0-.485-.108Zm-.025 2.62 11.55 5.14-11.554 5.045-11.656-4.995 11.66-5.19Zm.026 1.964c-1.68-.009-3.362.29-4.643.9-2.563 1.219-2.566 3.225-.008 4.47 1.935.943 3.534 1.006 4.7 1.05.836.033 1.466-.266 1.408-.668-.058-.403-.781-.756-1.616-.79-.976-.037-1.513-.058-2.5-.538-1.297-.632-1.162-1.713.303-2.41 1.464-.696 3.71-.75 5.006-.118.988.48 1.025.74 1.092 1.21.059.403.783.756 1.618.789.836.032 1.465-.268 1.408-.671-.08-.563-.19-1.333-2.126-2.275-1.278-.623-2.96-.94-4.642-.95Zm13.308 5.125v15.072L21.248 33.49V18.32l12.084-5.277Zm-26.667.104 12.083 5.178V33.49L6.665 28.114V13.146Zm22.837 3.57a3.51 3.51 0 0 0-1.379.271l-3.125 1.25c-.69.276-1.25 1.06-1.25 1.75v9.167c0 .69.56 1.026 1.25.75l3.542-1.418c1.953-.78 3.542-3.005 3.542-4.957 0-.993-.41-1.729-1.07-2.108.412-.743.653-1.547.653-2.31 0-1.464-.892-2.365-2.163-2.396Zm-17.415.65c-.324-.035-.594.106-.726.4l-3.452 7.633c-.241.53.041 1.386.63 1.909.588.523 1.26.515 1.5-.018l.335-.74 4.1 1.822.336 1.039c.182.565.612 1.05 1.066 1.252.145.064.292.097.434.097.588 0 .87-.604.63-1.35l-3.45-10.699c-.178-.548-.599-1.043-1.065-1.25a1.145 1.145 0 0 0-.338-.094Zm16.442 2.04c.373.009.636.276.636.706 0 .573-.465 1.224-1.037 1.455l-.005.004-1.875.748v-2.083l1.875-.749c.144-.057.281-.084.406-.081Zm-16.104 2.606 1.11 3.442-2.22-.986 1.11-2.456Zm16.52 1.811c.374.01.637.274.637.705 0 .574-.468 1.228-1.042 1.458l-2.292.917v-2.084l1.866-.745.01-.003.416-.168c.144-.057.28-.083.405-.08Z"
        fill={fillColor}
      />
    </svg>
  );
};
