import React, { ReactElement } from 'react';
import { IconProps } from '@lerna-core';

export const IconProjectProductComponent = ({
  width = 40,
  height = 40,
  fillColor = '#713AE0',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fillColor}
      d="M20 3c-6.416.002-11.65 5.223-11.65 11.646 0 3.331 1.407 6.346 3.652 8.469.264.25.458.6.548 1.003l2.052 9.248a3.756 3.756 0 0 0 3.656 2.934h3.484a3.757 3.757 0 0 0 3.655-2.934l2.055-9.248v-.001a1.95 1.95 0 0 1 .546-1v-.002H28a11.638 11.638 0 0 0 3.65-8.468C31.65 8.223 26.416 3.002 20 3Zm0 2.498a9.129 9.129 0 0 1 9.154 9.148 9.114 9.114 0 0 1-2.87 6.653 4.439 4.439 0 0 0-1.27 2.278l-.975 4.395H15.96l-.975-4.397a4.446 4.446 0 0 0-1.27-2.276 9.112 9.112 0 0 1-2.87-6.652A9.129 9.129 0 0 1 20 5.498ZM16.517 30.47h6.966l-.522 2.354a1.238 1.238 0 0 1-1.219.977h-3.484c-.594 0-1.09-.398-1.22-.977l-.521-2.354Z"
    />
  </svg>
);
