import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconMarketingComponent = ({
  width = 24,
  height = 24,
  strokeColor = '#713AE0',
}: IconProps): ReactElement => (
  <svg
    height={height}
    width={width}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.75 17.25H5.25a2 2 0 0 1-2-2V3.75h17.5v11.5a2 2 0 0 1-2 2Z"
      stroke={strokeColor}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
    />
    <path
      d="M2.25 3.75h19.5M12 2.25v1.5M9.5 17.25l-2.25 4.5M14.5 17.25l2.25 4.5M10.25 13.25v-2.5M13.75 13.25v-5.5M17.25 13.25v-4M6.75 12.25v1"
      stroke={strokeColor}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
