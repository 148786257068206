import React, { ReactElement, useEffect, useRef, useState } from 'react';
import * as S from './fastLinksMenuMobile.styled';
import { useDispatch, useSelector } from 'react-redux';
import { PopupContainer, useTranslations } from '../../../../features';
import {
  FastLinksMenuMobileModel,
  FastLinksMenuTabs,
} from './fastLinksMenuMobile.model';
import { openFastLinksMenu } from '../fastLinksMenu.actions';
import {
  FastLinksMenuTabModel,
  FastLinksMenuTabsContainer,
} from '../fastLinksMenuTabs';
import { getFastLinksMenuTabs } from './fastLinksMenuMobile.helper';
import { SchoolsContainer } from './schools';
import { Nullable } from '../../../../models';
import { SpheresContainer } from './spheres';
import { getFastLinksMenuStatusSelector } from '../fastLinksMenu.selectors';
import { SearchContainer } from './search';

export const FastLinksMenuMobileContainer = ({
  hasSearch,
  filtersAccentColor,
}: FastLinksMenuMobileModel): ReactElement => {
  const dispatch = useDispatch();
  const translations = useTranslations();
  const { isOpened } = useSelector(getFastLinksMenuStatusSelector);
  const tabs = getFastLinksMenuTabs(translations);
  const wrapperRef = useRef<Nullable<HTMLDivElement>>(null);
  const [activeTab, setActiveTab] = useState<FastLinksMenuTabModel>(tabs[0]);

  const handleCloseFastLinksMenu = () => {
    dispatch(openFastLinksMenu(false));
  };

  const handleTabClick = (tab: FastLinksMenuTabModel) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    wrapperRef.current?.parentElement?.parentElement?.scroll(0, 0);
  }, [activeTab]);

  return (
    <PopupContainer
      handleClose={handleCloseFastLinksMenu}
      closePositionRight
      hasHeader
      isMobile
      cleanDOMOnClose={false}
      isOpened={isOpened}
    >
      <S.FastLinksMenuMobile ref={wrapperRef}>
        {hasSearch && <SearchContainer />}
        <FastLinksMenuTabsContainer
          items={tabs}
          activeItem={activeTab}
          handleTabClick={handleTabClick}
        />
        {activeTab.id === FastLinksMenuTabs.schools && <SchoolsContainer />}
        {activeTab.id === FastLinksMenuTabs.sphere && (
          <SpheresContainer filtersAccentColor={filtersAccentColor} />
        )}
      </S.FastLinksMenuMobile>
    </PopupContainer>
  );
};
