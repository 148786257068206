import React, { ReactElement } from 'react';
import { FilterDatePopupFooterStyled } from './popupFooter.styled';
import { ButtonColor, ButtonContainer } from '../../button';
import { PopupFooterModel } from './popupFooter.model';

export const PopupFooterContainer = ({
  resetButtonTitle,
  handleReset,
  applyButtonTitle,
  handleApply,
  applyDisabled,
}: PopupFooterModel): ReactElement => {
  return (
    <FilterDatePopupFooterStyled>
      <ButtonContainer
        title={resetButtonTitle}
        color={ButtonColor.transparent}
        onClick={handleReset}
      />
      <ButtonContainer
        title={applyButtonTitle}
        color={ButtonColor.purple}
        onClick={handleApply}
        disabled={applyDisabled}
      />
    </FilterDatePopupFooterStyled>
  );
};
