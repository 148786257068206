import styled from 'styled-components';
import { fontSettings } from '../../../styles';

export const CertificateTitle = styled.div`
  margin-bottom: 16px;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_28, theme.FONT)};
  font-weight: 700;
  text-align: center;
  margin-top: 32px;
`;
export const CertificateSubtitle = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  font-weight: 500;
  text-align: center;
  max-width: 500px;
  margin: 0 auto;

  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

export const LoaderDots = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 32px;
`;

export const LoaderDotsItem = styled.span`
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${(props): string => props.theme.COLOR.PRIMARY['500']};
  animation: dotElastic 1s infinite linear;

  &::before {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${(props): string => props.theme.COLOR.PRIMARY['500']};
  }

  &::before {
    animation: dotElasticBefore 1s infinite linear;

    @keyframes dotElasticBefore {
      0% {
        transform: scale(1);
      }
      15% {
        transform: scale(1.5);
      }
      30% {
        transform: scale(1);
      }
      100% {
        transform: scale(1);
      }
    }
  }

  :nth-child(2) {
    &::before {
      animation-delay: 0.1s;
    }
  }

  :nth-child(3) {
    &::before {
      animation-delay: 0.2s;
    }
  }
`;
