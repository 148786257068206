import { ReactElement, useContext } from 'react';
import Script from 'next/script';
import { HeadProductMetaModel } from './headProductMeta.model';
import { mapProductMetaTag } from './headProductMeta.helper';
import { useSelector } from 'react-redux';
import { getVendors } from '../../features';
import { useRouter } from 'next/router';
import { PresetsContext } from '../../contexts';

export const HeadProductMetaTags = ({
  course,
}: HeadProductMetaModel): ReactElement => {
  const vendors = useSelector(getVendors);
  const router = useRouter();
  const context = useContext(PresetsContext);
  const mediaPresets = context?.mediaPresets;
  const content = mapProductMetaTag(course, vendors, router, mediaPresets);

  return (
    // eslint-disable-next-line @next/next/no-before-interactive-script-outside-document
    <Script
      strategy="beforeInteractive"
      type="application/ld+json"
      id="Schema_Product"
    >
      {JSON.stringify(content)}
    </Script>
  );
};
