import styled from 'styled-components';
import { fontSettings, lineClamping } from '../../../../../../styles';

export const SpheresStaticItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
  margin-top: 16px;
`;

export const SpheresStaticItemImage = styled.div`
  height: 24px;
  width: 24px;
  flex: 0 0 auto;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const SpheresStaticItemTitle = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  font-weight: 500;
  ${lineClamping(1)};
`;

export const SpheresStaticItem = styled.div<{ filtersAccentColor?: string }>`
  display: flex;
  gap: 12px;
  padding: 12px;
  box-sizing: border-box;
  cursor: pointer;
  align-items: center;
  width: 100%;
  border-radius: 8px;

  &:hover {
    ${SpheresStaticItemTitle} {
      color: ${({ theme, filtersAccentColor }): string =>
        filtersAccentColor ? filtersAccentColor : theme.COLOR.PRIMARY['500']};
    }
  }

  &:not(:last-child) {
    margin-bottom: 4px;
  }
`;
