import React, { ReactElement } from 'react';
import { IconProps } from '@lerna-core';

export const IconEnvironmentDesignComponent = ({
  width = 40,
  height = 40,
  fillColor = '#713AE0',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fillColor}
      d="M17.917 8.333a2.102 2.102 0 0 0-2.084 2.084v.416h-9.45a2.917 2.917 0 1 0-.001 2.5h5.338A14.988 14.988 0 0 0 5.036 25h-.453A2.102 2.102 0 0 0 2.5 27.083v3.334c0 1.136.947 2.083 2.083 2.083h3.334A2.102 2.102 0 0 0 10 30.417v-3.334A2.102 2.102 0 0 0 7.917 25h-.381a12.486 12.486 0 0 1 8.297-10.949v.532c0 1.137.947 2.084 2.084 2.084h4.166a2.102 2.102 0 0 0 2.084-2.084v-.527c4.6 1.626 7.962 5.859 8.297 10.944h-.38A2.102 2.102 0 0 0 30 27.083v3.334c0 1.136.947 2.083 2.083 2.083h3.334a2.102 2.102 0 0 0 2.083-2.083v-3.334A2.102 2.102 0 0 0 35.417 25h-.443c-.271-4.881-2.904-9.125-6.758-11.667h5.4a2.917 2.917 0 1 0 .002-2.5h-9.451v-.416a2.102 2.102 0 0 0-2.084-2.084h-4.166Zm.416 2.5h3.334v1.06c-.02.127-.02.256 0 .382v1.892h-3.334v-1.879a1.253 1.253 0 0 0 0-.405v-1.05ZM5 27.5h2.5V30H5v-2.5Zm27.5 0H35V30h-2.5v-2.5Z"
    />
  </svg>
);
