import { CourseTypes } from '../course';
import { publicConfig } from '../../services';
import { Nullable, RegionsEnum } from '../../models';
import { UserAggregateModel } from '../user';
import { CertificateModel, CourseAccessTypes } from '../courses';
import { VendorEnum, VendorModel } from '../vendors';

export const isCertificateCanBeGranted = (
  user: Nullable<UserAggregateModel>,
  certificate: Nullable<CertificateModel>,
  courseType: string,
  courseVendor: VendorModel,
  accessType: Nullable<CourseAccessTypes>,
  isHomeworkAvailable: boolean
) =>
  certificate?.isFileGenerated ||
  ([CourseTypes.course, CourseTypes.profession].some(
    (type) => courseType === type
  ) &&
    courseVendor.slug === VendorEnum.skillbox &&
    isHomeworkAvailable &&
    publicConfig.REGION !== RegionsEnum.tj &&
    !(
      user?.career?.isCertificateGenerationDisabled &&
      accessType === CourseAccessTypes.b2b
    ));

export const getCourseCertificate = (
  certificates: CertificateModel[],
  studentCourseId: string
): Nullable<CertificateModel> =>
  certificates.find(
    (certificate) => certificate.studentCourseId === studentCourseId
  ) || null;
