import React, { ReactElement } from 'react';
import { FooterBottomContainerProps } from './footerBottom.model';
import { publicConfig } from '../../../services';
import { RegionsEnum } from '../../../models';
import { FooterBottomDefaultContainer } from './footerBottomDefault';
import { FooterBottomRuContainer } from './footerBottomRu';
import { FooterBottomUzContainer } from './footerBottomUz';

export const FooterBottomContainer = ({
  footerImages,
}: FooterBottomContainerProps): ReactElement => {
  const isRuRegion = publicConfig?.REGION === RegionsEnum.ru;
  const isUzRegion = publicConfig?.REGION === RegionsEnum.uz;

  switch (true) {
    case isRuRegion:
      return <FooterBottomRuContainer footerImages={footerImages} />;
    case isUzRegion:
      return <FooterBottomUzContainer footerImages={footerImages} />;
    default:
      return <FooterBottomDefaultContainer footerImages={footerImages} />;
  }
};
