import styled from 'styled-components';

export const CourseHeadContainerStyled = styled.div`
  overflow: hidden;
`;

export const CourseHeadTitleWrapStyled = styled.div`
  margin-right: 20px;
`;

export const CourseHeadRowStyled = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const CourseHeadFormButtonStyled = styled.div`
  margin-top: 24px;
`;
