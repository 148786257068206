import React, { ReactElement, useMemo } from 'react';
import {
  FooterBottomInfoStyled,
  FooterBottomInfoTextStyled,
  FooterBottomLogoStyled,
  FooterBottomRuStyled,
  FooterBottomWrapperStyled,
  FooterCompanyStyled,
} from './footerBottomRu.styled';
import { FooterBottomContainerProps } from './footerBottomRu.model';
import { useTheme } from '../../../../hooks';
import { IconLernaLogoComponent } from '../../../icons';
import { FooterImagesContainer } from '../../footerImages';
import {
  FooterSberInfoContainer,
  isNotContainsExcludedCompanies,
} from '../../footerSberInfo';
import { useUser } from '../../../user';
import { useTranslations } from '../../../translations';

export const FooterBottomRuContainer = ({
  footerImages,
}: FooterBottomContainerProps): ReactElement => {
  const theme = useTheme();
  const { user } = useUser();
  const translations = useTranslations();

  const currentYear = useMemo(() => new Date().getFullYear(), []);
  const companyText =
    translations?.company && `© ${translations.company}, ${currentYear}`;

  const footerLogoContainer = useMemo(() => {
    const lernaCompanyText = isNotContainsExcludedCompanies(
      user?.career?.companyId
    ) ? (
      <FooterCompanyStyled>{companyText}</FooterCompanyStyled>
    ) : null;

    return (
      <FooterBottomLogoStyled>
        <IconLernaLogoComponent
          fillColor={theme.COLOR.WHITE}
          width={83}
          height={39}
        />
        {lernaCompanyText}
      </FooterBottomLogoStyled>
    );
  }, []);

  const footerImagesContainer = useMemo(() => {
    switch (true) {
      case isNotContainsExcludedCompanies(user?.career?.companyId):
        return <FooterSberInfoContainer translations={translations} />;
      default:
        return (
          <>
            <FooterImagesContainer images={footerImages} />
            <FooterCompanyStyled>{companyText}</FooterCompanyStyled>
          </>
        );
    }
  }, []);

  return (
    <FooterBottomRuStyled>
      <FooterBottomWrapperStyled>
        <FooterBottomInfoStyled>
          {footerLogoContainer}
          <FooterBottomInfoTextStyled>
            {translations?.exclusive_partner}
          </FooterBottomInfoTextStyled>
        </FooterBottomInfoStyled>
        <FooterCompanyStyled isMobile>{companyText}</FooterCompanyStyled>
      </FooterBottomWrapperStyled>
      {footerImagesContainer}
    </FooterBottomRuStyled>
  );
};
