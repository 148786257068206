import {
  GoogleSheetLoggerClient,
  ProxyLoggerClient,
} from './googleSheetLogger.client';
import { publicConfig, RegionsEnum } from '@lerna-core';

export const googleSheetLoggerClientProvider = async (
  path: string,
  requestParams: any
): Promise<void> => {
  const response =
    publicConfig.REGION === RegionsEnum.ru
      ? await new ProxyLoggerClient(path, requestParams).post()
      : await new GoogleSheetLoggerClient(path, requestParams).post();

  return response as unknown as Promise<void>;
};
