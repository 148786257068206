import { getCourseHref } from '@helpers';
import {
  ButtonColor,
  ButtonContainer,
  CommonEventPositions,
  CommonEventTypes,
  CourseModel,
  DiscountProgramContext,
  getAdditionalColors,
  getCoursesPath,
  getVendors,
  handleClearFilters,
  handleOpenPopup,
  IframeInitiator,
  Nullable,
  PopupsEnum,
  pushDataLayer,
  QueryParams,
  urlService,
  usePageRouter,
  useTranslations,
  useUser,
} from '@lerna-core';
import { useRouter } from 'next/router';
import React, { ReactElement, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleCoursesListDataLayer } from './coursesByTitle.helpers';
import { CoursesByTitleModel } from './coursesByTitle.model';
import {
  CoursesByTitleContainerStyled,
  CoursesByTitlePaginationStyled,
  CoursesByTitleStyled,
  CoursesByTitleTitleStyled,
} from './coursesByTitle.styled';
import { useCourseActiveContext } from '../coursesActive';
import { getCourseEventData } from '../../course';
import {
  GoToCourseEvent,
  ListItemButtonsEvent,
  ListItemContainer,
} from '../coursesList';
import { useCoursesByTitleContext } from './coursesByTitle.context';
import { ParsedUrlQuery } from 'querystring';

export const CoursesByTitleListContainer = ({
  courseBasePath,
}: CoursesByTitleModel): Nullable<ReactElement> => {
  const discountProgram = useContext(DiscountProgramContext);
  const courses = useCoursesByTitleContext();
  const vendors = useSelector(getVendors);
  const translations = useTranslations();
  const dispatch = useDispatch();
  const { setNumericId } = useCourseActiveContext();
  const { user } = useUser();
  const router = useRouter();
  const defaultPathname = getCoursesPath(router.query);
  const isRouting = usePageRouter();
  const { buttonAdditionalColors } = getAdditionalColors(
    discountProgram,
    user?.company?.uuid
  );

  const iframeInitiator = router.query[
    QueryParams.initiator
  ] as IframeInitiator;

  const discountProgramColors = iframeInitiator
    ? undefined
    : discountProgram?.colors;
  const isListingWithBenefitsOnly = user?.career?.isListingWithBenefitsOnly;
  const isPricesHiddenOnListingCards =
    user?.career?.isPricesHiddenOnListingCards;
  const initiator = router.query[QueryParams.initiator] as IframeInitiator;
  const isEmptyList = courses.data.length === 0;

  const handleOpenSingUpCoursePopup = (
    course: CourseModel,
    courseIndex: number
  ): void => {
    handleCoursesListDataLayer(course, vendors, courseIndex);
    dispatch(
      handleOpenPopup(
        !!user ? PopupsEnum.userOrderFormPopup : PopupsEnum.guestOrderFormPopup,
        CommonEventPositions.coursesPageList
      )
    );
    setNumericId(course.numericId);
  };

  const handleShowAll = () => {
    const query: ParsedUrlQuery = {
      title: router.query[QueryParams.title],
    };
    if (router.query[QueryParams.promoCode]) {
      query[QueryParams.promoCode] = router.query[QueryParams.promoCode];
    }
    dispatch(handleClearFilters());

    router.push(
      urlService.createUrlFromPathAndQuery(defaultPathname, router, query),
      undefined,
      { scroll: true }
    );
  };

  const handleGoToCourse = (
    course: CourseModel,
    courseHref: Nullable<string>,
    courseIndex: number,
    eventName: GoToCourseEvent | ListItemButtonsEvent
  ): void => {
    if (courseHref) {
      pushDataLayer(
        getCourseEventData(
          eventName,
          CommonEventTypes.coursesEvents,
          CommonEventPositions.coursesPageList,
          course,
          vendors,
          courseIndex
        )
      );
    } else {
      handleOpenSingUpCoursePopup(course, courseIndex);
    }
  };

  if (isEmptyList) {
    return null;
  }

  return (
    <CoursesByTitleContainerStyled>
      <CoursesByTitleTitleStyled>
        {translations.maybe_you_search}
      </CoursesByTitleTitleStyled>
      <CoursesByTitleStyled isRouting={isRouting}>
        {courses.data.map((course, index) => {
          const courseHref = getCourseHref({
            course,
            router,
            basePath: courseBasePath,
            initiator,
          });

          return (
            <ListItemContainer
              key={course.uuid}
              courseHref={courseHref}
              handleGoToCourse={handleGoToCourse.bind(
                null,
                course,
                courseHref,
                index
              )}
              course={course}
              courseIndex={index}
              isListingWithBenefitsOnly={isListingWithBenefitsOnly}
              isPricesHiddenOnListingCards={isPricesHiddenOnListingCards}
              handleOpenSingUpCoursePopup={handleOpenSingUpCoursePopup.bind(
                null,
                course,
                index
              )}
              discountProgramColors={discountProgramColors}
            />
          );
        })}
      </CoursesByTitleStyled>
      {courses.meta.total > 4 && (
        <CoursesByTitlePaginationStyled>
          <ButtonContainer
            borderRadius={100}
            onClick={handleShowAll}
            title={translations.show_all}
            color={ButtonColor.brightPurple}
            additionalColors={buttonAdditionalColors}
          />
        </CoursesByTitlePaginationStyled>
      )}
    </CoursesByTitleContainerStyled>
  );
};
