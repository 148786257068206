import React, { ReactElement } from 'react';
import { HeadBreadcrumbsMetaTags, Nullable } from '@lerna-core';
import { BreadcrumbsItemContainer } from './breadcrumbsItem';
import * as S from './headerBreadcrumbs.styled';
import { HeaderBreadcrumbsModel } from './headerBreadcrumbs.model';

export const HeaderBreadcrumbsContainer = ({
  hasBorder = true,
  breadcrumbs,
}: HeaderBreadcrumbsModel): Nullable<ReactElement> => {
  if (breadcrumbs.length === 0) {
    return null;
  }

  return (
    <S.HeaderBreadcrumbsWrapper hasBorder={hasBorder}>
      <S.HeaderBreadcrumbs>
        {breadcrumbs.map((item, index, list) => (
          <BreadcrumbsItemContainer
            hasDivider={index !== 0}
            key={`link_${index}`}
            isDisabled={list.length === index + 1}
            {...item}
          />
        ))}
      </S.HeaderBreadcrumbs>
      <HeadBreadcrumbsMetaTags breadcrumbs={breadcrumbs} />
    </S.HeaderBreadcrumbsWrapper>
  );
};
