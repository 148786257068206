import { useRouter } from 'next/router';
import { DiscountProgramLogoContainer } from '@features/common/discountProgram';
import {
  ContactUsFormEvent,
  ContactUsFormEventType,
} from '@features/common/forms/contactUsForm';
import { HeaderContainer, HeaderEventPositions } from '@features/common/header';
import {
  DiscountProgramContext,
  FilterModel,
  getCurrentPopupFromOpen,
  getFilters,
  getSimpleEvent,
  PopupsEnum,
  pushDataLayer,
  useFastLinksContext,
  useTranslations,
  useUser,
} from '@lerna-core';
import React, { ReactElement, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PageHeaderContainerProps } from './coursesHeader.model';
import {
  getCoursesHeaderBreadcrumbs,
  getCoursesHeaderLinkType,
} from './coursesHeader.helper';

export const CoursesHeaderContainer = ({
  showSearch,
  showFilters,
  handleSticky,
  alwaysShowSearch,
}: PageHeaderContainerProps): ReactElement => {
  const discountProgram = useContext(DiscountProgramContext);
  const { user } = useUser();
  const translations = useTranslations();
  const fastLinks = useFastLinksContext();
  const filters = useSelector(getFilters);
  const router = useRouter();

  const breadcrumbs = useMemo(() => {
    return getCoursesHeaderBreadcrumbs(
      fastLinks,
      filters as FilterModel[],
      translations,
      router
    );
  }, [router.query]);
  const contactUsFormPopup = useSelector(
    getCurrentPopupFromOpen(PopupsEnum.contactUsFormPopup)
  );

  const eventPosition = useMemo(() => {
    if (contactUsFormPopup) {
      return contactUsFormPopup.target || '';
    }

    return HeaderEventPositions.coursesPageBlockHeader;
  }, [contactUsFormPopup]);
  const headerLinkType = getCoursesHeaderLinkType(user, router.query);
  const discountProgramLogo = discountProgram?.logo && (
    <DiscountProgramLogoContainer logo={discountProgram.logo} />
  );

  const handleDataLayer = (eventName: ContactUsFormEvent): void => {
    pushDataLayer(
      getSimpleEvent(
        eventName,
        ContactUsFormEventType.overallEvents,
        eventPosition
      )
    );
  };

  const handleOpenContactUs = (): void => {
    handleDataLayer(ContactUsFormEvent.buttonAskQuestion);
    handleDataLayer(ContactUsFormEvent.formAskQuestionOpen);
  };

  const handleErrorContactUs = (): void => {
    handleDataLayer(ContactUsFormEvent.formAskQuestionError);
  };

  const handleSuccessContactUs = (): void => {
    handleDataLayer(ContactUsFormEvent.formAskQuestionSuccess);
  };

  const handleCloseContactUs = (): void => {
    handleDataLayer(ContactUsFormEvent.formAskQuestionClose);
  };

  return (
    <HeaderContainer
      breadcrumbs={breadcrumbs}
      showSearch={showSearch}
      showFilters={showFilters}
      hasSticky={true}
      handleSticky={handleSticky}
      handleOpenContactUs={handleOpenContactUs}
      handleCloseContactUs={handleCloseContactUs}
      handleErrorContactUs={handleErrorContactUs}
      handleSuccessContactUs={handleSuccessContactUs}
      alwaysShowSearch={alwaysShowSearch}
      headerLinkType={headerLinkType}
      discountProgramLogo={discountProgramLogo}
      withLogin={!discountProgram?.isHiddenAuthorization}
    />
  );
};
