import {
  DiscountProgramContext,
  getAdditionalColors,
  QueryParams,
  useTranslations,
  useUser,
} from '@lerna-core';
import { getCoursesPaginationData } from './coursesPagination.helper';
import {
  CoursesPaginationContainerProps,
  CoursesPaginationHookModel,
} from './coursesPagination.model';
import { useCoursesContext } from '@features/courses';
import { useContext } from 'react';
import { useRouter } from 'next/router';

export const useCoursesPagination = ({
  isLoading,
  handleClick,
  handleSuccess,
}: CoursesPaginationContainerProps): CoursesPaginationHookModel => {
  const translations = useTranslations();

  const discountProgram = useContext(DiscountProgramContext);
  const router = useRouter();
  const isPromoCatalog =
    router.asPath.startsWith('/promo/') ||
    router.query[QueryParams.discountProgram];
  const { user, reloadUser } = useUser();
  const { buttonAdditionalColors } = getAdditionalColors(
    discountProgram,
    user?.company?.uuid
  );
  const { courses, requestMoreCourses } = useCoursesContext();

  const coursesPaginationData = getCoursesPaginationData(
    courses.meta,
    courses.meta.currentPage,
    translations
  );

  const handleLoadMore = async (): Promise<void> => {
    handleClick();
    if (!isPromoCatalog) {
      reloadUser();
    }
    requestMoreCourses({
      page: `${courses.meta.currentPage + 1}`,
    }).then(handleSuccess);
  };

  return {
    buttonAdditionalColors,
    coursesPaginationData,
    handleLoadMore,
    isLoading,
  };
};
