import { ReactElement, useEffect, useState } from 'react';
import * as S from './fastLinksMenuDesktop.styled';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFastLinksMenuSelector,
  getFastLinksMenuStatusSelector,
} from '../fastLinksMenu.selectors';
import { FastLinkMenuModel } from '../fastLinksMenu.model';
import { SpheresContainer } from './spheres';
import { FastlinksContainer } from './fastlinks';
import { SchoolsContainer } from './schools';
import { openFastLinksMenu } from '../fastLinksMenu.actions';
import { FastLinksMenuMobileDesktop } from './fastLinksMenuDesktop.model';
import { Nullable } from '../../../../models';

export const FastLinksMenuDesktopContainer = ({
  top,
  filtersAccentColor,
}: FastLinksMenuMobileDesktop): Nullable<ReactElement> => {
  const dispatch = useDispatch();
  const { isOpened } = useSelector(getFastLinksMenuStatusSelector);
  const fastLinksMenu = useSelector(getFastLinksMenuSelector);
  const [activeSphere, setActiveSphere] = useState<Nullable<FastLinkMenuModel>>(
    fastLinksMenu[0] || null
  );
  const handleCloseFastLinksMenu = () => {
    dispatch(openFastLinksMenu(false));
  };
  const handleClickSphere = (sphere: FastLinkMenuModel) => {
    setActiveSphere(sphere);
  };

  useEffect(() => {
    if (!isOpened) {
      setActiveSphere(fastLinksMenu[0] || null);
    }
  }, [isOpened]);

  if (!activeSphere) {
    return null;
  }

  return (
    <S.FastLinksMenuDesktopWrapper top={top} isVisible={isOpened}>
      <S.FastLinksMenuBackgroundStyled onClick={handleCloseFastLinksMenu} />
      <S.FastLinksMenuDesktop>
        <SpheresContainer
          spheres={fastLinksMenu}
          activeSphere={activeSphere}
          handleClickSphere={handleClickSphere}
          filtersAccentColor={filtersAccentColor}
        />
        <FastlinksContainer activeSphere={activeSphere} />
        <SchoolsContainer activeSphere={activeSphere} />
      </S.FastLinksMenuDesktop>
    </S.FastLinksMenuDesktopWrapper>
  );
};
