import styled from 'styled-components';
import { fontSettings } from '../../../../../styles';

export const Search = styled.div`
  padding-top: 8px;
  padding-bottom: 16px;
  background-color: ${({ theme }) => theme.COLOR.WHITE};
`;

export const SearchWrapper = styled.div`
  position: relative;
  display: flex;
  gap: 8px;
  padding: 12px 24px;
  background-color: ${({ theme }) => theme.COLOR.BLACK_4};
  border-radius: 100px;
`;

export const SearchValue = styled.div<{ color: string }>`
  color: ${({ color }) => color};
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
  font-weight: 500;
`;
