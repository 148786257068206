import React, { ReactElement } from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { FiltersClearAllContainerProps } from './filtersClearAll.model';
import { FiltersClearAllContainerStyled } from './filtersClearAll.styled';
import {
  getCoursesPath,
  handleClearFilters,
  handleSetSearchTerm,
  QueryParams,
  urlService,
} from '@lerna-core';
import { ParsedUrlQuery } from 'querystring';

export const FiltersClearAllContainer = ({
  children,
  handleClear,
}: FiltersClearAllContainerProps): ReactElement => {
  const dispatch = useDispatch();
  const router = useRouter();
  const defaultPathname = getCoursesPath(router.query);

  const handleClick = (): void => {
    const query: ParsedUrlQuery = {};
    if (router.query[QueryParams.promoCode]) {
      query[QueryParams.promoCode] = router.query[QueryParams.promoCode];
    }
    if (handleClear) {
      handleClear();
    }
    dispatch(handleSetSearchTerm(''));
    dispatch(handleClearFilters());

    router.push(
      urlService.createUrlFromPathAndQuery(defaultPathname, router, query),
      undefined,
      { scroll: false }
    );
  };

  return (
    <FiltersClearAllContainerStyled onClick={handleClick}>
      {children}
    </FiltersClearAllContainerStyled>
  );
};
