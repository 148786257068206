import { logger, Nullable, publicConfig, useUser } from '@lerna-core';
import React, { ReactElement, useContext } from 'react';
import {
  GoogleSheetLoggerModel,
  GoogleSheetLoggerProviderProps,
} from './googleSheetLogger.model';
import {
  isExcludedEmail,
  requestWithTimeout,
} from './googleSheetLogger.helper';
import { OrderFormEventsValues } from '@features/common/forms';

const GoogleSheetLogger =
  React.createContext<Nullable<GoogleSheetLoggerModel>>(null);

export const GoogleSheetLoggerProvider = ({
  course,
  children,
}: GoogleSheetLoggerProviderProps): ReactElement => {
  const { user } = useUser();

  const googleSheetLogger = async (
    formEventOptions: OrderFormEventsValues[],
    requestParams: any = {}
  ): Promise<void> => {
    const customer = user
      ? {
          user_name: `${user.personal.firstName} ${user.personal.lastName}`,
          email: user.personal.email,
          phone: user.personal.phone,
        }
      : null;

    if (isExcludedEmail(requestParams.email || customer?.email)) {
      return;
    }

    if (publicConfig.GOOGLE_SHEET_ORDERS_PATH !== 'false') {
      const timeNow = await fetch(
        `${publicConfig.API_GATEWAY_URL}orders-api/v1/utils/time`,
        { method: 'GET', signal: AbortSignal.timeout(15000) }
      )
        .then((response) => {
          if (response.status === 200) {
            return response.json().then(({ current_time }) => current_time);
          }
        })
        .catch((e) => {
          logger.error('[ERROR]: can not get server time', e);
        });

      await requestWithTimeout({
        formType: formEventOptions.join(','),
        isB2B2C: !!user?.career?.companyId,
        isB2C: !!user?.personal && !user?.career?.companyId,
        isGuest: !user?.personal,
        course: {
          uuid: course.uuid,
          slug: course.slug,
          title: course.title,
        },
        region: publicConfig.REGION,
        url: window.location.href,
        customer_timestamp_iso: new Date().toISOString(),
        timestamp_iso: timeNow,
        userAgent: window.navigator.userAgent,
        ...customer,
        ...requestParams,
      });
    }
  };

  return (
    <GoogleSheetLogger.Provider value={{ googleSheetLogger }}>
      {children}
    </GoogleSheetLogger.Provider>
  );
};

export const useGoogleSheetLoggerContext = (): GoogleSheetLoggerModel => {
  const value = useContext(GoogleSheetLogger);

  if (!value) {
    logger.error('[ERROR]: you cannot use context without a provider');

    return {} as GoogleSheetLoggerModel;
  }

  return value;
};
