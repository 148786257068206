import React, {
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useTheme } from '../../../../hooks';
import { Nullable } from '../../../../models';
import { InputColors } from '../../../../styles';
import { IconArrowComponent } from '../../../icons';
import { useTranslations } from '../../../translations';
import { FloatingPlaceholderStyled } from '../../floatingPlaceholder';
import { useIsDropdownOpenBottom } from './select.hook';
import {
  SelectedValue,
  SelectLabelModel,
  SelectOptionModel,
  SelectOptionType,
  SelectProps,
} from './select.model';
import {
  SelectEmptySearchStyled,
  SelectStyled,
  SelectStyledArrow,
  SelectStyledButton,
  SelectStyledText,
} from './select.styled';
import { SelectDropdown } from './selectDropdown';
import { SelectOption } from './selectOptions/selectOption.container';
import { SelectSearchContainer } from './selectSearch';
import { filterOptionsByTerm } from './select.helper';

export const Select = ({
  name = '',
  options,
  placeholder,
  value,
  onChange,
  disabled = false,
  bordered = true,
  activeHovered = true,
  color = InputColors.white,
  optionsComponent,
  selectLabelComponent,
  floatingPlaceholder,
  hasError,
  directionOpenTop = false,
  dropdownHeight = 270,
  className,
  withSearch = false,
}: SelectProps): ReactElement => {
  const theme = useTheme();
  const translations = useTranslations();
  const [isOpened, setIsOpened] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const filterDropdownRef = useRef<Nullable<HTMLDivElement>>(null);

  const isOpenTop = useIsDropdownOpenBottom(
    filterDropdownRef,
    directionOpenTop,
    dropdownHeight
  );
  const handleToggleDropdown = useCallback((): void => {
    setIsOpened(!isOpened);
  }, [isOpened]);

  const handleSelect = useCallback(
    (option: SelectOptionType): void => {
      onChange({ name, value: option } as SelectedValue);
      setIsOpened(false);
    },
    [name, onChange]
  );

  const SelectedLabelComponent = ({
    value,
  }: SelectLabelModel): ReactElement => {
    const activePlaceholder = floatingPlaceholder ? '' : placeholder;
    const text = value ? value.value : activePlaceholder;

    if (selectLabelComponent) {
      return selectLabelComponent({ value });
    }

    return <SelectStyledText children={text} />;
  };

  const OptionsComponent = (props: SelectOptionModel): ReactElement => {
    const { options, value, activeHovered, onClick } = props;

    if (optionsComponent) {
      return optionsComponent(props);
    }

    return (
      <>
        {options.map((option) => (
          <SelectOption
            activeHovered={activeHovered}
            onClick={onClick}
            key={option.key}
            option={option}
            active={value?.key === option.key}
          />
        ))}
      </>
    );
  };

  const selectSearch: Nullable<ReactElement> = withSearch ? (
    <SelectSearchContainer
      searchTerm={searchTerm}
      setSearchTerm={setSearchTerm}
    />
  ) : null;

  const filteredOptions = useMemo(
    () => filterOptionsByTerm(searchTerm, options),
    [searchTerm, options]
  );

  return (
    <SelectStyled
      ref={filterDropdownRef}
      disabled={disabled}
      bordered={bordered}
      floatingPlaceholder={floatingPlaceholder}
      hasError={hasError}
      color={color}
      className={className}
    >
      {floatingPlaceholder ? (
        <FloatingPlaceholderStyled
          hasError={hasError}
          htmlFor={name}
          children={placeholder}
          active={!!value?.key}
          disabled={disabled}
        />
      ) : null}

      <SelectStyledButton onClick={handleToggleDropdown} />
      <SelectedLabelComponent value={value} />
      <SelectStyledArrow isOpened={isOpened}>
        <IconArrowComponent
          strokeColor={
            isOpened
              ? theme.COLOR.TEXT_DARK_COLOR
              : theme.COLOR.TEXT_LIGHT_COLOR
          }
        />
      </SelectStyledArrow>
      {isOpened && !!options.length && (
        <SelectDropdown
          ref={filterDropdownRef}
          handleOutsideClick={handleToggleDropdown}
          bordered={bordered}
          directionOpenTop={isOpenTop}
          dropdownHeight={dropdownHeight}
          search={selectSearch}
        >
          <OptionsComponent
            options={filteredOptions}
            value={value}
            activeHovered={activeHovered}
            onClick={handleSelect}
          />
          {withSearch && !filteredOptions.length && (
            <SelectEmptySearchStyled>
              {translations.request_not_found}
            </SelectEmptySearchStyled>
          )}
        </SelectDropdown>
      )}
    </SelectStyled>
  );
};
