import React, { ReactElement } from 'react';

import { SectionModel } from './section.model';
import { SectionStyled } from './section.styled';
import { SectionBodyContainer } from './sectionBody';
import { SectionHeadingContainer } from './sectionHeading';
import { usePageRouter } from '../../../../../hooks';

export const SectionContainer = ({
  heading,
  count,
  section,
  isMobile,
}: SectionModel): ReactElement => {
  const isRouting = usePageRouter();

  const hasHeading = heading || count;

  return (
    <SectionStyled isRouting={isRouting}>
      {hasHeading && (
        <SectionHeadingContainer
          heading={heading}
          count={count}
          isMobile={isMobile}
        />
      )}
      <SectionBodyContainer section={section} />
    </SectionStyled>
  );
};
