import React, { ReactElement } from 'react';
import { IconProps } from '../../../features';

export const IconProjectProductComponent = ({
  width = 24,
  height = 24,
  fillColor = '#713AE0',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#a)">
        <path
          d="M5.651 1.484a1.25 1.25 0 0 0-.87 2.147L5.958 4.81a1.25 1.25 0 1 0 1.767-1.768L6.548 1.864a1.25 1.25 0 0 0-.897-.38Zm28.66.002a1.25 1.25 0 0 0-.859.378l-1.178 1.178A1.25 1.25 0 1 0 34.04 4.81L35.22 3.63a1.249 1.249 0 0 0-.908-2.145ZM20 3.346C13.575 3.348 8.333 8.572 8.333 15c0 3.333 1.41 6.35 3.658 8.473.264.25.458.602.548 1.005l2.056 9.252a3.761 3.761 0 0 0 3.66 2.937h3.49c1.75 0 3.28-1.228 3.66-2.937l2.058-9.252v-.002a1.95 1.95 0 0 1 .546-1.001v-.002h.002A11.64 11.64 0 0 0 31.667 15c0-6.428-5.242-11.652-11.667-11.654Zm0 2.5A9.138 9.138 0 0 1 29.167 15a9.117 9.117 0 0 1-2.875 6.657 4.441 4.441 0 0 0-1.27 2.279l-.977 4.397h-8.09l-.976-4.4a4.449 4.449 0 0 0-1.271-2.276A9.114 9.114 0 0 1 10.833 15 9.138 9.138 0 0 1 20 5.846ZM1.25 12.5a1.252 1.252 0 0 0-1.17 1.732A1.25 1.25 0 0 0 1.25 15h2.5a1.252 1.252 0 0 0 1.17-1.732 1.25 1.25 0 0 0-1.17-.768h-2.5Zm35 0a1.252 1.252 0 0 0-1.268 1.25A1.249 1.249 0 0 0 36.25 15h2.5a1.252 1.252 0 0 0 1.268-1.25 1.249 1.249 0 0 0-1.268-1.25h-2.5Zm-3.105 9.811a1.25 1.25 0 0 0-.871 2.147l1.178 1.178a1.25 1.25 0 1 0 1.768-1.767L34.04 22.69a1.25 1.25 0 0 0-.896-.379Zm-26.327.002a1.25 1.25 0 0 0-.86.377L4.78 23.87a1.251 1.251 0 1 0 1.768 1.767l1.178-1.178a1.25 1.25 0 0 0-.908-2.145Zm9.694 8.52h6.976l-.523 2.355a1.24 1.24 0 0 1-1.22.979h-3.49a1.237 1.237 0 0 1-1.22-.979l-.523-2.355Z"
          fill={fillColor}
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h40v40H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
