import { NextPageContext } from 'next';
import { getCoursesPath } from '../../features';
import { getCompositeFastLink, getFastLinkHref } from './fastLinks.helper';
import { FastLinkModel } from './fastLinks.model';
import { Nullable, QueryParams } from '../../models';
import { filterRouterService, FiltersModel } from '../filters';

const trimmedFastLinkRedirectMiddleware = (
  context: NextPageContext,
  coursesPath: string
): void => {
  if (
    context.query.fastlink &&
    context.query.fastlink !== (context.query.fastlink as string).trim()
  ) {
    context?.res?.writeHead(301, {
      Location: getFastLinkHref(
        (context.query.fastlink as string).trim(),
        coursesPath,
        context.query
      ),
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    });
    context?.res?.end();
  }
};

export const fastLinkRedirectMiddleware = (
  context: NextPageContext,
  fastLinks: Nullable<FastLinkModel[]>,
  filters: FiltersModel[]
): void => {
  const coursesPath = getCoursesPath(context.query);
  const currentFastlink = context.query[QueryParams.fastlink];

  trimmedFastLinkRedirectMiddleware(context, coursesPath);

  if (fastLinks && !context.query[QueryParams.discountProgram]) {
    const newQuery = filterRouterService.getNewRouteFromFilters(
      { ...context.query },
      filters
    );
    const fastLink = getCompositeFastLink(fastLinks, newQuery);

    if (fastLink && fastLink.slug !== currentFastlink) {
      context?.res?.writeHead(301, {
        Location: getFastLinkHref(fastLink.slug, coursesPath, context.query),
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
      });
      context?.res?.end();
    }
  }
};
