import {
  CourseItemApiVendorModel,
  CustomerCoursesResponseApiModel,
  CustomerCoursesResponseModel,
  CustomerGrantAccessApiModel,
  CustomerGrantAccessModel,
} from './customerCourses.model';
import { mapCourseItem } from '../../courses';
import { mapImageResource } from '../../image';
import { CourseTypes } from '../../course';
import { VendorModel } from '../../vendors';

export const customerCourseVendorAdapter = (
  apiVendor: CourseItemApiVendorModel
): VendorModel => ({
  uuid: apiVendor.id,
  slug: apiVendor.slug,
  title: apiVendor.title,
  logo: apiVendor.logo
    ? {
        imageResource: mapImageResource(apiVendor.logo),
        backgroundColor: null,
        size: null,
      }
    : null,
  coursePlug: apiVendor.course_plug
    ? {
        imageResource: mapImageResource(apiVendor.course_plug),
        backgroundColor: null,
        size: null,
      }
    : null,
});

export const mapGrantAccess = (
  grantAccess: CustomerGrantAccessApiModel
): CustomerGrantAccessModel => ({
  uuid: grantAccess.id,
  courseId: grantAccess.course?.id || '',
  title: grantAccess.course?.title || '',
  type: grantAccess.course?.type || '',
  typeTitle: grantAccess.course?.type_title || '',
  vendor: grantAccess.course?.vendor
    ? customerCourseVendorAdapter(grantAccess.course.vendor)
    : null,
  logo: grantAccess.course?.logo
    ? mapImageResource(grantAccess.course?.logo)
    : null,
});

const filterGranule = ({ type }: CustomerGrantAccessModel): boolean =>
  type === CourseTypes.granule;

export const customerCoursesAdapter = ({
  courses,
  grant_accesses,
}: CustomerCoursesResponseApiModel['data']): CustomerCoursesResponseModel => ({
  courses: {
    corporate: courses.corporate.map(mapCourseItem),
    personal: courses.personal.map(mapCourseItem),
  },
  // TODO: убрать фильтры во второй итерации
  grantAccesses: {
    corporate: grant_accesses.corporate
      .map(mapGrantAccess)
      .filter(filterGranule),
    personal: grant_accesses.personal.map(mapGrantAccess).filter(filterGranule),
  },
});

export const customerCoursesResponseAdapter = (
  response: CustomerCoursesResponseApiModel
): CustomerCoursesResponseModel => customerCoursesAdapter(response.data);
