import React, { ReactElement } from 'react';
import { FastLinksMenuContainer } from '../../catalog';

import * as S from './header.styled';
import { HeaderContainerProps } from './header.model';

export const HeaderContainer = ({
  children,
  maxWidth,
  useFastLinksMenu,
}: HeaderContainerProps): ReactElement => (
  <S.HeaderWrapper>
    <S.Header>
      <S.HeaderContent maxWidth={maxWidth} children={children} />
    </S.Header>
    {useFastLinksMenu && <FastLinksMenuContainer top="86px" />}
  </S.HeaderWrapper>
);
