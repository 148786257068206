import styled from 'styled-components';
import { fontSettings, lineClamping } from '../../../styles';
import { TooltipStyled } from '../../tooltip';

export const IUserBar = styled.div`
  ${TooltipStyled} {
    padding: 16px 10px;
    min-width: 200px;
    max-width: 320px;
    width: auto;
  }
`;

export const IUserBarDefault = styled.div`
  display: flex;
  background: ${({ theme }) => theme.COLOR.PRIMARY['100']};
  border-radius: 50%;
  padding: 12px;
  cursor: pointer;
`;

export const IUserMenu = styled.div``;
export const IUserRow = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)}
  font-weight: 500;
  color: ${({ theme }): string => theme.COLOR.TEXT['500']};
  word-break: break-word;
  ${lineClamping(2)};

  &:not(:last-child) {
    margin-bottom: 4px;
  }
`;
