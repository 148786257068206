import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconMailV2Component = ({
  width = 24,
  height = 24,
  strokeColor = '#713AE0',
}: IconProps): ReactElement => (
  <svg
    fill="none"
    height={height}
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <path
      stroke={strokeColor}
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M19.662 20.3H4.338C2.77 20.3 1.5 19.018 1.5 17.438V6.562C1.5 4.982 2.77 3.7 4.338 3.7h15.324c1.567 0 2.838 1.282 2.838 2.862v10.876c0 1.58-1.27 2.862-2.838 2.862Z"
    />
    <path
      stroke={strokeColor}
      strokeWidth="2"
      strokeMiterlimit="10"
      strokeLinejoin="round"
      d="M1.5 7.707 12 13.43l10.5-5.724"
    />
  </svg>
);
