import { ReactElement, useEffect, useMemo, useRef } from 'react';
import * as S from './spheres.styled';
import { SpheresModel } from './spheres.model';
import { StaticItemsContainer } from './staticItems';
import { getFastLinksIcons } from '../../../fastLinks.meta';
import { FastLinksIconsEnum } from '../../../fastLinks.model';
import { useSelector } from 'react-redux';
import { getFastLinksMenuStatusSelector } from '../../fastLinksMenu.selectors';
import { Nullable } from '../../../../../models';

export const SpheresContainer = ({
  spheres,
  activeSphere,
  handleClickSphere,
  filtersAccentColor,
}: SpheresModel): ReactElement => {
  const spheresRef = useRef<Nullable<HTMLDivElement>>(null);
  const { isOpened } = useSelector(getFastLinksMenuStatusSelector);
  const fastLinksIcons = useMemo(
    () => getFastLinksIcons(filtersAccentColor),
    []
  );

  useEffect(() => {
    spheresRef.current?.scroll(0, 0);
  }, [isOpened]);

  return (
    <S.Spheres ref={spheresRef}>
      <S.SpheresItems>
        {spheres.map((item) => (
          <S.SpheresItem
            onMouseEnter={handleClickSphere.bind(null, item)}
            isActive={activeSphere.slug === item.slug}
            key={item.slug}
          >
            <S.SpheresItemImage>
              {fastLinksIcons[item.slug as keyof typeof FastLinksIconsEnum]}
            </S.SpheresItemImage>
            <S.SpheresItemTitle>{item.title}</S.SpheresItemTitle>
          </S.SpheresItem>
        ))}
      </S.SpheresItems>
      <StaticItemsContainer filtersAccentColor={filtersAccentColor} />
    </S.Spheres>
  );
};
