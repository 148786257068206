import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconAcademicCapComponent = ({
  width = 25,
  height = 24,
  strokeColor = '#78747A',
}: IconProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <path
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M11.116 2.802 1.4 7.498l9.716 4.196a2.875 2.875 0 0 0 2.569 0L23.4 7.498l-9.716-4.696a2.88 2.88 0 0 0-2.568 0Z"
    />
    <path
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M20.4 9v10.207S17.548 22 12.4 22s-8-2.793-8-2.793V9M23.4 7.723v5.223"
    />
  </svg>
);
