import React, { ReactElement, useMemo } from 'react';
import { AccordionContainer } from '../../../../../features';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import { getFastLinksMenuSelector } from '../../fastLinksMenu.selectors';
import { getFastLinksIcons } from '../../../fastLinks.meta';
import { FastLinksIconsEnum } from '../../../fastLinks.model';
import {
  getFastLinkHref,
  getFastLinksCoursesPath,
} from '../../../fastLinks.helper';
import {
  AccordionContentModel,
  AccordionTitleModel,
} from '../fastLinksMenuMobile.model';
import { urlService } from '../../../../../services';
import { openFastLinksMenu } from '../../fastLinksMenu.actions';
import * as S from './spheres.styled';
import { FastLinksMenuMobileModel } from './spheres.model';
import { StaticItemsContainer } from './staticItems';

const AccordionTitle = ({
  title,
  icon = null,
}: AccordionTitleModel): ReactElement => {
  return (
    <S.AccordionTitle>
      <S.AccordionTitleImage>{icon}</S.AccordionTitleImage>
      <S.AccordionTitleText>{title}</S.AccordionTitleText>
    </S.AccordionTitle>
  );
};

const AccordionContent = ({ items }: AccordionContentModel): ReactElement => {
  const dispatch = useDispatch();
  const router = useRouter();
  const coursesPath = getFastLinksCoursesPath(router.query);

  const handleCloseFastLinksMenu = () => {
    dispatch(openFastLinksMenu(false));
  };

  return (
    <S.AccordionContent>
      {items.map(({ title, slug }) => (
        <S.AccordionContentItem
          href={urlService.getFullUrlToPage(
            getFastLinkHref(slug, coursesPath),
            router
          )}
          onClick={handleCloseFastLinksMenu}
          key={slug}
        >
          {title}
        </S.AccordionContentItem>
      ))}
    </S.AccordionContent>
  );
};

export const SpheresContainer = ({
  filtersAccentColor,
}: FastLinksMenuMobileModel): ReactElement => {
  const fastLinksMenu = useSelector(getFastLinksMenuSelector);
  const fastLinksIcons = useMemo(
    () => getFastLinksIcons(filtersAccentColor),
    []
  );

  const accordionItems = fastLinksMenu.map((item) => ({
    title: (
      <AccordionTitle
        {...item}
        icon={fastLinksIcons[item.slug as keyof typeof FastLinksIconsEnum]}
      />
    ),
    content: <AccordionContent items={item.fastlinks} />,
  }));

  return (
    <>
      <AccordionContainer
        version="v3"
        spoilerIconVersion="v3"
        items={accordionItems}
      />
      <StaticItemsContainer filtersAccentColor={filtersAccentColor} />
    </>
  );
};
