import * as constants from './search.constants';
import { PopupStyleModel } from '../../features/popups/popup';
import { SearchActions } from './search.actions';

export type SearchIsOpenedType = boolean;
export type SearchPopupStyleType = PopupStyleModel;

export type SearchReducerState = {
  suggest: string;
  isOpened: SearchIsOpenedType;
  style: SearchPopupStyleType;
};

const defaultState: SearchReducerState = {
  suggest: '',
  isOpened: false,
  style: null,
};

export const searchReducer = (
  state: SearchReducerState = defaultState,
  action: SearchActions
): SearchReducerState => {
  switch (action.type) {
    case constants.SET_SEARCH_TERM: {
      return {
        ...state,
        suggest: action.term,
      };
    }
    case constants.TOGGLE_OPEN_SEARCH_TERM: {
      return {
        ...state,
        isOpened: action.isOpened,
        style: action.style,
      };
    }
    default: {
      return state;
    }
  }
};
