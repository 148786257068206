import {
  publicConfig,
  RequestHeader,
  serverConfig,
} from '../../../../services';
import { RequestClient } from '../../../../services/request/request.service';
import {
  FastLinkMenuApiResponseModel,
  FastLinkMenuItemModel,
} from '../fastLinksMenu.model';
import { fastLinksMenuSchoolsResponseAdapter } from './fastLinksMenuSchools.adapter';

export class FastLinksMenuSchoolsClient extends RequestClient<
  null,
  FastLinkMenuApiResponseModel,
  FastLinkMenuItemModel[]
> {
  protected path;
  protected requestParameters = null;
  protected adapter = fastLinksMenuSchoolsResponseAdapter;

  constructor(path: string, requestHeaders?: RequestHeader) {
    super(
      publicConfig.API_GATEWAY_URL,
      serverConfig?.PRIVATE_API_GATEWAY_INTERNAL_URL,
      requestHeaders
    );
    this.path = path;
    this.requestParameters = null;
  }
}
