import { AccordionVersionModel } from '../accordion.model';
import { css } from 'styled-components';
import { ITheme } from '../../themes';
import { breakpoint } from '../../../styles';
import { accordionSpoilerVerticalPadding } from '../accordionSpoiler';

export const getAccordionItemStyle = (
  theme: ITheme,
  version: AccordionVersionModel
): ReturnType<typeof css> => {
  switch (version) {
    case 'v3':
    case 'default':
      return css`
        &:not(:last-child) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.08);
        }

        &:last-child {
          margin-bottom: -${accordionSpoilerVerticalPadding};
        }
      `;
    case 'v2':
      return css`
        border-radius: 16px;
        background: ${theme.COLOR.BLACK_4};

        &:not(:last-child) {
          margin-bottom: 4px;
        }

        ${breakpoint(
          'mediumDown',
          css`
            background: ${theme.COLOR.WHITE};
          `
        )}
      `;
  }
};
