import styled, { css, keyframes } from 'styled-components';
import { breakpoint, MenuSwitcherStyled, positionSticky } from '@lerna-core';
import {
  SearchButtonContainerStyled,
  SearchStyled,
} from '@features/common/search';
import { SearchContainerStyled } from 'src/features/search';
import { HeaderDesktopStyledProps } from './headerDesktop.model';

const opacity = keyframes`
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
`;

const controlBgColor = 'rgba(0, 0, 0, 0.04)';

export const HeaderDesktopContainerStyled = styled.div<HeaderDesktopStyledProps>`
    padding: 20px 0 16px;

    ${breakpoint(
      'mediumDown',
      css`
        padding: 20px 0 16px;
      `
    )}

    ${({ hasSticky }): string =>
      hasSticky ? positionSticky() : 'position: static'};
    height: 86px;
    box-sizing: border-box;
    z-index: ${(props): string => props.theme.ZINDEX.TOP_PAGE_CONTENT};
    background: rgb(255, 255, 255);
    top: 0;

    ${({ showSearch }): ReturnType<typeof css> => {
      return showSearch
        ? css`
            ${HeaderDesktopStickyCenterStyled} {
              animation: ${opacity} 0.12s ease-in-out;
            }

            ${SearchStyled} {
              width: 400px;
            }
          `
        : css``;
    }};

    ${SearchButtonContainerStyled} {
        margin-left: 8px;

        > button {
            border-radius: 50%;
            background-color: ${controlBgColor};

            &:hover {
                background-color: ${({ theme }): string =>
                  theme.COLOR.BLACK_08};
            }
        }
`;

export const HeaderDesktopContainerWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  top: -2px;

  ${MenuSwitcherStyled} {
    margin-left: 0;
    ${breakpoint(
      'xlargeDown',
      css`
        display: none;
      `
    )}
  }
`;

export const HeaderDesktopLeftStyled = styled.div`
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  z-index: 200;
`;

export const HeaderDesktopButtonsContainerStyled = styled.div`
  justify-content: flex-end;
  display: flex;
  flex: 0 0 auto;
  align-items: center;
  z-index: 200;
  margin-right: -12px;

  & > button {
    white-space: nowrap;
    overflow: hidden;
    justify-content: flex-start;
    padding: 14px 13px;
    width: 54px;
    color: ${(props): string => props.theme.COLOR.TEXT_DARK_COLOR};

    ${breakpoint(
      'mediumDown',
      css`
        display: none;
      `
    )}
  }
`;

export const HeaderDesktopIconStyled = styled.div`
  width: 24px;
  height: 24px;
  position: relative;
  cursor: pointer;
  padding: 12px;

  &:not(:last-child) {
    margin-right: 4px;
  }
`;

export const HeaderDesktopStickyCenterStyled = styled.div<HeaderDesktopStyledProps>`
    display: flex;
    margin: 0 auto;
    z-index: 200;

    ${({ showSearch }): ReturnType<typeof css> | string =>
      !showSearch
        ? css`
            margin: 0 auto 0 24px;

            ${SearchButtonContainerStyled} {
              margin-right: 32px;
            }
          `
        : ''}
    ${SearchContainerStyled} {
        background-color: ${controlBgColor};

        &:hover {
            background-color: ${({ theme }): string => theme.COLOR.BLACK_08};
        }
    }

    ${SearchStyled} {
        ${breakpoint(
          'mediumDown',
          css`
            margin: 0;
          `
        )}
    }
}
`;
