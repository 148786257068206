import { tariffAdapter } from '../features';
import { CareerApiModel, CareerModel, mapUserPosition } from '../features/user';
import { mapDepartment } from '../features/departments';

export const mapCareer = (careerData: CareerApiModel): CareerModel => {
  const career = {
    companyTitle: careerData.company_title,
    companyId: careerData.company_id,
    isVerified: careerData.is_verified,
    roles: careerData.roles || [],
    tariff: careerData.tariff?.data
      ? tariffAdapter(careerData.tariff.data)
      : null,
    position: careerData.position ? mapUserPosition(careerData.position) : null,
    department: careerData.department
      ? mapDepartment(careerData.department)
      : null,
    isHeaderFastLinkMenuHidden: careerData.is_header_fast_link_menu_hidden,
    isPromoCodesMenuSectionHidden:
      typeof careerData.is_promo_codes_menu_section_hidden === 'boolean'
        ? careerData.is_promo_codes_menu_section_hidden
        : false,
    isCertificateGenerationDisabled:
      typeof careerData.is_certificate_generation_disabled === 'boolean'
        ? careerData.is_certificate_generation_disabled
        : false,
  } as CareerModel;

  if (careerData.lms_courses_count) {
    career.lmsCoursesCount = careerData.lms_courses_count;
  }
  if (careerData.is_listing_hidden) {
    career.isListingHidden = careerData.is_listing_hidden;
  }
  if (careerData.is_listing_with_benefits_only) {
    career.isListingWithBenefitsOnly = careerData.is_listing_with_benefits_only;
  }
  if (careerData.is_listing_with_subscriptions_only) {
    career.isListingWithSubscriptionsOnly =
      careerData.is_listing_with_subscriptions_only;
  }
  if (careerData.is_invite_link_available) {
    career.isInviteLinkAvailable = careerData.is_invite_link_available;
  }
  if (careerData.is_company_without_manager) {
    career.isCompanyWithoutManager = careerData.is_company_without_manager;
  }
  if (careerData.is_search_hidden_on_listing) {
    career.isSearchHiddenOnListing = careerData.is_search_hidden_on_listing;
  }
  if (careerData.is_filters_hidden_on_listing) {
    career.isFiltersHiddenOnListing = careerData.is_filters_hidden_on_listing;
  }
  if (careerData.is_bonus_points_hidden_on_listing) {
    career.isBonusPointsHiddenOnListing =
      careerData.is_bonus_points_hidden_on_listing;
  }
  if (careerData.is_without_promo_cards) {
    career.isWithoutPromoCards = careerData.is_without_promo_cards;
  }
  if (careerData.is_without_promo_banner) {
    career.isWithoutPromoBanner = careerData.is_without_promo_banner;
  }
  if (careerData.is_without_personal_tests) {
    career.isWithoutPersonalTests = careerData.is_without_personal_tests;
  }
  if (careerData.is_educational_streams_hidden_on_registration) {
    career.isEducationalStreamsHiddenOnRegistration =
      careerData.is_educational_streams_hidden_on_registration;
  }
  if (careerData.is_prices_hidden_on_listing_cards) {
    career.isPricesHiddenOnListingCards =
      careerData.is_prices_hidden_on_listing_cards;
  }
  if (careerData.without_b2b2c_order_on_subscription) {
    career.withoutB2B2COrderOnSubscription =
      careerData.without_b2b2c_order_on_subscription;
  }
  if (careerData.is_custom_compensation_available) {
    career.isCustomCompensationAvailable =
      careerData.is_custom_compensation_available;
  }
  if (careerData.without_incorrect_phone_popup) {
    career.withoutIncorrectPhonePopup =
      careerData.without_incorrect_phone_popup;
  }
  if (careerData.without_personal_data_update) {
    career.isWithoutPersonalDataUpdate =
      careerData.without_personal_data_update;
  }
  if (careerData.is_personal_data_fill_optional) {
    career.isPersonalDataFillOptional =
      careerData.is_personal_data_fill_optional;
  }
  if (careerData.is_always_import_course_progresses) {
    career.isAlwaysImportCourseProgresses =
      careerData.is_always_import_course_progresses;
  }

  return career;
};
