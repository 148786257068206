import { css } from 'styled-components';
import { breakpoint, breakpointType } from '../../../styles';

export const getAdaptiveSwitcherStyle = (
  isAdaptive: boolean
): ReturnType<typeof css> => {
  if (isAdaptive) {
    return css`
      max-width: 620px;
      width: 100%;
      margin: 0 auto 32px;
    `;
  }

  return css`
    ${breakpoint(
      'mediumDown',
      css`
        display: none;
      `
    )}
  `;
};

export const getIsHiddenBreakpoint = (
  type?: keyof typeof breakpointType
): ReturnType<typeof css> => {
  if (type) {
    return css`
      ${breakpoint(
        type,
        css`
          display: none;
        `
      )}
    `;
  }

  return css``;
};

export const getIsVisibleBreakpoint = (
  type?: keyof typeof breakpointType
): ReturnType<typeof css> => {
  if (type) {
    return css`
      ${breakpoint(
        type,
        css`
          display: flex;
        `
      )}
    `;
  }

  return css``;
};

export const getItemsSeparator = (
  activeIndex: number,
  linksLength: number
): ReturnType<typeof css> => {
  if (activeIndex !== -1) {
    const currentItem = activeIndex + 1;
    const nextItem = currentItem + 1;
    const hideSeparatorCSS = css`
      &::before {
        display: none;
      }
    `;

    switch (true) {
      case currentItem > 1 && currentItem < linksLength:
        return css`
          & > :nth-child(n + ${currentItem}):nth-child(-n + ${nextItem}) {
            ${hideSeparatorCSS}
          }
        `;
      case currentItem === 1:
        return css`
          & > :nth-child(${nextItem}) {
            ${hideSeparatorCSS}
          }
        `;
      case currentItem === linksLength:
        return css`
          & > :nth-child(${linksLength}) {
            ${hideSeparatorCSS}
          }
        `;
    }
  }

  return css``;
};
