import React, { ReactElement, useState } from 'react';
import { useTheme } from '../../../../../hooks';
import { useTranslations } from '../../../../translations';
import { SelectSearchContainerProps } from './selectSearch.model';
import { IconMagnifierComponent } from '../../../../icons';
import { GenericInputContainer, InputSizes } from '../../../../inputs';
import {
  SearchMagnifierStyled,
  SearchStyled,
  SelectSearchStyled,
} from './selectSearch.styled';

export const SelectSearchContainer = ({
  searchTerm,
  setSearchTerm,
}: SelectSearchContainerProps): ReactElement => {
  const theme = useTheme();
  const translations = useTranslations();
  const [color, setColor] = useState(theme.COLOR.TEXT_LIGHT_COLOR);

  const searchColors = {
    default: theme.COLOR.TEXT_LIGHT_COLOR,
    active: theme.COLOR.TEXT_DARK_COLOR,
  };

  const handleFocus = (): void => {
    if (!!searchColors) {
      setColor(searchColors.active);
    }
  };

  const handleBlur = (): void => {
    if (!!searchColors) {
      setColor(searchTerm ? searchColors.active : searchColors.default);
    }
  };

  return (
    <SelectSearchStyled>
      <SearchStyled color={color} isMobile={theme.IS_MOBILE}>
        <SearchMagnifierStyled>
          <IconMagnifierComponent fillColor={color} strokeColor={color} />
        </SearchMagnifierStyled>
        <GenericInputContainer
          name={'selectSearch'}
          type={'text'}
          placeholder={translations.search}
          onChange={setSearchTerm}
          onBlur={handleBlur}
          onFocus={handleFocus}
          size={InputSizes.small}
          initialValue={searchTerm}
          clearControl={true}
          autoComplete={'off'}
        />
      </SearchStyled>
    </SelectSearchStyled>
  );
};
