import React, { ReactElement } from 'react';
import {
  CommonEventPositions,
  Nullable,
  QueryParams,
  useUser,
} from '@lerna-core';
import {
  GuestOrderFormContainer,
  UserOrderFormContainer,
} from '@features/common/forms';
import { ListFormsContainerProps } from './listForms.model';
import { useCoursesContext } from '@features/courses';
import { useRouter } from 'next/router';

export const ListFormsContainer = ({
  course,
  courseIndex,
}: ListFormsContainerProps): Nullable<ReactElement> => {
  const router = useRouter();
  const { user, reloadUser } = useUser();
  const isPromoCatalog =
    router.asPath.startsWith('/promo/') ||
    router.query[QueryParams.discountProgram];
  const { requestCourses, courses } = useCoursesContext();

  const handleFormSent = (): Promise<void> => {
    return requestCourses({ page: `${courses.meta.currentPage}` }).then(() => {
      if (!isPromoCatalog) {
        reloadUser();
      }
    });
  };

  if (!course) {
    return null;
  }

  if (user) {
    return (
      <UserOrderFormContainer
        course={course}
        handleFormSent={handleFormSent}
        eventPosition={CommonEventPositions.coursesPageList}
        courseIndex={courseIndex}
      />
    );
  }

  return (
    <GuestOrderFormContainer
      course={course}
      handleFormSent={handleFormSent}
      eventPosition={CommonEventPositions.coursesPageList}
      courseIndex={courseIndex}
    />
  );
};
