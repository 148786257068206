import {
  FilterModel,
  FilterTypeEnum,
  getCoursesPath,
  InputSizes,
  Nullable,
} from '@lerna-core';
import React, { ReactElement, useMemo } from 'react';
import { FilterContainerProps } from './filterColumn.model';
import { FilterContainerStyled } from './filterColumn.styled';
import {
  FilterIconContainer,
  FilterToggleContainer,
  FilterTypeItem,
  isCustomCheckboxFilter,
} from '@features/common/filters';

import { useRouter } from 'next/router';

export const FilterColumnContainer = ({
  filter,
  withFilterDescription,
}: FilterContainerProps): Nullable<ReactElement> => {
  const router = useRouter();
  const coursesPath = getCoursesPath(router.query);
  const isToggle = filter.type === FilterTypeEnum.toggle;
  const filterColor = (filter as FilterModel).activeButtonColor;
  const filterBackgroundColor = (filter as FilterModel).backgroundColor;
  const filterIconColor = (filter as FilterModel).iconColor;
  const isCustomFilter = useMemo(
    () => isCustomCheckboxFilter(filter.slug),
    [filter.slug]
  );
  const filterIcon = (
    <FilterIconContainer
      filterSlug={filter.slug}
      background={filterIconColor}
    />
  );

  if (!filter.isVisible) {
    return null;
  }

  return isToggle ? (
    <FilterToggleContainer
      filter={filter as FilterModel}
      withTooltip
      analyticsType="fixed"
    />
  ) : (
    <FilterContainerStyled
      isCustomFilter={isCustomFilter}
      background={filterBackgroundColor}
    >
      <FilterTypeItem
        rangeInputSize={InputSizes.small}
        filter={filter}
        withTitle={true}
        withSearch={true}
        isCollapsed={false}
        withTooltip={false}
        icon={filterIcon}
        filterColor={filterColor}
        withDescription={withFilterDescription}
        defaultPathname={coursesPath}
      />
    </FilterContainerStyled>
  );
};
