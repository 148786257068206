import {
  FastLinkModel,
  FilterModel,
  FilterOptionModel,
  FiltersSlugEnum,
  getCoursesPath,
  getFastLinkHref,
  mainPath,
  Nullable,
  QueryParams,
  TranslationModel,
  urlService,
  UserAggregateModel,
} from '@lerna-core';
import { ParsedUrlQuery } from 'querystring';
import { HeaderLinkTypes } from '@features/common/header';
import { NextRouter } from 'next/router';
import {
  DISCOUNT_PROGRAM_PAGE_REPLACER,
  HeaderBreadcrumbModel,
} from '@features/common/headerBreadcrumbs';

export const getCoursesHeaderLinkType = (
  user: Nullable<UserAggregateModel>,
  query: ParsedUrlQuery
): HeaderLinkTypes => {
  switch (true) {
    case !!query[QueryParams.discountProgram]:
      return HeaderLinkTypes.none;
    case !!user:
      return HeaderLinkTypes.switcher;
    default:
      return HeaderLinkTypes.b2bLanding;
  }
};

const getBreadcrumbsFastlinkLink = (
  router: NextRouter,
  filterOption: FilterOptionModel,
  coursesPath: string
): string => {
  const discount = router.query[QueryParams.discountProgram] as string;

  switch (true) {
    case !!discount:
      return urlService.getFullUrlToPage(
        `${mainPath.discountProgram.replace(
          DISCOUNT_PROGRAM_PAGE_REPLACER,
          discount
        )}`,
        {
          ...router,
          query: {
            ...router.query,
            [FiltersSlugEnum.sphere]: filterOption.slug,
          },
        },
        [FiltersSlugEnum.sphere, QueryParams.promoCode]
      );
    default:
      return urlService.getFullUrlToPage(
        `${coursesPath}/${filterOption.slug}`,
        router,
        [QueryParams.promoCode]
      );
  }
};

export const getCoursesHeaderBreadcrumbs = (
  fastLinks: FastLinkModel[],
  filters: FilterModel[],
  translations: TranslationModel,
  router: NextRouter
): HeaderBreadcrumbModel[] => {
  const coursesPath = getCoursesPath(router.query);
  const result: HeaderBreadcrumbModel[] = [
    {
      title: translations.catalog,
      link: urlService.getFullUrlToPage(coursesPath, router, [
        QueryParams.promoCode,
      ]),
    },
  ];

  const fastLinkQuery = router.query[QueryParams.fastlink];
  const fastLink = fastLinkQuery
    ? fastLinks.find(({ slug }) => slug === fastLinkQuery)
    : null;

  if (fastLink) {
    if (
      fastLink?.options &&
      Object.keys(fastLink?.options).length > 1 &&
      fastLink.options?.[FiltersSlugEnum.sphere]
    ) {
      fastLink.options[FiltersSlugEnum.sphere].forEach((fastLinkOptionSlug) => {
        const sphere = filters.find(
          ({ slug }) => slug === FiltersSlugEnum.sphere
        );
        const sphereOption = sphere?.options.find(
          ({ slug }) => slug === fastLinkOptionSlug
        );

        if (sphereOption) {
          result.push({
            title: sphereOption.value,
            link: getBreadcrumbsFastlinkLink(router, sphereOption, coursesPath),
          });
        }
      });
    }

    result.push({
      title: fastLink.title,
      link: urlService.getFullUrlToPage(
        getFastLinkHref(fastLink.slug, coursesPath),
        router
      ),
    });
  }

  if (result.length === 1) {
    return [];
  }

  return result;
};
