import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';
import { FilterTabStyled } from '../filterTab.styled';
import { FilterOptionSelectAllProps } from './filterTabSelectAll.model';
import {
  filterRouterService,
  getCompositeFastLink,
  getFastLinkHref,
  getFilters,
  urlService,
  useFastLinksContext,
  useTranslations,
} from '@lerna-core';
import { useSelector } from 'react-redux';

export const FilterTabSelectAll = ({
  filter,
  defaultPathname,
  filterColor,
}: FilterOptionSelectAllProps): ReactElement => {
  const router = useRouter();
  const translations = useTranslations();
  const filters = useSelector(getFilters);
  const fastLinks = useFastLinksContext();
  const isActive = !filter.options.some((option) => option.checked);

  const handleClick = (): void => {
    const newQuery = filterRouterService.getNewRouteFromFilters(
      router.query,
      filters
    );

    delete newQuery[filter.slug];

    const fastLink = getCompositeFastLink(fastLinks, newQuery);

    if (fastLink) {
      router.push(
        getFastLinkHref(fastLink.slug, defaultPathname, newQuery),
        undefined,
        {
          scroll: false,
        }
      );
    } else {
      router.push(
        urlService.createUrlFromPathAndQuery(defaultPathname, router, newQuery),
        undefined,
        { scroll: false }
      );
    }
  };

  return (
    <FilterTabStyled
      activeColor={filterColor}
      isActive={isActive}
      onClick={handleClick}
    >
      {translations.all}
    </FilterTabStyled>
  );
};
