import React, { ReactElement, useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import {
  DiscountProgramContext,
  getCurrentPopupFromOpen,
  getSimpleEvent,
  mainPath,
  Nullable,
  PopupsEnum,
  pushDataLayer,
  QueryParams,
  useFastLinksContext,
  useTranslations,
  useUser,
} from '@lerna-core';
import {
  ContactUsFormEvent,
  ContactUsFormEventType,
} from '@features/common/forms/contactUsForm';
import {
  HeaderContainer,
  HeaderEventPositions,
  HeaderLinkTypes,
} from '@features/common/header';
import { DiscountProgramLogoContainer } from '@features/common/discountProgram';
import { HeaderBannerContainer } from '@features/common/headerBanner';
import { SearchPopupWrapContainer } from '@features/search';
import { useCourseContext, useCoursePageContext } from '../course.context';
import { getCourseHeaderBreadcrumbs } from './courseHeader.helper';

export const CourseHeaderContainer = (): Nullable<ReactElement> => {
  const discountProgram = useContext(DiscountProgramContext);
  const { banners } = useCoursePageContext();
  const { user } = useUser();
  const router = useRouter();
  const course = useCourseContext();
  const fastLinks = useFastLinksContext();
  const translations = useTranslations();
  const isSearchHidden =
    !!user?.career?.isSearchHiddenOnListing ||
    !!router.query[QueryParams.discountProgram] ||
    !!router.query[QueryParams.iframeInitiator];
  const breadcrumbs = getCourseHeaderBreadcrumbs(
    course,
    fastLinks,
    translations,
    router
  );

  const contactUsFormPopup = useSelector(
    getCurrentPopupFromOpen(PopupsEnum.contactUsFormPopup)
  );
  const eventPosition = useMemo(() => {
    if (contactUsFormPopup) {
      return contactUsFormPopup.target || '';
    }

    return HeaderEventPositions.coursePageBlockHeader;
  }, [contactUsFormPopup]);
  const headerLinkType = Boolean(user)
    ? HeaderLinkTypes.switcher
    : HeaderLinkTypes.catalog;
  const discountProgramLogo = discountProgram?.logo && (
    <DiscountProgramLogoContainer logo={discountProgram.logo} />
  );

  const handleDataLayer = (eventName: ContactUsFormEvent): void => {
    pushDataLayer(
      getSimpleEvent(
        eventName,
        ContactUsFormEventType.overallEvents,
        eventPosition
      )
    );
  };

  const handleOpenContactUs = (): void => {
    handleDataLayer(ContactUsFormEvent.buttonAskQuestion);
    handleDataLayer(ContactUsFormEvent.formAskQuestionOpen);
  };

  const handleErrorContactUs = (): void => {
    handleDataLayer(ContactUsFormEvent.formAskQuestionError);
  };

  const handleSuccessContactUs = (): void => {
    handleDataLayer(ContactUsFormEvent.formAskQuestionSuccess);
  };

  const handleCloseContactUs = (): void => {
    handleDataLayer(ContactUsFormEvent.formAskQuestionClose);
  };

  return (
    <>
      <HeaderBannerContainer banners={banners} />
      <HeaderContainer
        showSearch={!isSearchHidden}
        showFilters={false}
        hasSticky={true}
        handleOpenContactUs={handleOpenContactUs}
        handleCloseContactUs={handleCloseContactUs}
        handleErrorContactUs={handleErrorContactUs}
        handleSuccessContactUs={handleSuccessContactUs}
        alwaysShowSearch={!isSearchHidden}
        alwaysShowHelpButton
        headerLinkType={headerLinkType}
        discountProgramLogo={discountProgramLogo}
        withLogin={!discountProgram?.isHiddenAuthorization}
        breadcrumbs={breadcrumbs}
      />

      {!isSearchHidden && (
        <SearchPopupWrapContainer
          isSticky={true}
          handleScrollToCourses={() => {}}
          coursesPath={mainPath.courses}
        />
      )}
    </>
  );
};
