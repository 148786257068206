import { ReactElement } from 'react';
import Link from 'next/link';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { useTranslations, useUser } from '../../../../../../features';
import { StaticItemsModel } from './staticItems.model';
import { getStaticItems } from '../../../fastLinksMenu.helper';
import { FastLinkStaticItemModel } from '../../../fastLinksMenu.model';
import { openFastLinksMenu } from '../../../fastLinksMenu.actions';
import * as S from './staticItems.styled';

const StaticItemContainer = ({
  title,
  icon,
  link,
  filtersAccentColor,
}: FastLinkStaticItemModel) => {
  const dispatch = useDispatch();
  const handleCloseFastLinksMenu = () => {
    dispatch(openFastLinksMenu(false));
  };

  return (
    <S.SpheresStaticItemWrapper>
      <Link href={link} onClick={handleCloseFastLinksMenu}>
        <S.SpheresStaticItem filtersAccentColor={filtersAccentColor}>
          <S.SpheresStaticItemImage>{icon}</S.SpheresStaticItemImage>
          <S.SpheresStaticItemTitle>{title}</S.SpheresStaticItemTitle>
        </S.SpheresStaticItem>
      </Link>
    </S.SpheresStaticItemWrapper>
  );
};

export const StaticItemsContainer = ({
  filtersAccentColor,
}: StaticItemsModel): ReactElement => {
  const translations = useTranslations();
  const router = useRouter();
  const { user } = useUser();
  const items = getStaticItems(router, translations, user, filtersAccentColor);

  return (
    <S.SpheresStaticItems>
      {items.map((item, i) => (
        <StaticItemContainer
          filtersAccentColor={filtersAccentColor}
          key={`item_${i}`}
          {...item}
        />
      ))}
    </S.SpheresStaticItems>
  );
};
