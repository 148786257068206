import styled, { css } from 'styled-components';
import { ButtonTitleStyled } from '../../../../features/button/button.styled';
import { breakpoint } from '../../../../styles';
import { FastLinksMenuButtonModel } from './fastLinksMenuButton.model';

const getMobileStyles = (
  isMobile?: boolean,
  margin?: string
): ReturnType<typeof css> => {
  if (isMobile) {
    return css`
      margin: ${margin ? margin : '0 8px 0 0'};

      ${breakpoint(
        'mediumDown',
        css`
          margin: 0 8px 0 0;

          button {
            height: 32px;
            width: 32px;
            padding: 14px;
            box-sizing: border-box;

            svg:first-child {
              margin-right: 0;
            }
          }

          ${ButtonTitleStyled} {
            display: none;
          }
        `
      )}
    `;
  }

  return css`
    margin: ${margin ? margin : '0 8px'};
  `;
};

export const FastLinksMenuButton = styled.div<FastLinksMenuButtonModel>`
  ${({ isMobile, margin }) => getMobileStyles(isMobile, margin)};
`;
