import React, { ReactElement } from 'react';
import { IconProps } from '../../../features';

export const IconAnalyticsDatascienceComponent = ({
  width = 24,
  height = 24,
  fillColor = '#713AE0',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.981 2.48a1.25 1.25 0 0 0-1.23 1.269v1.25h-15a1.25 1.25 0 1 0 0 2.5h.416v17.916C4.167 27.932 6.234 30 8.751 30h5.062l-2.847 5.691a1.25 1.25 0 1 0 2.236 1.117l3.403-6.809h6.79l3.404 6.809a1.249 1.249 0 1 0 2.236-1.117L26.19 30h5.062c2.516 0 4.583-2.067 4.583-4.584V7.5h.417a1.25 1.25 0 1 0 0-2.5h-15v-1.25a1.25 1.25 0 0 0-1.27-1.268ZM6.667 7.5h13.129c.134.022.27.022.405 0h13.133v17.916a2.065 2.065 0 0 1-2.083 2.084H24.35a1.251 1.251 0 0 0-.389 0h-7.919a1.247 1.247 0 0 0-.397 0H8.751a2.065 2.065 0 0 1-2.084-2.084V7.5Zm22.06 4.155a1.25 1.25 0 0 0-.86.377l-8.283 8.283-3.283-3.283a1.25 1.25 0 0 0-1.767 0l-4.167 4.167a1.251 1.251 0 1 0 1.767 1.768l3.283-3.283 3.283 3.283a1.25 1.25 0 0 0 1.768 0l9.166-9.167a1.25 1.25 0 0 0-.908-2.145Z"
        fill={fillColor}
      />
    </svg>
  );
};
