import { Store } from 'redux';
import { NextPageContext } from 'next';
import {
  containUrlFilter,
  CoursesModel,
  Department,
  departmentsByCompanyClientProvider,
  fastLinkRedirectMiddleware,
  FastLinksMenuClientProvider,
  FastLinksMenuSchoolsClientProvider,
  handleSetSearchTerm,
  publicConfig,
  QueryParams,
  requestCurrenciesSuccess,
  requestUserSuccess,
  requestVendorsSuccess,
  serverAuthorizationService,
  setAcceptLanguage,
  setFastLinksMenu,
  setFastLinksMenuSchools,
  setShowcaseStatus,
  ShowcaseRequestProps,
  userPositionsByCompanyProvider,
  UserPositionWithOptions,
  userRedirectMiddleware,
} from '@lerna-core';
import { setSecureHeadersProps } from '@services';
import { CoursesPageWithErrorModel } from './courses.model';
import { discountProgramClientProvider } from '@features/common/discountProgram';
import {
  coursesClientProvider,
  coursesPageClientProvider,
} from './courses.clientProvider';

export async function coursesPageServerRequest(
  store: Store,
  context: NextPageContext
): Promise<CoursesPageWithErrorModel> {
  const {
    user: { user },
    fastLinksMenu: {
      fastlinks: { status: fastLinksMenuStatus },
      schools: { status: fastLinksMenuSchoolsStatus },
    },
  } = store.getState();
  const params = { ...context.query } as ShowcaseRequestProps;
  const locale = context.locale || publicConfig.DEFAULT_LOCALE;
  const discountProgramQuery = params[QueryParams.discountProgram] as string;
  params.page = `${params.page ? params.page : 1}`;
  params.lang = locale;
  if (params.title) {
    params.title = decodeURIComponent(params.title as string);
  }
  store.dispatch(setShowcaseStatus({ isLoaded: false, isLoading: true }));
  const requestHeaders = serverAuthorizationService.getAuthHeaders(
    context.req,
    context.res
  );
  const secureHeaderProps = serverAuthorizationService.isAuthed()
    ? setSecureHeadersProps()
    : {};

  const requests = [] as Promise<unknown>[];

  if (discountProgramQuery) {
    requests.push(
      discountProgramClientProvider(params, discountProgramQuery, {
        ...setAcceptLanguage(locale),
        ...secureHeaderProps,
        ...requestHeaders,
      })
    );
  } else {
    requests.push(
      coursesPageClientProvider(
        params,
        {
          ...setAcceptLanguage(locale),
          ...secureHeaderProps,
          ...requestHeaders,
        },
        user
      )
    );
  }

  const companyId = user?.career?.companyId;
  const isVerified = !!user?.career?.isVerified;

  if (companyId && !isVerified) {
    requests.push(
      departmentsByCompanyClientProvider(companyId),
      userPositionsByCompanyProvider(companyId)
    );
  } else {
    requests.push(Promise.resolve(), Promise.resolve());
  }

  if (
    context.query.title &&
    (context.query.fastlink || containUrlFilter(context.query))
  ) {
    requests.push(
      coursesClientProvider(
        {
          limit: '20',
          title: context.query.title,
        },
        user,
        requestHeaders
      )
    );
  } else {
    requests.push(Promise.resolve());
  }

  if (!fastLinksMenuStatus.isLoaded) {
    requests.push(
      FastLinksMenuClientProvider.get(user, requestHeaders).then(
        (fastLinksMenu) => {
          store.dispatch(setFastLinksMenu(fastLinksMenu));
        }
      )
    );
  } else {
    requests.push(Promise.resolve());
  }

  if (!fastLinksMenuSchoolsStatus.isLoaded) {
    requests.push(
      FastLinksMenuSchoolsClientProvider.get(user, requestHeaders).then(
        (fastLinksMenuSchools) => {
          store.dispatch(setFastLinksMenuSchools(fastLinksMenuSchools));
        }
      )
    );
  } else {
    requests.push(Promise.resolve());
  }

  const requestResults = await Promise.all(requests);
  const coursesData = requestResults[0] as CoursesPageWithErrorModel;
  const departments = requestResults[1] as Department[];
  const positions = requestResults[2] as UserPositionWithOptions[];
  const coursesByTitle = requestResults[3] as CoursesModel;

  fastLinkRedirectMiddleware(
    context,
    coursesData.fastLinks,
    coursesData.filters
  );

  if (coursesData.user) {
    store.dispatch(requestUserSuccess(coursesData.user));
  }
  userRedirectMiddleware(context, coursesData.user);
  store.dispatch(setShowcaseStatus({ isLoaded: true, isLoading: false }));
  store.dispatch(requestCurrenciesSuccess(coursesData.currencies));
  store.dispatch(requestVendorsSuccess(coursesData.vendors));
  store.dispatch(handleSetSearchTerm((context.query.title as string) || ''));

  if (context.res && coursesData.statusCode) {
    context.res.statusCode = coursesData.statusCode;
  }

  return {
    ...coursesData,
    coursesByTitle,
    positions,
    departments,
  };
}
