export enum CourseOptionLogicTypes {
  types = 'types',
  difficulty = 'difficulty',
  spheres = 'spheres',
  schools = 'schools',
  professions = 'professions',
  collections = 'collections',
}

export enum CourseOptionsSlug {
  startDate = 'startDate',
  duration = 'duration',
  difficulty = 'difficulty',
  company = 'company',
  another = 'another',
  type = 'type',
  hit = 'hit',
  bestseller = 'bestseller',
  withEmployment = 'with-employment',
}
