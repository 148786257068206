import React, { ReactElement } from 'react';
import { IconProps } from '../../../features';

export const IconCodeComponent = ({
  width = 24,
  height = 24,
  fillColor = '#713AE0',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.083 4.167a3.77 3.77 0 0 0-3.75 3.75v17.5c0 2.057 1.694 3.75 3.75 3.75h7.083v4.167H11.25a1.25 1.25 0 1 0 0 2.5h3.961c.135.022.271.022.406 0h8.761c.134.022.271.022.405 0h3.967a1.249 1.249 0 0 0 1.17-1.732 1.25 1.25 0 0 0-1.17-.768h-2.917v-4.167h7.084c2.056 0 3.75-1.693 3.75-3.75v-17.5c0-2.056-1.694-3.75-3.75-3.75H7.082Zm0 2.5h25.833c.706 0 1.25.545 1.25 1.25v17.5c0 .706-.544 1.25-1.25 1.25h-8.128a1.247 1.247 0 0 0-.405 0h-8.761a1.247 1.247 0 0 0-.406 0H7.083c-.705 0-1.25-.544-1.25-1.25v-17.5c0-.705.545-1.25 1.25-1.25Zm14.185 3.316a1.25 1.25 0 0 0-1.236.986l-2.5 10.834a1.25 1.25 0 1 0 2.435.563l2.5-10.834a1.25 1.25 0 0 0-1.2-1.55Zm-6.682 2.505a1.25 1.25 0 0 0-.952.449l-2.5 2.917a1.25 1.25 0 0 0 0 1.627l2.5 2.917a1.25 1.25 0 1 0 1.898-1.628l-1.802-2.102 1.802-2.103a1.25 1.25 0 0 0-.946-2.077Zm10.79 0a1.25 1.25 0 0 0-.908 2.077l1.801 2.103-1.801 2.102a1.25 1.25 0 1 0 1.897 1.628l2.5-2.917a1.25 1.25 0 0 0 0-1.627l-2.5-2.917a1.25 1.25 0 0 0-.99-.45Zm-8.71 16.68h6.667v4.166h-6.666v-4.167Z"
        fill={fillColor}
      />
    </svg>
  );
};
