import { dateService, logger, objectService, publicConfig } from '@lerna-core';
import { googleSheetLoggerClientProvider } from '@features/common/forms';

const excludedEmails = ['test_orders@test.ru'];
const MAX_RETRIES = 5;

export const isExcludedEmail = (email?: string): boolean =>
  !!email && excludedEmails.includes(email);

export const mapGoogleSheetLoggerRequestParams = (params: any): any => {
  const requestParameters = new FormData();

  if (!params.formGoogleSheetName) {
    const date = new Date();
    requestParameters.append(
      'formGoogleSheetName',
      `${dateService.formatMonthNumberToString(
        date.getMonth()
      )} ${date.getFullYear()}`
    );
  }

  const flatRequestParams = objectService.deepFlattenToObject(params);
  for (const key in flatRequestParams) {
    requestParameters.append(key, flatRequestParams[key]);
  }

  return requestParameters;
};

export const requestWithTimeout = async (params: any, retryCounter = 0) => {
  googleSheetLoggerClientProvider(
    publicConfig.GOOGLE_SHEET_ORDERS_PATH,
    params
  ).catch((e) => {
    if (retryCounter < MAX_RETRIES) {
      setTimeout(() => {
        requestWithTimeout(params, retryCounter + 1);
        logger.warning(`[Request retry]: gs log retry #${retryCounter + 1}`, e);
      }, (retryCounter + 1) * 700);
    } else {
      logger.warning('[Request retry]: Retries limit exceeded');
    }
  });
};
