import React, { ReactElement } from 'react';
import { IconProps } from '@lerna-core';

export const IconSelfDevelopmentComponent = ({
  width = 40,
  height = 40,
  fillColor = '#713AE0',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.9658 2.00038C19.8221 2.00474 19.6803 2.03393 19.5465 2.08665L2.48951 8.75331C2.3308 8.80838 2.18482 8.89496 2.06031 9.00788C1.9358 9.12079 1.83533 9.25771 1.76491 9.41043C1.69448 9.56316 1.65556 9.72855 1.65048 9.89669C1.64539 10.0648 1.67424 10.2323 1.73529 10.389C1.79635 10.5457 1.88836 10.6885 2.00582 10.8087C2.12327 10.929 2.26375 11.0243 2.41884 11.0889C2.57393 11.1535 2.74044 11.186 2.9084 11.1846C3.07636 11.1833 3.24231 11.1479 3.39631 11.0808L19.9999 4.59153L36.6035 11.0808C36.7575 11.1479 36.9234 11.1833 37.0914 11.1846C37.2594 11.186 37.4259 11.1535 37.581 11.0889C37.7361 11.0243 37.8765 10.929 37.994 10.8087C38.1114 10.6885 38.2035 10.5457 38.2645 10.389C38.3256 10.2323 38.3544 10.0648 38.3493 9.89669C38.3442 9.72855 38.3053 9.56316 38.2349 9.41043C38.1645 9.25771 38.064 9.12079 37.9395 9.00788C37.815 8.89496 37.669 8.80838 37.5103 8.75331L20.4533 2.08665C20.2982 2.02558 20.1324 1.99625 19.9658 2.00038ZM7.9352 12.8337C6.34187 12.8337 5.02303 14.1546 5.02303 15.7504V32.4171C5.02303 34.0128 6.34187 35.3337 7.9352 35.3337H17.1414C17.7136 36.3269 18.7713 37.0004 19.9999 37.0004C21.2285 37.0004 22.2862 36.3269 22.8584 35.3337H32.0646C33.6579 35.3337 34.9768 34.0128 34.9768 32.4171V15.7504C34.9768 14.1546 33.6579 12.8337 32.0646 12.8337H23.3281C21.8784 12.8337 20.6358 13.5652 19.7951 14.6306C18.8035 13.5443 17.4119 12.8337 15.8397 12.8337H7.9352ZM7.9352 15.3337H15.8397C17.447 15.3337 18.7518 16.6405 18.7518 18.2504V32.8337H7.9352C7.68971 32.8337 7.51918 32.6629 7.51918 32.4171V15.7504C7.51918 15.5045 7.68971 15.3337 7.9352 15.3337ZM23.3281 15.3337H32.0646C32.3101 15.3337 32.4806 15.5045 32.4806 15.7504V32.4171C32.4806 32.6629 32.3101 32.8337 32.0646 32.8337H21.248V18.2504V17.4171C21.248 16.2519 22.1647 15.3337 23.3281 15.3337Z"
      fill={fillColor}
    />
  </svg>
);
