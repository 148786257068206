import React, {
  ReactElement,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Nullable } from '../../../models';
import { CertificateModel } from '../certificate.model';
import { logger, publicConfig } from '../../../services';
import {
  CertificateGenerationContextProviderContextModel,
  CertificateGenerationContextProviderProps,
} from './certificateGeneration.model';
import { CertificatePopup } from '../certificatePopup';
import { CertificateSkillbox } from '../certificateTemplate';
import { useUser } from '../../user';
import {
  createCertificateClientProvider,
  updateCertificateClientProvider,
} from '../certificate.clientProvider';

export const CertificateGenerationContext =
  React.createContext<
    Nullable<CertificateGenerationContextProviderContextModel>
  >(null);

export const CertificateGenerationContextProvider = ({
  children,
  courseTitle,
  studentCourseId,
  eventPosition = null,
  getCertificateBlob,
  ...props
}: CertificateGenerationContextProviderProps): ReactElement => {
  const { user } = useUser();
  const [certificate, setCertificate] = useState<Nullable<CertificateModel>>(
    props.certificate
  );
  const [isCertificatePending, setIsCertificatePending] =
    useState<boolean>(false);
  const [retryCounter, setRetryCounter] = useState<number>(0);
  const [isCertificateSendToSupport, setCertificateSendToSupport] =
    useState<boolean>(false);
  const [hasCertificateError, setCertificateError] = useState<boolean>(false);
  const [isCertificatePopupOpened, setIsCertificatePopupOpened] =
    useState<boolean>(false);
  const [shouldUpdateCertificate, setShouldUpdateCertificate] =
    useState<boolean>(false);

  const updateCertificate = async (
    certificate: CertificateModel,
    retry = 0
  ): Promise<void> => {
    if (!certificateRef?.current) {
      return;
    }

    return await getCertificateBlob(certificateRef.current).then(
      async (file) => {
        // если по какой-то причине сгенерился пустой сертификат (он же белый сертификат)
        if (
          !file.size ||
          (typeof file.size === 'number' && file.size > 100000)
        ) {
          await updateCertificateClientProvider(certificate.id, file)
            .then((certificate) => {
              setCertificate(certificate);
            })
            .catch((error) => {
              setCertificateError(true);
              logger.error('[ERROR]: can not save certificate file', error);
            })
            .finally(() => {
              setIsCertificatePending(false);
            });
        } else if (retry < 3) {
          setTimeout(() => {
            updateCertificate(certificate, retry + 1);
          }, 1000);
        } else {
          setIsCertificatePending(false);
          setCertificateSendToSupport(true);

          const loggerFormData = new FormData();
          loggerFormData.append('region', publicConfig.REGION);
          loggerFormData.append('email', `${user?.personal.email}`);
          loggerFormData.append(
            'name',
            `${user?.personal.firstName} ${user?.personal.lastName}`
          );
          loggerFormData.append('certificate_id', certificate.id);
          loggerFormData.append('course_title', courseTitle);
          loggerFormData.append('course_id', certificate.studentCourseId);

          fetch(
            'https://script.google.com/macros/s/AKfycbx7j-DqynjQd9VVM6Vi59yWap2uad9F5hy4vEQOtdrDKYwJTSsKjaKLHP_lijpty3Jd/exec',
            {
              method: 'POST',
              body: loggerFormData,
            }
          );
        }
      }
    );
  };

  const handleGenerateCertificate = async () => {
    setIsCertificatePopupOpened(true);
    if (certificate && !certificate.isFileGenerated) {
      setIsCertificatePending(true);
      setShouldUpdateCertificate(true);
    } else if (!certificate) {
      setIsCertificatePending(true);
      await createCertificateClientProvider(studentCourseId)
        .then(async (certificate) => {
          setCertificate(certificate);
          setShouldUpdateCertificate(true);
        })
        .catch((error) => {
          setCertificateError(true);
          setIsCertificatePending(false);
          logger.error('[ERROR]: can not create course certificate', error);
        });
    }
  };

  const handleCloseCertificatePopup = () => {
    setIsCertificatePopupOpened(false);
    setCertificateError(false);
    setIsCertificatePending(false);
    setCertificateSendToSupport(false);
    setRetryCounter(0);
  };

  const handleSetCertificateError = () => {
    setCertificateError(true);
  };

  const handleRetryGenerateCertificate = () => {
    setRetryCounter(retryCounter + 1);
    handleGenerateCertificate();
  };

  const certificateRef = useRef<Nullable<HTMLDivElement>>(null);

  const context = {
    retryCounter,
    certificate,
    eventPosition,
    handleRetryGenerateCertificate,
    handleGenerateCertificate,
    handleSetCertificateError,
  };

  useEffect(() => {
    if (shouldUpdateCertificate && certificate) {
      setShouldUpdateCertificate(false);
      updateCertificate(certificate);
    }
  }, [certificate, shouldUpdateCertificate]);

  return (
    <CertificateGenerationContext.Provider value={context}>
      {children}
      <CertificatePopup
        certificate={certificate}
        hasError={hasCertificateError}
        isCertificatePending={isCertificatePending}
        isCertificateSendToSupport={isCertificateSendToSupport}
        isOpened={isCertificatePopupOpened}
        handleClose={handleCloseCertificatePopup}
      />
      <div style={{ display: 'none' }} ref={certificateRef} id="certificate">
        <CertificateSkillbox
          certificate={certificate}
          courseTitle={courseTitle}
          user={{
            firstName: user!.personal.firstName,
            lastName: user!.personal.lastName,
          }}
        />
      </div>
    </CertificateGenerationContext.Provider>
  );
};

export const useCertificateGeneration =
  (): CertificateGenerationContextProviderContextModel => {
    const value = useContext(CertificateGenerationContext);

    if (!value) {
      logger.error('[ERROR]: you cannot use context without a provider');

      // если поставить возвращаемый тип null/undefined мы должны будем всегда, при вызове этого контекста проверять
      // не пустой ли он, что неудобно. В любом случае, тут мы получим ошибку при разработке.
      return {} as CertificateGenerationContextProviderContextModel;
    }

    return value;
  };
