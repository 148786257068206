import React, { ReactElement } from 'react';
import { SelectControlComponent, TextControlComponent } from '../../../form';
import { useTranslations } from '../../../translations';
import { useFormCareer } from './formCareer.hook';
import { ButtonColor, ButtonContainer } from '../../../button';

export const FormCareerContainer = (): ReactElement => {
  const translations = useTranslations();
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    handleSelect,
    values,
    isSubmitting,
    errors,
    touched,
    status,
    setFieldValue,
    departmentValue,
    departments,
    positionValue,
    positions,
  } = useFormCareer();

  return (
    <>
      <TextControlComponent
        type="text"
        label={translations?.company_input_label}
        name="company"
        disabled
        touched={touched.company}
        value={values.company}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={translations.company_input_label}
        floatingPlaceholder
        errorMessage={errors.company}
        statusMessage={status?.company}
        showResetButton
        setFieldValue={setFieldValue}
      />
      {!!departments.length && (
        <SelectControlComponent
          label={translations.customer_profile_department_label}
          options={departments}
          name="departmentUuid"
          disabled={isSubmitting}
          value={departmentValue}
          placeholder={translations.placeholder_department}
          floatingPlaceholder
          onChange={handleSelect}
          statusMessage={status?.departmentUuid}
          errorMessage={errors.departmentUuid}
          touched={!!touched.departmentUuid}
          directionOpenTop
        />
      )}
      {!!positions.length && (
        <SelectControlComponent
          label={translations.customer_profile_position_title}
          options={positions}
          name="positionUuid"
          disabled={isSubmitting}
          value={positionValue}
          placeholder={translations.placeholder_position}
          floatingPlaceholder
          onChange={handleSelect}
          statusMessage={status?.positionUuid}
          errorMessage={errors.positionUuid}
          touched={!!touched.positionUuid}
          directionOpenTop
        />
      )}
      <ButtonContainer
        fullWidth
        title={translations.register_form_submit_button}
        borderRadius={100}
        color={ButtonColor.purple}
        disabled={isSubmitting}
        onClick={handleSubmit}
      />
    </>
  );
};
