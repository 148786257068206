import { ScaleMethod } from './mediaPreset.enum';

export const DEFAULT_MEDIA_QUALITY = 80;
export const RETINA_MEDIA_QUALITY = 34;

export const PRESETS = {
  logoCourseSmall: {
    method: ScaleMethod.thumb,
    opts: {
      w: 48,
      h: 48,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  logoCourseMedium: {
    method: ScaleMethod.thumb,
    opts: {
      w: 64,
      h: 64,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  logoCourse: {
    method: ScaleMethod.thumb,
    opts: {
      w: 80,
      h: 80,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  logoCourseXBig: {
    method: ScaleMethod.thumb,
    opts: {
      w: 120,
      h: 120,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  logoCourseXXBig: {
    method: ScaleMethod.thumb,
    opts: {
      w: 344,
      h: 264,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  libraryVendorLogo: {
    method: ScaleMethod.resize,
    opts: {
      w: 80,
      h: 80,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  logoOrder: {
    method: ScaleMethod.thumb,
    opts: {
      w: 50,
      h: 50,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  logoCourseMiddle: {
    method: ScaleMethod.thumb,
    opts: {
      w: 64,
      h: 64,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  logoVendor: {
    method: ScaleMethod.resize,
    opts: {
      w: 208,
      h: 32,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  logoVendorMobile: {
    method: ScaleMethod.resize,
    opts: {
      w: 182,
      h: 28,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  footerImage: {
    method: ScaleMethod.thumb,
    opts: {
      w: 40,
      h: 40,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  footerSocialImage: {
    method: ScaleMethod.thumb,
    opts: {
      w: 32,
      h: 32,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  logoCompanyXXSmall: {
    method: ScaleMethod.resize,
    opts: {
      w: 75,
      h: 22,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  logoCompanyXSmall: {
    method: ScaleMethod.resize,
    opts: {
      w: 90,
      h: 30,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  logoCompanySmall: {
    method: ScaleMethod.resize,
    opts: {
      w: 105,
      h: 40,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  logoCompany: {
    method: ScaleMethod.thumb,
    opts: {
      w: 140,
      h: 48,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  logoCompanyBig: {
    method: ScaleMethod.thumb,
    opts: {
      w: 280,
      h: 96,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  userAvatarLarge: {
    method: ScaleMethod.resize,
    opts: {
      w: 100,
      h: 100,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  userAvatarBig: {
    method: ScaleMethod.resize,
    opts: {
      w: 52,
      h: 52,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  userAvatarXBig: {
    method: ScaleMethod.resize,
    opts: {
      w: 64,
      h: 64,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  userAvatarMedium: {
    method: ScaleMethod.resize,
    opts: {
      w: 50,
      h: 50,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  userAvatarSmall: {
    method: ScaleMethod.resize,
    opts: {
      w: 32,
      h: 32,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  userAvatarXSmall: {
    method: ScaleMethod.resize,
    opts: {
      w: 24,
      h: 24,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  tutorPhoto: {
    method: ScaleMethod.thumb,
    opts: {
      w: 100,
      h: 100,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  tutorPhotoSmall: {
    method: ScaleMethod.thumb,
    opts: {
      w: 50,
      h: 50,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  companyInviteLogo: {
    method: ScaleMethod.thumb,
    opts: {
      w: 150,
      h: 120,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  skillboxCatalogCourseLogo: {
    method: ScaleMethod.thumb,
    opts: {
      w: 100,
      h: 100,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  skillboxCatalogCourseLogoMobile: {
    method: ScaleMethod.thumb,
    opts: {
      w: 64,
      h: 64,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  geekbrainsCourseLogo: {
    method: ScaleMethod.thumb,
    opts: {
      w: 148,
      h: 148,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  subscriptionCourseLogo: {
    method: ScaleMethod.thumb,
    opts: {
      w: 50,
      h: 50,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  advantagesImage: {
    method: ScaleMethod.resize,
    opts: {
      w: 362,
      h: 362,
      q: 100,
    },
  },
  vendorLogoImage: {
    method: ScaleMethod.thumb,
    opts: {
      w: 32,
      h: 32,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  seoImage: {
    method: ScaleMethod.thumb,
    opts: {
      w: 240,
      h: 240,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  discountProgramLogo: {
    method: ScaleMethod.thumb,
    opts: {
      w: 206,
      h: 54,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  discountProgramLogoMobile: {
    method: ScaleMethod.thumb,
    opts: {
      w: 164,
      h: 42,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  headerBannerDesktop: {
    method: ScaleMethod.resize,
    opts: {
      w: 1920,
      h: 96,
      q: 100,
    },
  },
  headerBannerTablet: {
    method: ScaleMethod.resize,
    opts: {
      w: 1280,
      h: 96,
      q: 100,
    },
  },
  headerBannerMobile: {
    method: ScaleMethod.resize,
    opts: {
      w: 768,
      h: 96,
      q: 100,
    },
  },
  headerBannerSmallMobile: {
    method: ScaleMethod.resize,
    opts: {
      w: 480,
      h: 96,
      q: 100,
    },
  },
  orderPopupBannerDesktop: {
    method: ScaleMethod.resize,
    opts: {
      w: 540,
      h: 110,
      q: 100,
    },
  },
  orderPopupBannerMobile: {
    method: ScaleMethod.resize,
    opts: {
      w: 320,
      h: 65,
      q: 100,
    },
  },
  testImageMedium: {
    method: ScaleMethod.thumb,
    opts: {
      w: 290,
      h: 156,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  testImage: {
    method: ScaleMethod.thumb,
    opts: {
      w: 296,
      h: 296,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  courseFilterOption: {
    method: ScaleMethod.thumb,
    opts: {
      w: 40,
      h: 40,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  vendorUploadLogo: {
    method: ScaleMethod.thumb,
    opts: {
      w: 208,
      h: 32,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  courseUploadLogo: {
    method: ScaleMethod.thumb,
    opts: {
      w: 160,
      h: 160,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  paymentMethodLogo: {
    method: ScaleMethod.thumb,
    opts: {
      w: 94,
      h: 22,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  favicon: {
    method: ScaleMethod.thumb,
    opts: {
      w: 32,
      h: 32,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  testQuestionImage: {
    method: ScaleMethod.resize,
    opts: {
      w: 544,
      h: 544,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  testProfessionImage: {
    method: ScaleMethod.resize,
    opts: {
      w: 240,
      h: 240,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  promoCodeCouponDesktop: {
    method: ScaleMethod.thumb,
    opts: {
      w: 288,
      h: 156,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
  promoCodeCouponMobile: {
    method: ScaleMethod.thumb,
    opts: {
      w: 318,
      h: 156,
      q: DEFAULT_MEDIA_QUALITY,
    },
  },
};
