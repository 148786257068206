import {
  OrganizationMetaModel,
  OrganizationMetaTypes,
} from './headOrganizationMeta.model';
import { NextRouter } from 'next/router';
import { publicConfig, urlService } from '../../services';
import { mainPath } from '../../constants';
import { TranslationModel } from '../../models';

const getCountry = (): string => {
  switch (publicConfig.REGION) {
    case 'am':
      return 'Армении';
    case 'az':
      return 'Азербайджане';
    case 'by':
      return 'Беларуси';
    case 'kg':
      return 'Киргизии';
    case 'kz':
      return 'Казахстане';
    case 'md':
      return 'Республике Молдова';
    case 'tj':
      return 'Таджикистане';
    case 'uz':
      return 'Узбекистане';
    case 'ru':
      return 'России';
    default:
      return '';
  }
};

const getName = () => `Маркетплейс Lerna в ${getCountry()}`;
const getDescription = () =>
  `Первый маркетплейс онлайн-курсов в ${getCountry()}: подбор индивидуального онлайн-обучения и переподготовки на ${publicConfig.APP_NAME.toUpperCase()}.${publicConfig.REGION.toLowerCase()}`;

export const mapOrganizationMetaTag = (
  router: NextRouter,
  translations: TranslationModel
): OrganizationMetaModel => {
  return {
    '@context': 'https://schema.org/',
    '@type': OrganizationMetaTypes.organization,
    name: getName(),
    description: getDescription(),
    url: urlService.getFullUrlToPage(mainPath.courses, router),
    image: urlService.getUrlToFile('/logo.svg'),
    legalName: translations.company,
    telephone: translations.company_phone_label,
    email: translations.company_email_label,
    address: {
      '@type': OrganizationMetaTypes.address,
      streetAddress:
        translations.company_address_label ||
        translations.company_address_label_2,
    },
  };
};
