import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { FooterCompanyStyledProps } from './footerBottomUz.model';
import { breakpoint, fontSettings } from '../../../../styles';

export const FooterBottomUzStyled = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 28px 0;
  border-top: 1px solid ${({ theme }): string => rgba(theme.COLOR.WHITE, 0.2)};

  ${breakpoint(
    'largeDown',
    css`
      padding: 32px 0;
      align-items: flex-start;
    `
  )}

  ${breakpoint(
    'mediumDown',
    css`
      flex-direction: column;
      align-items: center;
      gap: 32px;
    `
  )}
`;

export const FooterBottomWrapperStyled = styled.div`
  flex: 0 0 auto;

  ${breakpoint(
    'xlargeDown',
    css`
      flex: 0 1 auto;
    `
  )}
`;

export const FooterBottomInfoStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const FooterBottomLogoStyled = styled.div`
  display: grid;
  gap: 16px;
  position: relative;

  ${breakpoint(
    'mediumDown',
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    `
  )}
`;

export const FooterCompanyStyled = styled.div<FooterCompanyStyledProps>`
  flex: 0 0 20%;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)};
  color: ${({ theme }): string => rgba(theme.COLOR.WHITE, 0.8)};
  display: ${({ isMobile }): string => (isMobile ? 'none' : 'block')};
  margin-bottom: 8px;

  ${({ isMobile }): ReturnType<typeof css> | string =>
    breakpoint(
      'xlargeDown',
      css`
        display: ${isMobile ? 'block' : 'none'};
        ${isMobile ? 'margin-top: 42px;' : ''};
        flex: 0 1 auto;
        text-align: left;
      `
    )}

  ${({ isMobile }): ReturnType<typeof css> | string =>
    breakpoint(
      'mediumDown',
      css`
        display: ${isMobile ? 'none' : 'block'};
        ${isMobile ? 'margin-top: 0;' : ''}
        margin-bottom: 16px;
      `
    )}
`;
