import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconPlusInCircleComponent = ({
  width = 24,
  height = 24,
  strokeColor = '#fff',
  strokeWidth = '2',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="12"
      cy="12"
      r="10"
      stroke={strokeColor}
      strokeWidth={strokeWidth}
    />
    <path
      d="M12 8.25V12m0 3.75V12m0 0h3.75M12 12H8.25"
      stroke="#fff"
      strokeWidth={strokeWidth}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
