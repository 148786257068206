import { ReactElement } from 'react';
import { FastLinksMenuTabsModel } from './fastLinksMenuTabs.model';
import * as S from './fastLinksMenuTabs.styled';

export const FastLinksMenuTabsContainer = ({
  items,
  activeItem,
  handleTabClick,
}: FastLinksMenuTabsModel): ReactElement => (
  <S.FastLinksMenuTabs>
    <S.FastLinksMenuTabsItems>
      {items.map((item, index) => (
        <S.FastLinksMenuTabsItem
          key={`tab_${index}`}
          onClick={handleTabClick.bind(null, item)}
          isActive={activeItem.id === item.id}
        >
          {item.title}
        </S.FastLinksMenuTabsItem>
      ))}
    </S.FastLinksMenuTabsItems>
  </S.FastLinksMenuTabs>
);
