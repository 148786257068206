import { ReactElement } from 'react';
import Image from 'next/image';
import * as S from './footerEdTech.styled';

export const FooterEdTechContainer = (): ReactElement => (
  <S.FooterEdTech>
    <Image width={80} height={90} src="/img/edtech_badge.png" alt="edtech" />
    <S.FooterEdTechLabel>
      {`Глобальный рейтинг\nобразовательных технологий`}
    </S.FooterEdTechLabel>
  </S.FooterEdTech>
);
