import React, { ReactElement } from 'react';
import { IconProps } from '../../../features';

export const IconFinanceAccountingComponent = ({
  width = 24,
  height = 24,
  fillColor = '#713AE0',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.917 1.667c-3.437 0-6.25 2.813-6.25 6.25 0 .73.133 1.43.366 2.083H8.75c-.69 0-1.25-.56-1.25-1.25S8.06 7.5 8.75 7.5h1.271c.046-.88.23-1.72.54-2.5H8.75C6.776 5 5.17 6.54 5.028 8.478c-.02.09-.029.18-.028.272v22.5A3.77 3.77 0 0 0 8.75 35h22.5A3.77 3.77 0 0 0 35 31.25v-17.5c0-1.939-1.51-3.538-3.407-3.716a5.75 5.75 0 0 0 .074-.867c0-3.207-2.627-5.834-5.834-5.834-1.075 0-2.081.3-2.949.814a6.234 6.234 0 0 0-4.967-2.48Zm0 2.5a3.73 3.73 0 0 1 3.123 1.69A5.788 5.788 0 0 0 20 9.166c0 .283.027.56.067.833h-5.25a3.73 3.73 0 0 1 3.1-5.833Zm7.916 1.666A3.314 3.314 0 0 1 29.058 10h-6.449a3.314 3.314 0 0 1 3.224-4.167ZM7.5 12.5h23.75c.705 0 1.25.545 1.25 1.25v17.5c0 .705-.545 1.25-1.25 1.25H8.75c-.705 0-1.25-.545-1.25-1.25V12.5ZM27.917 20a2.083 2.083 0 1 0 0 4.167 2.083 2.083 0 0 0 0-4.167Z"
        fill={fillColor}
      />
    </svg>
  );
};
