import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconShoppingCartComponent = ({
  width = 24,
  height = 24,
  strokeColor = '#713AE0',
  fillColor = '#713AE0',
}: IconProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <path
      stroke={strokeColor}
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M17.093 5.157H23.8l-1.731 9.13a2.686 2.686 0 0 1-2.636 2.191H9.386a2.687 2.687 0 0 1-2.636-2.19L5.02 5.156h7.243"
    />
    <path
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="m5.02 5.157-.16-.842A1.612 1.612 0 0 0 3.28 3h-1.48"
    />
    <path
      fill={fillColor}
      d="M10.74 22c1.1 0 1.991-.895 1.991-2s-.891-2-1.99-2c-1.1 0-1.991.895-1.991 2s.891 2 1.99 2ZM18.76 22c1.099 0 1.99-.895 1.99-2s-.891-2-1.99-2c-1.1 0-1.991.895-1.991 2s.891 2 1.99 2Z"
    />
    <path
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M14.678 3.54v8.086M17.36 10.009l-2.682 2.695-2.683-2.695"
    />
  </svg>
);
