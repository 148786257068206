import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconCloseV2Component = ({
  width = 24,
  height = 24,
  strokeColor = '#ffffff',
  strokeWidth = '2',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox={'0 0 24 24'}
    >
      <path
        d="m6 6 12 12M18 6 6 18"
        strokeLinecap="round"
        stroke={strokeColor}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};
