import { useRouter } from 'next/router';
import { useDiscountProgram } from '@features/common/discountProgram';
import { SeoTextContainer } from '@features/common/seoText';
import { Nullable, useTranslations, useUser } from '@lerna-core';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useCoursesPageContext } from '../coursesPage';
import {
  checkCollapseSeoText,
  getIsCoursesSeoHidden,
} from './coursesSeo.helper';
import * as S from './coursesSeo.styled';
import { SeoTextShowMoreContainer } from './seoTextShowMore';
import { SeoTextImageContainer } from './seoTextImage';

export const CoursesSeoTextContainer = (): Nullable<ReactElement> => {
  const { seo } = useCoursesPageContext();
  const { user } = useUser();
  const router = useRouter();
  const translations = useTranslations();
  const { isDiscountProgramPage } = useDiscountProgram();

  const ref = useRef<Nullable<HTMLDivElement>>(null);

  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [showControls, setShowControls] = useState<boolean>(false);

  const seoText = seo.text;
  const isCoursesSeoHidden = getIsCoursesSeoHidden(
    seo,
    user,
    isDiscountProgramPage
  );

  useEffect(() => {
    if (ref?.current) {
      setIsCollapsed(true);
      setTimeout(() => {
        const needToCollapse = checkCollapseSeoText(ref.current);
        setIsCollapsed(needToCollapse);
        setShowControls(needToCollapse);
      }, 0);
    }
  }, [router.query]);

  const handleShowMore = (): void => {
    setIsCollapsed(false);
    setShowControls(false);
  };

  if (isCoursesSeoHidden) return null;

  return (
    <S.CoursesSeo>
      <S.CoursesSeoWrapper withImage={!!seo.image}>
        <SeoTextImageContainer image={seo.image} />
        <S.CoursesSeoTextOuterWrapper>
          <S.CoursesSeoTextInnerWrapper
            ref={ref}
            withImage={!!seo.image}
            isCollapsed={isCollapsed}
          >
            <S.CoursesSeoText>
              <SeoTextContainer html={seoText!} />
              {showControls && <S.CoursesSeoTextShadow />}
            </S.CoursesSeoText>
          </S.CoursesSeoTextInnerWrapper>
          {showControls && (
            <SeoTextShowMoreContainer
              label={translations.catalog_seo_text_show_more}
              handleClick={handleShowMore}
            />
          )}
        </S.CoursesSeoTextOuterWrapper>
      </S.CoursesSeoWrapper>
    </S.CoursesSeo>
  );
};
