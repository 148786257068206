import { ReactElement, useEffect, useRef } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFastLinkHref,
  getFastLinksCoursesPath,
} from '../../../fastLinks.helper';
import { openFastLinksMenu } from '../../fastLinksMenu.actions';
import * as S from './fastlinks.styled';
import { FastlinksColumnModel, FastlinksModel } from './fastlinks.model';
import { urlService } from '../../../../../services';
import { Nullable } from '../../../../../models';
import { getFastLinksMenuStatusSelector } from '../../fastLinksMenu.selectors';

const FastlinkColumnContainer = ({
  fastlinks,
}: FastlinksColumnModel): ReactElement => {
  const router = useRouter();
  const dispatch = useDispatch();
  const coursesPath = getFastLinksCoursesPath(router.query);

  const handleCloseFastLinksMenu = () => {
    dispatch(openFastLinksMenu(false));
  };

  return (
    <S.FastLinksColumn>
      {fastlinks.map((item) => (
        <Link
          onClick={handleCloseFastLinksMenu}
          href={urlService.getFullUrlToPage(
            getFastLinkHref(item.slug, coursesPath),
            router
          )}
          key={item.slug}
        >
          <S.FastLinksItem>
            <span>{item.title}</span>
          </S.FastLinksItem>
        </Link>
      ))}
      <S.FastLinksItem noPointer />
    </S.FastLinksColumn>
  );
};

export const FastlinksContainer = ({
  activeSphere,
}: FastlinksModel): ReactElement => {
  const { isOpened } = useSelector(getFastLinksMenuStatusSelector);
  const wrapperRef = useRef<Nullable<HTMLDivElement>>(null);
  const halfCounter = Math.ceil(activeSphere.fastlinks.length / 2);

  useEffect(() => {
    wrapperRef.current?.scroll(0, 0);
  }, [isOpened, activeSphere]);

  return (
    <S.FastLinks>
      <S.FastLinksTitle>{activeSphere.title}</S.FastLinksTitle>
      <S.FastLinksItems ref={wrapperRef}>
        <FastlinkColumnContainer
          fastlinks={activeSphere.fastlinks.slice(0, halfCounter)}
        />
        <FastlinkColumnContainer
          fastlinks={activeSphere.fastlinks.slice(halfCounter)}
        />
      </S.FastLinksItems>
    </S.FastLinks>
  );
};
