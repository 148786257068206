import styled from 'styled-components';
import { hideScrollbar } from '../../../../styles';

export const MAX_HEIGHT = '612px';
export const SPHERES_MAX_HEIGHT = '668px';

export const FastLinksMenuDesktopWrapper = styled.div<{
  top?: string;
  isVisible: boolean;
}>`
  display: ${({ isVisible }): string => (isVisible ? 'block' : 'none')};
  position: fixed;
  height: 100vh;
  top: ${({ top = '82px' }) => top};
  width: 100%;
`;

export const FastLinksMenuDesktop = styled.div`
  background: ${({ theme }): string => theme.COLOR.WHITE};
  display: grid;
  position: relative;
  z-index: ${({ theme }): string => theme.ZINDEX.TOP_PAGE_CONTENT + 1};
  grid-template-columns: 316px minmax(300px, auto) 316px;
  gap: 32px;
  padding: 32px 32px 0;
  box-sizing: border-box;
  border-top: 1px solid ${({ theme }): string => theme.COLOR.BLACK_08};
`;

export const FastLinksMenuBackgroundStyled = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${({ theme }): string => theme.ZINDEX.TOP_PAGE_CONTENT};
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  ${hideScrollbar};
`;
