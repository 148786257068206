import React, { ReactElement } from 'react';

import { AdminContentContainer } from './adminContent';
import { AdminPageWrapperModel } from './adminPageWrapper.model';
import {
  AdminPageWrapperContainerStyled,
  AdminPageWrapperContentStyled,
} from './adminPageWrapper.styled';

const DEFAULT_MAX_WIDTH = 1440;
const DEFAULT_MIN_WIDTH = 1024;

export const AdminPageWrapperContainer = ({
  header,
  section,
  heading,
  sidebar,
  footer,
  maxWidth = DEFAULT_MAX_WIDTH,
  minWidth = DEFAULT_MIN_WIDTH,
  isMobile = false,
}: AdminPageWrapperModel): ReactElement => (
  <AdminPageWrapperContainerStyled isMobile={isMobile} minWidth={minWidth}>
    {header}
    <AdminPageWrapperContentStyled maxWidth={maxWidth} isMobile={isMobile}>
      <AdminContentContainer
        sidebar={sidebar}
        heading={heading}
        section={section}
        isMobile={isMobile}
      />
    </AdminPageWrapperContentStyled>
    {footer}
  </AdminPageWrapperContainerStyled>
);
