export type BreadcrumbModel = {
  link: string;
  title: string;
};

export enum BreadcrumbsMetaTypes {
  list = 'BreadcrumbList',
  item = 'ListItem',
}

export type BreadcrumbMetaItemModel = {
  '@id': string;
  name: string;
};

export type BreadcrumbMetaModel = {
  '@type': BreadcrumbsMetaTypes.item;
  position: number;
  item: BreadcrumbMetaItemModel;
};

export type BreadcrumbsMetaModel = {
  '@context': string;
  '@type': BreadcrumbsMetaTypes.list;
  itemListElement: BreadcrumbMetaModel[];
};

export type HeadBreadcrumbsMetaModel = {
  breadcrumbs: BreadcrumbModel[];
};
