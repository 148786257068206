export enum OrganizationMetaTypes {
  organization = 'Organization',
  address = 'PostalAddress',
}

export type OrganizationMetaModel = {
  '@context': string;
  '@type': OrganizationMetaTypes.organization;
  name: string;
  description: string;
  url: string;
  image: string;
  legalName: string;
  telephone: string;
  email: string;
  address: {
    '@type': OrganizationMetaTypes.address;
    streetAddress: string;
  };
};
