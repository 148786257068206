import React, { ReactElement } from 'react';
import {
  FooterBottomInfoStyled,
  FooterBottomLogoStyled,
  FooterBottomUzStyled,
  FooterBottomWrapperStyled,
  FooterCompanyStyled,
} from './footerBottomUz.styled';
import { FooterBottomContainerProps } from './footerBottomUz.model';
import { useTheme } from '../../../../hooks';
import { IconLernaLogoComponent } from '../../../icons';
import { FooterCyberParkContainer } from './footerCyberPark';
import { useTranslations } from '../../../translations';
import { FooterImagesContainer } from '../../footerImages';
import { FooterEdTechContainer } from './footerEdTech';

export const FooterBottomUzContainer = ({
  footerImages,
}: FooterBottomContainerProps): ReactElement => {
  const theme = useTheme();
  const translations = useTranslations();
  const companyText =
    translations?.company &&
    `© ${translations.company}, ${new Date().getFullYear()}`;

  return (
    <FooterBottomUzStyled>
      <FooterBottomWrapperStyled>
        <FooterBottomInfoStyled>
          <FooterBottomLogoStyled>
            <IconLernaLogoComponent
              fillColor={theme.COLOR.WHITE}
              width={83}
              height={39}
            />
            <FooterCompanyStyled>{companyText}</FooterCompanyStyled>
            <FooterImagesContainer images={footerImages.slice(0, 5)} />
          </FooterBottomLogoStyled>
        </FooterBottomInfoStyled>
        <FooterCompanyStyled isMobile>{companyText}</FooterCompanyStyled>
      </FooterBottomWrapperStyled>
      <FooterEdTechContainer />
      <FooterCyberParkContainer translations={translations} />
    </FooterBottomUzStyled>
  );
};
