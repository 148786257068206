import React, { ReactElement } from 'react';
import { IconProps } from '@lerna-core';

export const IconTeamManagementComponent = ({
  width = 40,
  height = 40,
  fillColor = '#713AE0',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fillColor}
      d="M6.23 5A1.25 1.25 0 0 0 5 6.267v27.484A1.25 1.25 0 0 0 6.25 35h27.483a1.25 1.25 0 0 0 .895-2.139 1.248 1.248 0 0 0-.895-.36H7.499V6.267A1.25 1.25 0 0 0 6.229 5Zm27.566 2.502a1.249 1.249 0 0 0-1.122.602l-5.282 8.45-2.654-3.541a1.25 1.25 0 0 0-2.071.107l-4.04 6.734-2.634-3.51a1.25 1.25 0 0 0-2.05.075l-3.747 5.83a1.25 1.25 0 1 0 2.101 1.35l2.777-4.319 2.67 3.56a1.25 1.25 0 0 0 2.07-.107L23.854 16l2.634 3.51a1.25 1.25 0 0 0 2.058-.087l6.246-9.994a1.249 1.249 0 0 0-.996-1.926Z"
    />
  </svg>
);
