import { ReactElement } from 'react';
import Script from 'next/script';
import { mapOrganizationMetaTag } from './headOrganizationMeta.helper';
import { useTranslations } from '../../features';
import { useRouter } from 'next/router';

export const HeadOrganizationMetaTags = (): ReactElement => {
  const router = useRouter();
  const translations = useTranslations();
  const content = mapOrganizationMetaTag(router, translations);

  return (
    // eslint-disable-next-line @next/next/no-before-interactive-script-outside-document
    <Script
      strategy="beforeInteractive"
      type="application/ld+json"
      id="Schema_Organization"
    >
      {JSON.stringify(content)}
    </Script>
  );
};
