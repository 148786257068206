import styled from 'styled-components';
import { rgba } from 'polished';
import { fontSettings } from '../../../../../styles';

export const FooterEdTech = styled.div`
  display: grid;
  gap: 12px;

  img {
    justify-self: center;
  }
`;

export const FooterEdTechLabel = styled.div`
  font-weight: 400;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_11, theme.FONT)};
  color: ${({ theme }): string => rgba(theme.COLOR.WHITE, 0.7)};
  white-space: pre-wrap;
  text-align: center;
`;
