import styled from 'styled-components';

export const CertificateSkillbox = styled.div`
  display: flex;
  flex-direction: column;
  background: #3845f5;
  height: 1684px;
  width: 1190px;
  font-family: sans-serif;
`;

export const CertificateHeaderWrapper = styled.div`
  position: relative;
  background: #29004d;
  padding: 28px 80px 54px;
`;

export const CertificateHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CertificateHeaderLogo = styled.div`
  display: flex;
`;

export const CertificateHeaderSite = styled.div`
  font-weight: 400;
  font-size: 24px;
  line-height: 120%;
  text-align: right;
  color: #ffffff;
  opacity: 0.4;
`;

export const CertificateContent = styled.div`
  position: relative;
  top: -20px;
  background: #3845f5;
  border-radius: 20px 20px 0 0;
  padding: 80px 80px 40px;
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
`;

export const CertificateTitle = styled.div`
  margin-bottom: 80px;
  font-size: 52px;
  line-height: 110%;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.4;
`;

export const CertificateSubtitle = styled.div`
  display: inline-block;
  white-space: nowrap;
  margin-bottom: 20px;
  font-size: 34px;
  //line-height: 120%;
  color: #ffffff;
  opacity: 0.4;
`;

export const CertificateFIO = styled.div`
  margin-bottom: 40px;
  color: #ffffff;
  font-weight: 400;
  font-size: 96px;
  line-height: 100%;
`;

export const CertificateName = styled.div`
  position: relative;
  overflow: hidden;
`;

export const CertificateTail = styled.div`
  display: none;
  position: absolute;
  bottom: 0;
  right: 0;
  background: #3845f5;
`;

export const CertificateBox = styled.div`
  margin-left: 180px;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

export const CertificateCourse = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: 1 1 auto;
  justify-content: center;
`;

export const CertificateCourseLabel = styled.div`
  font-size: 34px;
  line-height: 120%;
  color: #ffffff;
  opacity: 0.4;
`;

export const CertificateCourseVendor = styled.div`
  display: grid;
  gap: 40px;
`;

export const CertificateCourseTitle = styled.div`
  position: relative;
  overflow: hidden;
  font-size: 52px;
  line-height: 110%;
  color: #ffffff;
  white-space: pre-wrap;
`;

export const CertificateSigns = styled.div`
  position: relative;
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

export const CertificateSign = styled.div``;

export const CertificateContacts = styled.div`
  display: grid;
  gap: 4px;
`;

export const CertificateContactsFIO = styled.div`
  font-size: 24px;
  line-height: 120%;
  color: #ffffff;
`;

export const CertificateContactsCompany = styled.div`
  font-size: 24px;
  line-height: 100%;
  color: #ffffff;
  opacity: 0.4;
`;

export const CertificateStamp = styled.div`
  position: absolute;
  right: 0;
  z-index: 2;
`;

export const CertificateId = styled.div`
  display: flex;
  align-items: center;
  opacity: 0.5;
  gap: 10px;
  transform: rotate(-90deg) translate(calc(50% + 24px), -64px);
  position: absolute;
  left: 0;
  bottom: 0;
`;

export const CertificateIdValue = styled.div`
  color: #ffffff;
  font-size: 24px;
  line-height: 120%;
`;

export const CertificateIdDate = styled.div`
  color: #ffffff;
  font-size: 24px;
  line-height: 120%;
`;

export const CertificateIdDivider = styled.div`
  height: 3px;
  width: 44px;
  background: #ffffff;
`;

export const CertificatePolicies = styled.div`
  margin-top: 36px;
  gap: 20px;
  color: #ffffff;
  opacity: 0.7;
  font-size: 19px;
  line-height: 120%;
  margin-left: 180px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  p {
    margin: 0;
  }
`;
