import { publicConfig, RequestHeader, serverConfig } from '../../services';
import { RequestClient } from '../../services/request/request.service';
import {
  courseCertificateResponseAdapter,
  courseCertificatesResponseAdapter,
} from './certificate.adapter';
import {
  CertificateApiResponseModel,
  CertificateModel,
  CertificatesApiResponseModel,
} from './certificate.model';

const CERTIFICATE_ID = '{{CERTIFICATE_ID}}';

export class UpdateCertificateClient extends RequestClient<
  FormData,
  CertificateApiResponseModel,
  CertificateModel
> {
  protected path = `certificates-api/v1/me/certificates/${CERTIFICATE_ID}`;
  protected requestParameters: FormData;
  protected adapter = courseCertificateResponseAdapter;

  constructor(certificateId: string, blob: Blob) {
    super(publicConfig.API_GATEWAY_URL, '', {
      'Content-Type': `multipart/form-data`,
    });
    this.requestParameters = new FormData();
    this.requestParameters.append('file', blob);

    this.path = this.path.replace(CERTIFICATE_ID, certificateId);
  }
}

export class CreateCertificateClient extends RequestClient<
  { student_course_id: string },
  CertificateApiResponseModel,
  CertificateModel
> {
  protected adapter = courseCertificateResponseAdapter;
  protected path = `certificates-api/v1/me/certificates`;
  protected requestParameters: { student_course_id: string };

  constructor(studentCourseId: string) {
    super(publicConfig.API_GATEWAY_URL);
    this.requestParameters = {
      student_course_id: studentCourseId,
    };
  }
}

export class CertificatesClient extends RequestClient<
  null,
  CertificatesApiResponseModel,
  CertificateModel[]
> {
  protected adapter = courseCertificatesResponseAdapter;
  protected path = `certificates-api/v1/me/certificates`;
  protected requestParameters: null;

  constructor(requestHeaders?: RequestHeader) {
    super(
      publicConfig.API_GATEWAY_URL,
      serverConfig?.PRIVATE_API_GATEWAY_INTERNAL_URL,
      requestHeaders
    );
    this.requestParameters = null;
  }
}
