import React, { ReactElement } from 'react';
import { IconProps } from '../../../features';

export const IconGamedevComponent = ({
  width = 24,
  height = 24,
  fillColor = '#713AE0',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.082 7.578A6.234 6.234 0 0 0 3.721 10.6l-.069.117-.048.136s-.89 2.74-1.788 6.005C.918 20.123 0 23.808 0 26.215c0 3.43 2.813 6.238 6.25 6.238a6.26 6.26 0 0 0 6.133-5.049l-.04.146c.157-.497.294-1.062.43-1.608a1.233 1.233 0 0 1 1.211-.975h12.032c.586 0 1.084.39 1.21.975.137.546.274 1.111.43 1.608l-.039-.146a6.26 6.26 0 0 0 6.133 5.05c3.438 0 6.25-2.808 6.25-6.24 0-2.407-.918-6.091-1.816-9.356-.899-3.266-1.787-6.005-1.787-6.005l-.04-.136-.078-.117a6.234 6.234 0 0 0-5.361-3.022H9.082Zm0 2.495h21.836c1.299 0 2.49.683 3.174 1.784.058.195.84 2.593 1.68 5.663.878 3.197 1.728 6.999 1.728 8.695a3.723 3.723 0 0 1-3.75 3.743 3.732 3.732 0 0 1-3.672-3.032l-.039-.136c-.117-.36-.254-.897-.38-1.413v-.01a3.758 3.758 0 0 0-3.643-2.895H13.984c-1.738 0-3.252 1.218-3.652 2.905a17.898 17.898 0 0 1-.371 1.413l-.04.136a3.732 3.732 0 0 1-3.671 3.032 3.723 3.723 0 0 1-3.75-3.743c0-1.696.85-5.498 1.729-8.695.84-3.07 1.62-5.468 1.689-5.663a3.71 3.71 0 0 1 3.164-1.784ZM10 12.491v2.495H7.5v2.495H10v2.496h2.5V17.48H15v-2.495h-2.5v-2.495H10Zm18.75 0c-.693 0-1.25.555-1.25 1.247 0 .692.557 1.248 1.25 1.248S30 14.43 30 13.738s-.557-1.247-1.25-1.247Zm-2.5 2.495c-.693 0-1.25.556-1.25 1.248s.557 1.247 1.25 1.247 1.25-.555 1.25-1.247c0-.692-.557-1.248-1.25-1.248Zm5 0c-.693 0-1.25.556-1.25 1.248s.557 1.247 1.25 1.247 1.25-.555 1.25-1.247c0-.692-.557-1.248-1.25-1.248Zm-2.5 2.495c-.693 0-1.25.556-1.25 1.248s.557 1.248 1.25 1.248S30 19.42 30 18.729s-.557-1.248-1.25-1.248Z"
        fill={fillColor}
      />
    </svg>
  );
};
