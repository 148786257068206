import React, { cloneElement, ReactElement } from 'react';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import {
  canShowFastLinksMenu,
  FastLinksMenuButtonContainer,
  FastLinksMenuContainer,
  handleToggleSearchForm,
  HeaderLoaderContainer as HeaderLoader,
  IconHelpComponent,
  IconMagnifierComponent,
  LogoContainer,
  PRESETS,
  publicConfig,
  QueryParams,
  RegisterWarningContainer,
  useTheme,
  useUser,
} from '@lerna-core';
import { useDiscountProgram } from '@features/common/discountProgram';
import { SearchButtonContainer } from '@features/common/search';
import { getHomePageLink, isCollaborateWithLerna } from '../header.helpers';
import { HeaderMobileContainerProps } from './headerMobile.model';
import {
  HeaderMobileBoxStyled,
  HeaderMobileContainerStyled,
  HeaderMobileIconStyled,
  HeaderMobileWrapperStyled,
} from './headerMobile.styled';
import { HeaderProfileContainer } from './headerProfile';

const { w: logoWidth, h: logoHeight } = PRESETS.logoCompanySmall.opts;

export const HeaderMobileContainer = ({
  isSticky,
  hasSticky,
  showSearch,
  showFilters,
  handleOpenContactUsPopup,
  handleLogoClick,
  discountProgramLogo,
  withLogin,
  homePageHref,
  alwaysShowHelpButton = false,
}: HeaderMobileContainerProps): ReactElement => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const router = useRouter();
  const { user } = useUser();
  const { isDiscountProgramPage } = useDiscountProgram();
  const showFastLinksMenuButton = canShowFastLinksMenu(user, router);

  const handleClickSearch = (): void => {
    dispatch(handleToggleSearchForm(true, null));
  };

  const homePageLink = homePageHref || getHomePageLink(router);
  const iconColor = theme.COLOR.TEXT_DARK_COLOR;
  const withCompany = !!user?.career?.companyId;

  const showProfileButton =
    publicConfig.HAS_ACCOUNT === 'true' &&
    !router.query[QueryParams.discountProgram];
  const logo =
    isDiscountProgramPage && !!discountProgramLogo ? (
      cloneElement(discountProgramLogo, { href: homePageLink })
    ) : (
      <LogoContainer
        href={homePageLink}
        logo={user?.company?.logo}
        width={logoWidth}
        height={logoHeight}
        preset="logoCompanySmall"
        callback={handleLogoClick}
        withCompany={!isDiscountProgramPage && withCompany}
        isCollaborateWithLerna={isCollaborateWithLerna(
          user,
          isDiscountProgramPage
        )}
      />
    );

  return (
    <HeaderMobileContainerStyled
      isSticky={isSticky}
      withCompany={withCompany}
      hasSticky={hasSticky}
    >
      <HeaderMobileWrapperStyled>
        {showFastLinksMenuButton && (
          <HeaderMobileBoxStyled>
            <FastLinksMenuButtonContainer />
          </HeaderMobileBoxStyled>
        )}
        {logo}
        <HeaderMobileBoxStyled>
          {(!isSticky || alwaysShowHelpButton) && (
            <HeaderMobileIconStyled onClick={handleOpenContactUsPopup}>
              <IconHelpComponent
                fillColor={iconColor}
                strokeColor={iconColor}
              />
            </HeaderMobileIconStyled>
          )}
          {showFilters && isSticky && (
            <HeaderMobileIconStyled>
              <SearchButtonContainer isSticky={isSticky} isFullButton={false} />
            </HeaderMobileIconStyled>
          )}
          {showSearch && (
            <HeaderMobileIconStyled onClick={handleClickSearch}>
              <IconMagnifierComponent
                fillColor={iconColor}
                strokeColor={iconColor}
              />
            </HeaderMobileIconStyled>
          )}
          {showProfileButton && (
            <HeaderProfileContainer withLogin={withLogin} />
          )}
        </HeaderMobileBoxStyled>
      </HeaderMobileWrapperStyled>
      <HeaderLoader withCompany={withCompany} />
      <RegisterWarningContainer />
      <FastLinksMenuContainer hasMobileSearch />
    </HeaderMobileContainerStyled>
  );
};
