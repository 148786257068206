import { publicConfig } from '../../../../../../services';
import { RequestClient } from '../../../../../../services/request/request.service';
import { deleteUserPhotoAdapter } from './deleteUserPhoto.adapter';
import { Nullable } from '../../../../../../models';

type ImageEntity = {
  entity: string;
  filename: string;
  path: string;
  server?: Nullable<string>;
};

export type DeleteUserPhotoParams = {
  image: ImageEntity;
  source: ImageEntity;
};

export type DeleteUserApiModel = {
  data: {
    response: { deleted: boolean };
    status: string;
  };
};

export class DeleteUserPhotoClient extends RequestClient<
  DeleteUserPhotoParams,
  DeleteUserApiModel,
  boolean
> {
  protected path = 'ms-uploader-api/v1/image/delete';
  protected requestParameters: DeleteUserPhotoParams;
  protected adapter = deleteUserPhotoAdapter;

  constructor(params: DeleteUserPhotoParams) {
    super(publicConfig.API_GATEWAY_URL, '', {
      'Content-Type': `multipart/form-data`,
    });
    this.requestParameters = params;
  }
}
