import styled, { css } from 'styled-components';

import { getInputColor, InputColor, transition } from '../../../../styles';
import { setBaseInputStyles } from '../input';

export type SelectStyledProps = {
  disabled: boolean;
  bordered: boolean;
  search?: boolean;
  floatingPlaceholder?: boolean;
  hasError?: boolean;
  color?: InputColor;
};

export const SelectStyled = styled.div<SelectStyledProps>`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  order: 0;
  flex-grow: 0;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  ${({ floatingPlaceholder }): ReturnType<typeof css> =>
    setBaseInputStyles(floatingPlaceholder)}
  ${({ bordered, disabled, color, theme, hasError }): string =>
    getInputColor({
      bordered,
      disabled,
      color,
      theme,
      hasError,
    })}
    cursor: pointer;
`;

export const SelectStyledText = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  min-height: 24px;
`;

export const SelectStyledButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${(props): string => props.theme.ZINDEX.TOP_BLOCK};
`;

const getBackground = (activeHovered: boolean): string => `
  background-color: ${activeHovered ? 'rgba(0, 0, 0, 0.04)' : 'initial'};
`;

const getColor = (
  isActive: boolean,
  activeHovered: boolean
): ReturnType<typeof css> => css`
  color: ${({ theme }): string => {
    if (activeHovered) {
      return theme.COLOR.TEXT_DARK_COLOR;
    }

    return isActive
      ? theme.COLOR.TEXT_DARK_COLOR
      : theme.COLOR.TEXT_LIGHT_COLOR;
  }};
`;

type SelectStyledArrowProps = {
  isOpened: boolean;
};

export const SelectStyledArrow = styled.div<SelectStyledArrowProps>`
  height: 16px;
  right: 19px;
  transform: translateY(-50%);
  position: absolute;
  ${transition('transform')}
  ${({ isOpened }): ReturnType<typeof css> =>
    css`
      transform: rotate(${isOpened ? 180 : 0}deg);
    `}
`;

type SelectStyledOptionProps = {
  isActive: boolean;
  multi?: boolean;
  activeHovered: boolean;
};
export const SelectStyledOption = styled.div<SelectStyledOptionProps>`
  cursor: pointer;
  width: 100%;
  padding: 16px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;

  ${({ isActive, activeHovered }): ReturnType<typeof css> =>
    getColor(isActive, activeHovered)}
  ${({ isActive, multi }): string =>
    !(!isActive || !!multi)
      ? `
      font-weight: 500;
      cursor: default;
    `
      : ''};

  &:hover {
    ${({ isActive, multi, activeHovered }): string =>
      !isActive || !!multi ? getBackground(activeHovered) : ''}
  }
`;

export const SelectOptionContainerStyled = styled.div`
  display: flex;
  width: 100%;
`;

export const AllSelectedStyled = styled.span`
  color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};
`;

export const SelectEmptySearchStyled = styled.div`
  padding: 20px 20px 10px;
  text-align: center;
  font-weight: 400;
  color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};
`;
