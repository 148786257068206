import React, { ReactElement } from 'react';
import { IconProps } from '../../../features';

export const PeopleIconComponent = ({
  width = 24,
  height = 24,
  fillColor = '#713AE0',
  strokeColor = '#713AE0',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.9 10.889c0-.208-.013-.412-.039-.611h4.215c.78 0 1.425.643 1.425 1.444v1.39c0 .722-.418 1.416-1.213 1.952-.747.503-1.794.837-2.999.877a3.55 3.55 0 0 0-2.361-2.1c.61-.824.973-1.844.973-2.952Z"
      fill={fillColor}
      stroke={strokeColor}
    />
    <path
      d="M2.925 10.778h3.676l-.001.11c0 .983.26 1.903.711 2.697A4.065 4.065 0 0 0 5.4 15.422c-.983-.08-1.815-.374-2.407-.773-.7-.471-.993-1.027-.993-1.538v-1.389c0-.53.425-.944.925-.944Z"
      fill={fillColor}
      stroke={strokeColor}
      stroke-width="2"
    />
    <path
      d="M7.925 5.333c0 1.11-.89 2-1.975 2a1.988 1.988 0 0 1-1.975-2c0-1.109.889-2 1.975-2s1.975.891 1.975 2Z"
      fill={fillColor}
      stroke={strokeColor}
    />
    <path
      d="M5.95 8.167c-1.539 0-2.8-1.267-2.8-2.834 0-1.567 1.261-2.833 2.8-2.833 1.54 0 2.8 1.266 2.8 2.833 0 1.567-1.26 2.834-2.8 2.834Zm0-5A2.16 2.16 0 0 0 3.8 5.333c0 1.19.96 2.167 2.15 2.167A2.16 2.16 0 0 0 8.1 5.333a2.16 2.16 0 0 0-2.15-2.166ZM20.024 5.333c0 1.11-.889 2-1.975 2a1.988 1.988 0 0 1-1.975-2c0-1.109.89-2 1.975-2 1.086 0 1.975.891 1.975 2Z"
      fill={fillColor}
      stroke={strokeColor}
    />
    <path
      d="M18.05 8.667c-1.82 0-3.3-1.495-3.3-3.334C14.75 3.495 16.23 2 18.05 2c1.82 0 3.3 1.495 3.3 3.333 0 1.839-1.48 3.334-3.3 3.334Zm0-5c-.91 0-1.65.747-1.65 1.666 0 .92.74 1.667 1.65 1.667.91 0 1.65-.748 1.65-1.667a1.66 1.66 0 0 0-1.65-1.666ZM12 14.222c-1.82 0-3.3-1.495-3.3-3.333s1.48-3.333 3.3-3.333c1.82 0 3.3 1.495 3.3 3.333s-1.48 3.333-3.3 3.333Zm0-5c-.91 0-1.65.748-1.65 1.667s.74 1.667 1.65 1.667c.909 0 1.65-.748 1.65-1.667A1.66 1.66 0 0 0 12 9.222ZM12 22c-2.775 0-4.95-1.464-4.95-3.333v-1.39c0-1.071.864-1.944 1.926-1.944h6.05c1.061 0 1.925.873 1.925 1.945v1.389C16.95 20.536 14.777 22 12 22Zm-3.024-5a.277.277 0 0 0-.275.278v1.389c0 .786 1.411 1.666 3.3 1.666 1.889 0 3.3-.88 3.3-1.666v-1.39a.277.277 0 0 0-.275-.277h-6.05Z"
      fill={fillColor}
    />
  </svg>
);
