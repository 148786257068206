import { Column } from 'react-table';
import { ReactElement } from 'react';
import { CourseLinkModel, CourseStatus } from '../../../course';
import {
  MetaPaginationModel,
  Nullable,
  PriceValueModel,
} from '../../../../models';
import { LocationType, Row } from '../../../transfer';
import { TableEmptyContent } from '../../../table';

type TableData = {
  columns?: Column<Record<string, unknown>>[];
  rows: Record<string, unknown>[];
};

export type CourseTableData = {
  uuid: string;
  title: string;
  sphere: string;
  price: PriceValueModel;
  // TODO: сложить всё в объект price
  priceRaw: number;
  status: CourseStatus;
  vendorUuid: string;
  typeTitle: Nullable<string>;
  url: Nullable<CourseLinkModel>;
  slug: string;
  externalId: string;
};

export type CourseTableRow = Omit<
  CourseTableData,
  'title' | 'externalId' | 'price'
> & {
  title: ReactElement | string;
  externalId: ReactElement | string;
  type: Nullable<string>;
  price: string;
};

export type TransferTableModel = {
  data: CourseTableData[];
  meta: MetaPaginationModel;
};

export type CoursesTableProps = {
  selectionColMinWidth?: number;
  selectionColWidth?: number;
  defaultColMinWidth?: number;
  defaultColWidth?: number;
  handleCheckAll?: () => void;
  isRadioButton?: boolean;
  handleCheck?: (rows: Row[]) => void;
  tableData: TableData;
  handlePagination: () => void;
  meta: MetaPaginationModel;
  isLoading: boolean;
  isPaginating: boolean;
  isSelectedAll: boolean;
  getSelectedRows?: (rows: Record<string, unknown>[]) => void;
  headerComponent?: ReactElement | boolean;
  emptyContent: TableEmptyContent;
  locationType: LocationType;
};

export enum CoursesTableAccessors {
  title = 'title',
  sphere = 'sphere',
  vendor = 'vendor',
  price = 'price',
  type = 'type',
  typeFilter = 'typeFilter',
  externalId = 'externalId',
}
