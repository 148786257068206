import React, { ReactElement, useMemo, useRef } from 'react';

import { UserAvatarContainer } from '../../userAvatar';
import { UserBarContainerProps } from './userBarMobile.model';
import {
  UserBarAvatarDefaultStyled,
  UserBarContainerStyled,
  UserBarGradientBorderStyled,
  UserBarLabelStyle,
  UserBarTargetStyled,
} from './userBarMobile.styled';
import { BonusPointsContainer, hasBonusPoints } from '../../../bonusPoints';
import { stringService } from '../../../../services';
import { useTheme, useToggle } from '../../../../hooks';
import { Nullable } from '../../../../models';
import { IconPersonComponent } from '../../../icons';
import { RegisterPositions, useRegisterPopupContext } from '../../../register';
import { useTranslations } from '../../../translations';
import { useUser } from '../../user.hook';
import { MenuMobileContainer } from '../../userMenu';

export const UserBarMobileContainer = ({
  avatarSize,
  isRound,
  handleClick,
  borderRadius = 100,
  menu,
  withLogin,
  projectPageName,
  staticLinks,
}: UserBarContainerProps): Nullable<ReactElement> => {
  const theme = useTheme();
  const translations = useTranslations();

  const [isMenuOpened, toggleMenuOpened] = useToggle(false);
  const { user } = useUser();
  const { handleAction } = useRegisterPopupContext();
  const userBarRef = useRef<Nullable<HTMLDivElement>>(null);
  const withPoints = useMemo(
    () => hasBonusPoints(user?.career, theme.IS_MOBILE),
    [user?.career, theme.IS_MOBILE]
  );

  const bonusLabel = useMemo(() => {
    if (user?.bonuses?.points !== null) {
      return stringService.pluralTranslations(user?.bonuses?.points || 0, [
        translations.points_label_1,
        translations.points_label_2,
        translations.points_label_many,
      ]);
    }

    return '';
  }, [user?.bonuses]);

  const handleClickUserBar = (): void => {
    const onSuccess = () => {
      toggleMenuOpened();

      if (typeof handleClick === 'function') {
        handleClick();
      }
    };

    if (typeof handleAction === 'function') {
      handleAction(
        {
          onSuccess,
        },
        RegisterPositions.userBar
      );
    } else {
      onSuccess();
    }
  };

  const avatar = user?.personal?.isFulfilled ? (
    <UserBarTargetStyled
      borderRadius={borderRadius}
      onClick={handleClickUserBar}
    >
      <UserBarGradientBorderStyled borderRadius={borderRadius} />
      {withPoints && user?.bonuses?.points !== null && (
        <UserBarLabelStyle>
          <BonusPointsContainer
            points={user?.bonuses?.points || 0}
            label={bonusLabel}
          />
        </UserBarLabelStyle>
      )}
      <UserAvatarContainer
        size={avatarSize}
        personal={user?.personal}
        isRound={isRound}
      />
    </UserBarTargetStyled>
  ) : (
    <UserBarAvatarDefaultStyled onClick={handleClickUserBar}>
      <IconPersonComponent strokeColor={theme.COLOR.TEXT_DARK_COLOR} />
    </UserBarAvatarDefaultStyled>
  );

  return (
    <UserBarContainerStyled ref={userBarRef} borderRadius={borderRadius}>
      {avatar}
      <MenuMobileContainer
        isMobile={true}
        menu={menu}
        isOpened={isMenuOpened}
        handleClose={toggleMenuOpened}
        bottomStaticLinks={staticLinks}
        projectPageName={projectPageName}
        withLogin={withLogin}
      />
    </UserBarContainerStyled>
  );
};
