import {
  AdjustmentApiModel,
  AdjustmentApiType,
  AdjustmentModel,
  AdjustmentType,
  CompensationRequestApiModel,
  CompensationRequestModel,
  CompensationRequestsApiModel,
  CompensationRequestsApiStatuses,
  CompensationRequestsModel,
  CompensationRequestsResponse,
  CompensationRequestsStatuses,
} from './compensationRequests.model';
import { mapMetaPaginationAdapter, priceAdapter } from '../../adapters';
import { dateService } from '../../services';
import { vendorAdapter } from '../vendors';
import { mapCustomer } from '../customer';
import { courseAdapter } from '../course';

const mapCompensationRequestStatuses = (
  status: CompensationRequestsApiStatuses
): CompensationRequestsStatuses => {
  switch (status) {
    case CompensationRequestsApiStatuses.pending:
      return CompensationRequestsStatuses.pending;
    case CompensationRequestsApiStatuses.accepted:
      return CompensationRequestsStatuses.accepted;
    case CompensationRequestsApiStatuses.rejected:
      return CompensationRequestsStatuses.rejected;
    case CompensationRequestsApiStatuses.accepted_with_library_claim:
      return CompensationRequestsStatuses.acceptedWithLibraryClaim;
  }
};

const mapAdjustmentType = (type?: AdjustmentApiType): AdjustmentType => {
  switch (type) {
    case AdjustmentApiType.compensationPercent:
    case AdjustmentApiType.customCompensationPercent:
      return AdjustmentType.percent;

    case AdjustmentApiType.compensationAbsolute:
    case AdjustmentApiType.customCompensationAbsolute:
    default:
      return AdjustmentType.absolute;
  }
};

const adjustmentAdapter = (
  adjustment: AdjustmentApiModel
): AdjustmentModel => ({
  uuid: adjustment.uuid,
  type: mapAdjustmentType(adjustment.type),
  value: priceAdapter(adjustment.value),
  priceInfluencePercent: adjustment.price_influence_percent,
});

export const mapCompensationRequest = (
  compensationRequestApi: CompensationRequestApiModel
): CompensationRequestModel => ({
  uuid: compensationRequestApi.uuid,
  status: mapCompensationRequestStatuses(compensationRequestApi.status),
  processedAt: compensationRequestApi.processed_at
    ? dateService.formatDate(compensationRequestApi.processed_at)
    : null,
  createdAt: dateService.formatDate(compensationRequestApi.created_at),
  isAvailableInLibrary: compensationRequestApi.is_available_in_library,
  isCustomCompensation: compensationRequestApi.is_custom_compensation,
  adjustment: compensationRequestApi.adjustment
    ? adjustmentAdapter(compensationRequestApi.adjustment)
    : null,
  initialPrice: priceAdapter(compensationRequestApi.initial_price),
  customerPrice: priceAdapter(compensationRequestApi.customer_price),
  b2bPrice: compensationRequestApi.b2b_price
    ? priceAdapter(compensationRequestApi.b2b_price)
    : null,
  companyPrice: priceAdapter(compensationRequestApi.company_price),
  startDate: compensationRequestApi.course.start_date || '',
  customer: mapCustomer(compensationRequestApi.customer),
  course: courseAdapter(compensationRequestApi.course),
  vendor: vendorAdapter(compensationRequestApi.vendor),
  comment: compensationRequestApi.comment
    ? compensationRequestApi.comment.trim()
    : null,
});

export const compensationRequestsApiAdapter = (
  compensationRequests: CompensationRequestsApiModel
): CompensationRequestsModel => {
  return {
    data: compensationRequests.data.map(mapCompensationRequest),
    meta: mapMetaPaginationAdapter(compensationRequests.meta.pagination),
  };
};

export const mapCompensationRequestsResponse = ({
  data,
}: CompensationRequestsResponse): CompensationRequestsModel => ({
  data: data.data.map((compensationRequest) => {
    return mapCompensationRequest(compensationRequest);
  }),
  meta: mapMetaPaginationAdapter(data.meta.pagination),
});
