import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconPortfolioComponent = ({
  width = 24,
  height = 24,
  fillColor = '#201926',
  strokeColor = '#201926',
  strokeWidth = '2',
}: IconProps): ReactElement => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
    >
      <path
        stroke={strokeColor}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth={strokeWidth}
        d="M21.119 20.4H3.28c-.598 0-1.081-.49-1.081-1.094V6.176c0-.604.483-1.094 1.08-1.094H21.12c.597 0 1.08.49 1.08 1.094v13.13c0 .604-.483 1.094-1.08 1.094Z"
      />
      <path
        stroke={strokeColor}
        strokeLinejoin="round"
        strokeMiterlimit="10"
        strokeWidth={strokeWidth}
        d="M8.146 5.082V2.894c0-.604.484-1.094 1.08-1.094h5.947c.597 0 1.08.49 1.08 1.094v2.188"
      />
      <path
        stroke={strokeColor}
        strokeMiterlimit="10"
        strokeWidth={strokeWidth}
        d="M2.2 14.382h19.73"
      />
      <path
        fill={fillColor}
        d="M13.01 16.297H11.39a.816.816 0 0 1-.81-.82v-2.189c0-.453.362-.82.81-.82h1.622c.447 0 .81.367.81.82v2.188c0 .453-.363.821-.81.821Z"
      />
    </svg>
  );
};
