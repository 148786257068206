import React, { ReactElement } from 'react';

import { CourseItemComponentProps } from './courseItem.model';
import * as S from './courseItem.styled';
import { ImageContainer } from '../../image';
import { CourseItemProgressComponent } from './courseItemProgress';
import { VendorEnum } from '../../vendors';
import { CertificateWarning } from '../../certificate';

export const CourseItemComponent = ({
  course,
  button,
  certificateButton,
  className,
}: CourseItemComponentProps): ReactElement => {
  const courseItemImageResource =
    course?.logo || course?.vendor?.coursePlug?.imageResource;

  return (
    <S.CourseItem className={className}>
      <S.CourseItemRow>
        {!!courseItemImageResource && (
          <S.CourseItemLogo>
            <ImageContainer
              imageResource={courseItemImageResource}
              //TODO: think about naming presets
              preset={'logoCourse'}
            />
          </S.CourseItemLogo>
        )}
        <S.CourseItemContent>
          <S.CourseItemContentWrapper>
            <S.CourseItemInfo>
              <S.CourseItemTitle>{course.title}</S.CourseItemTitle>
              <S.CourseItemDescription>
                {course?.vendor && (
                  <S.CourseItemSubTitle>
                    {course.vendor.title}
                  </S.CourseItemSubTitle>
                )}
                {course?.typeTitle ? (
                  <S.CourseItemSubTitle>
                    {course.typeTitle}
                  </S.CourseItemSubTitle>
                ) : null}
              </S.CourseItemDescription>
            </S.CourseItemInfo>
            <S.CoursesItemButtons>
              {certificateButton}
              {button}
            </S.CoursesItemButtons>
          </S.CourseItemContentWrapper>

          <CourseItemProgressComponent
            vendorSlug={course.vendor?.slug as VendorEnum}
            progress={course.progress}
          />
        </S.CourseItemContent>
      </S.CourseItemRow>
      <S.CourseItemRow>
        <CertificateWarning course={course} />
      </S.CourseItemRow>
    </S.CourseItem>
  );
};
