import { ReactElement } from 'react';
import Script from 'next/script';
import { HeadBreadcrumbsMetaModel } from './headBreadcrumbsMeta.model';
import { mapBreadcrumbsMetaTag } from './headBreadcrumbsMeta.helper';

export const HeadBreadcrumbsMetaTags = ({
  breadcrumbs,
}: HeadBreadcrumbsMetaModel): ReactElement => {
  const content = mapBreadcrumbsMetaTag(breadcrumbs);

  return (
    // eslint-disable-next-line @next/next/no-before-interactive-script-outside-document
    <Script
      strategy="beforeInteractive"
      type="application/ld+json"
      id="Schema_Breadcrumbs"
    >
      {JSON.stringify(content)}
    </Script>
  );
};
