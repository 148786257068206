import styled from 'styled-components';
import { fontSettings } from '../../../../styles';
import * as S from '../courseItem.styled';

export const CourseItemMobile = styled(S.CourseItem)`
  min-width: 200px;
`;

export const CourseItemMobileSubTitle = styled(S.CourseItemSubTitle)`
  margin-top: 8px;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
`;

export const CourseItemMobileContent = styled.div`
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
`;

export const CourseItemMobileInfo = styled.div`
  margin-right: 16px;
`;

export const CourseItemMobileLogo = styled(S.CourseItemLogo)`
  flex: 0 0 48px;
  border-radius: 5px;
  overflow: hidden;
`;

export const CourseItemMobileButtons = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
`;
