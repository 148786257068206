import React, { ReactElement } from 'react';
import { IconProps } from '@lerna-core';

export const IconBusinessManagementComponent = ({
  width = 40,
  height = 40,
  fillColor = '#713AE0',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fillColor}
      d="M15.471 4a2.933 2.933 0 0 0-2.914 2.914v2.081H6.314A2.933 2.933 0 0 0 3.4 11.909v19.98a2.933 2.933 0 0 0 2.914 2.914h27.472a2.933 2.933 0 0 0 2.914-2.914v-19.98a2.933 2.933 0 0 0-2.914-2.914h-6.244V6.914A2.933 2.933 0 0 0 24.63 4H15.47Zm0 2.497h9.158c.245 0 .416.171.416.417v2.081h-9.99V6.914c0-.246.17-.417.416-.417Zm-9.157 4.995h27.472c.246 0 .416.171.416.417v11.239H22.549v-.417c0-.69-.56-1.248-1.25-1.248h-2.497c-.689 0-1.248.559-1.248 1.248v.416H5.897V11.91c0-.246.17-.417.416-.417Zm-.416 14.153h11.654v.416c0 .69.56 1.249 1.25 1.249h2.497c.69 0 1.248-.56 1.248-1.249v-.416h11.655v6.244c0 .245-.17.416-.416.416H6.314a.398.398 0 0 1-.416-.416v-6.244Z"
    />
  </svg>
);
