import { getCourseHref } from '@helpers';
import {
  CommonEventPositions,
  CommonEvents,
  CommonEventTypes,
  containUrlFilter,
  CourseModel,
  DiscountProgramContext,
  getSimpleEvent,
  getVendors,
  handleOpenPopup,
  IframeInitiator,
  Nullable,
  PopupsEnum,
  pushDataLayer,
  QueryParams,
  usePageRouter,
  useTranslations,
  useUser,
} from '@lerna-core';
import { useRouter } from 'next/router';
import React, { ReactElement, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CoursesPaginationContainer } from '../coursesPagination';
import { handleCoursesListDataLayer } from './coursesList.helpers';
import { CoursesListContainerProps } from './coursesList.model';
import {
  CoursesEmptyListStyled,
  CoursesListContainerStyled,
  CoursesListStyled,
} from './coursesList.styled';
import { ListEmptyContainer } from './listEmpty';
import {
  GoToCourseEvent,
  ListItemButtonsEvent,
  ListItemContainer,
} from './listItem';
import { useCourseActiveContext } from '../coursesActive';
import { getCourseEventData } from '../../course';
import { useCoursesContext } from '../courses.context';
import {
  CoursesByTitleListContainer,
  useCoursesByTitleContext,
} from '../coursesByTitleList';

export const CoursesListContainer = ({
  courseBasePath,
}: CoursesListContainerProps): Nullable<ReactElement> => {
  const discountProgram = useContext(DiscountProgramContext);
  const { courses } = useCoursesContext();
  const coursesByTitle = useCoursesByTitleContext();
  const vendors = useSelector(getVendors);
  const dispatch = useDispatch();
  const { setNumericId } = useCourseActiveContext();
  const { user } = useUser();
  const router = useRouter();
  const translations = useTranslations();
  const isRouting = usePageRouter();

  const iframeInitiator = router.query[
    QueryParams.initiator
  ] as IframeInitiator;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const discountProgramColors = iframeInitiator
    ? undefined
    : discountProgram?.colors;
  const isListingWithBenefitsOnly = user?.career?.isListingWithBenefitsOnly;
  const isPricesHiddenOnListingCards =
    user?.career?.isPricesHiddenOnListingCards;
  const initiator = router.query[QueryParams.initiator] as IframeInitiator;
  const fastLink = router.query.fastlink;
  const discount = router.query.discount;
  const isEmptyList =
    courses.data.length === 0 &&
    (!!router.query.title ||
      containUrlFilter(router.query) ||
      !!fastLink ||
      !!discount);
  const isEmptyCoursesByTitleList = coursesByTitle.data.length === 0;

  const handleSuccessShowMore = (): void => {
    setIsLoading(false);
  };

  const handleClickShowMore = (): void => {
    pushDataLayer(
      getSimpleEvent(
        CommonEvents.clickButtonShowMoreCourses,
        CommonEventTypes.overallEvents,
        CommonEventPositions.coursesPageList
      )
    );
    setIsLoading(true);
  };

  const handleOpenSingUpCoursePopup = (
    course: CourseModel,
    courseIndex: number
  ): void => {
    handleCoursesListDataLayer(course, vendors, courseIndex);
    dispatch(
      handleOpenPopup(
        !!user ? PopupsEnum.userOrderFormPopup : PopupsEnum.guestOrderFormPopup,
        CommonEventPositions.coursesPageList
      )
    );
    setNumericId(course.numericId);
  };

  const handleGoToCourse = (
    course: CourseModel,
    courseHref: Nullable<string>,
    courseIndex: number,
    eventName: GoToCourseEvent | ListItemButtonsEvent
  ): void => {
    if (courseHref) {
      pushDataLayer(
        getCourseEventData(
          eventName,
          CommonEventTypes.coursesEvents,
          CommonEventPositions.coursesPageList,
          course,
          vendors,
          courseIndex
        )
      );
    } else {
      handleOpenSingUpCoursePopup(course, courseIndex);
    }
  };

  if (isEmptyList) {
    const title = isEmptyCoursesByTitleList
      ? translations.list_no_results_label
      : translations.list_no_results_with_filters_label;
    const subtitle = isEmptyCoursesByTitleList
      ? translations.list_clear_filters_and_search_label
      : undefined;

    return (
      <CoursesEmptyListStyled isRouting={isRouting}>
        <ListEmptyContainer title={title} subtitle={subtitle} />
        <CoursesByTitleListContainer courseBasePath={courseBasePath} />
      </CoursesEmptyListStyled>
    );
  }

  return (
    <CoursesListContainerStyled>
      <CoursesListStyled isRouting={isRouting}>
        {courses.data.map((course, index) => {
          const courseHref = getCourseHref({
            course,
            router,
            basePath: courseBasePath,
            initiator,
          });

          return (
            <ListItemContainer
              key={course.uuid}
              courseHref={courseHref}
              handleGoToCourse={handleGoToCourse.bind(
                null,
                course,
                courseHref,
                index
              )}
              course={course}
              courseIndex={index}
              isListingWithBenefitsOnly={isListingWithBenefitsOnly}
              isPricesHiddenOnListingCards={isPricesHiddenOnListingCards}
              handleOpenSingUpCoursePopup={handleOpenSingUpCoursePopup.bind(
                null,
                course,
                index
              )}
              discountProgramColors={discountProgramColors}
            />
          );
        })}
      </CoursesListStyled>
      <CoursesPaginationContainer
        isLoading={isLoading}
        handleSuccess={handleSuccessShowMore}
        handleClick={handleClickShowMore}
      />
      <CoursesByTitleListContainer courseBasePath={courseBasePath} />
    </CoursesListContainerStyled>
  );
};
