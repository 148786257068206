import {
  CourseModel,
  CourseOptionLogicTypes,
  FastLinkModel,
  FiltersSlugEnum,
  getCourseOption,
  getCoursesPath,
  getFastLinkHref,
  mainPath,
  QueryParams,
  TranslationModel,
  urlService,
} from '@lerna-core';
import { NextRouter } from 'next/router';
import {
  DISCOUNT_PROGRAM_PAGE_REPLACER,
  HeaderBreadcrumbModel,
} from '@features/common/headerBreadcrumbs';
import { getCourseHref } from '@helpers';

const getBreadcrumbsFastlinkLink = (
  router: NextRouter,
  coursesPath: string,
  sphereSlug?: string,
  schoolSlug?: string
): string => {
  const discount = router.query[QueryParams.discountProgram] as string;

  switch (true) {
    case !!discount && !!schoolSlug && !!sphereSlug:
      return urlService.getFullUrlToPage(
        `${mainPath.discountProgram.replace(
          DISCOUNT_PROGRAM_PAGE_REPLACER,
          discount
        )}`,
        {
          ...router,
          query: {
            ...router.query,
            [FiltersSlugEnum.sphere]: sphereSlug,
            [FiltersSlugEnum.schools]: schoolSlug,
          },
        },
        [FiltersSlugEnum.sphere, FiltersSlugEnum.schools, QueryParams.promoCode]
      );
    case !!discount && !!schoolSlug:
      return urlService.getFullUrlToPage(
        `${mainPath.discountProgram.replace(
          DISCOUNT_PROGRAM_PAGE_REPLACER,
          discount
        )}`,
        {
          ...router,
          query: {
            ...router.query,
            [FiltersSlugEnum.schools]: schoolSlug,
          },
        },
        [FiltersSlugEnum.sphere, FiltersSlugEnum.schools, QueryParams.promoCode]
      );
    case !!discount:
      return urlService.getFullUrlToPage(
        `${mainPath.discountProgram.replace(
          DISCOUNT_PROGRAM_PAGE_REPLACER,
          discount
        )}`,
        {
          ...router,
          query: {
            ...router.query,
            [FiltersSlugEnum.sphere]: sphereSlug,
          },
        },
        [FiltersSlugEnum.sphere, QueryParams.promoCode]
      );
    default:
      return urlService.getFullUrlToPage(
        `${coursesPath}/${sphereSlug}`,
        router,
        [QueryParams.promoCode]
      );
  }
};

export const getCourseHeaderBreadcrumbs = (
  course: CourseModel,
  fastLinks: FastLinkModel[],
  translations: TranslationModel,
  router: NextRouter
): HeaderBreadcrumbModel[] => {
  const discount = router.query[QueryParams.discountProgram] as string;
  const coursesPath = getCoursesPath(router.query);

  const result = [
    {
      title: translations.catalog,
      link: urlService.getFullUrlToPage(coursesPath, router, [
        QueryParams.promoCode,
      ]),
    },
    {
      title: course.title,
      link: getCourseHref({
        course,
        router,
        initiator: null,
      }) as string,
    },
  ];
  const courseSphere = getCourseOption(
    course.options,
    CourseOptionLogicTypes.spheres
  );
  const courseSchool = getCourseOption(
    course.options,
    CourseOptionLogicTypes.schools
  );

  if (courseSphere) {
    result.splice(1, 0, {
      title: courseSphere.value,
      link: getBreadcrumbsFastlinkLink(router, coursesPath, courseSphere.slug),
    });
  }

  if (courseSchool) {
    if (courseSphere) {
      const fastLink = fastLinks.find(({ options }) => {
        const hasSameSphere =
          options?.[FiltersSlugEnum.sphere] &&
          options[FiltersSlugEnum.sphere].length === 1 &&
          options[FiltersSlugEnum.sphere][0] === courseSphere.slug;
        const hasSameSchool =
          options?.[FiltersSlugEnum.schools] &&
          options[FiltersSlugEnum.schools].length === 1 &&
          options[FiltersSlugEnum.schools][0] === courseSchool.slug;

        return hasSameSphere && hasSameSchool;
      });

      if (fastLink) {
        result.splice(2, 0, {
          title: fastLink.title,
          link: discount
            ? getBreadcrumbsFastlinkLink(
                router,
                coursesPath,
                courseSphere.slug,
                courseSchool.slug
              )
            : urlService.getFullUrlToPage(
                getFastLinkHref(fastLink.slug, coursesPath),
                router
              ),
        });
      }
    } else {
      result.splice(1, 0, {
        title: courseSchool.value,
        link: !!discount
          ? getBreadcrumbsFastlinkLink(
              router,
              coursesPath,
              undefined,
              courseSchool.slug
            )
          : urlService.getFullUrlToPage(
              getFastLinkHref(courseSchool.slug, coursesPath),
              router
            ),
      });
    }
  }

  return result;
};
