import { Store } from 'redux';
import { GetServerSidePropsContext } from 'next';
import {
  Department,
  departmentsByCompanyClientProvider,
  FastLinksMenuClientProvider,
  FastLinksMenuSchoolsClientProvider,
  publicConfig,
  QueryParams,
  requestCurrenciesSuccess,
  requestUserSuccess,
  requestVendorsSuccess,
  serverAuthorizationService,
  setAcceptLanguage,
  setFastLinksMenu,
  setFastLinksMenuSchools,
  userPositionsByCompanyProvider,
  UserPositionWithOptions,
  userRedirectMiddleware,
} from '@lerna-core';
import { discountProgramCourseClientProvider } from '@features/common/discountProgram';
import { setSecureHeadersProps } from '@services';
import { CoursePageModel, CourseRequestParams } from './course.model';
import { courseClientProvider } from './courseClient.provider';

export const courseDataServerRequest = async (
  store: Store,
  context: GetServerSidePropsContext
): Promise<CoursePageModel> => {
  const params = context.query as CourseRequestParams;

  const {
    user: { user },
    fastLinksMenu: {
      fastlinks: { status: fastLinksMenuStatus },
      schools: { status: fastLinksMenuSchoolsStatus },
    },
  } = store.getState();
  const secureHeaderProps = !!user ? setSecureHeadersProps() : {};
  const locale = context.locale || publicConfig.DEFAULT_LOCALE;
  const discountProgramQuery = params[QueryParams.discountProgram] as string;
  const promoCode = params[QueryParams.promoCode] as string;
  const requestHeaders = serverAuthorizationService.getAuthHeaders(
    context.req,
    context.res
  );

  const requests = [] as Promise<unknown>[];

  if (discountProgramQuery) {
    requests.push(
      discountProgramCourseClientProvider(
        {
          discount: discountProgramQuery,
          slug: params.slug,
          ...(promoCode && { promo_code: promoCode }),
        },
        {
          ...setAcceptLanguage(locale),
          ...secureHeaderProps,
          ...requestHeaders,
        }
      )
    );
  } else {
    requests.push(
      courseClientProvider(
        params,
        {
          ...setAcceptLanguage(locale),
          ...secureHeaderProps,
          ...requestHeaders,
        },
        user
      )
    );
  }

  const companyId = user?.career?.companyId;
  const isVerified = !!user?.career?.isVerified;

  if (companyId && !isVerified) {
    requests.push(
      departmentsByCompanyClientProvider(companyId),
      userPositionsByCompanyProvider(companyId)
    );
  }
  if (!fastLinksMenuStatus.isLoaded) {
    requests.push(
      FastLinksMenuClientProvider.get(user, requestHeaders).then(
        (fastLinksMenu) => {
          store.dispatch(setFastLinksMenu(fastLinksMenu));
        }
      )
    );
  } else {
    requests.push(Promise.resolve());
  }

  if (!fastLinksMenuSchoolsStatus.isLoaded) {
    requests.push(
      FastLinksMenuSchoolsClientProvider.get(user, requestHeaders).then(
        (fastLinksMenuSchools) => {
          store.dispatch(setFastLinksMenuSchools(fastLinksMenuSchools));
        }
      )
    );
  } else {
    requests.push(Promise.resolve());
  }

  const requestResults = await Promise.all(requests);
  const courseData = requestResults[0] as CoursePageModel;
  const departments = (requestResults[1] as Department[]) || [];
  const positions = (requestResults[2] as UserPositionWithOptions[]) || [];

  if (courseData.user) {
    store.dispatch(requestUserSuccess(courseData.user));
  }
  store.dispatch(requestCurrenciesSuccess(courseData.currencies));
  store.dispatch(requestVendorsSuccess(courseData.vendors));

  courseData.referer = context.req.headers.referer || null;

  userRedirectMiddleware(context, courseData.user);

  return { ...courseData, departments, positions };
};
