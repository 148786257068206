import React, { ReactElement } from 'react';
import { IconProps } from '../../../features';

export const AllSpheresIconComponent = ({
  width = 40,
  height = 40,
  fillColor = '#713AE0',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.58333 5C7.06685 5 5 7.06685 5 9.58333V30.4167C5 32.9331 7.06685 35 9.58333 35H30.4167C32.9331 35 35 32.9331 35 30.4167V9.58333C35 7.06685 32.9331 5 30.4167 5H9.58333ZM9.58333 7.5H18.75V18.75H7.5V9.58333C7.5 8.41814 8.41814 7.5 9.58333 7.5ZM21.25 7.5H30.4167C31.5819 7.5 32.5 8.41814 32.5 9.58333V18.75H21.25V7.5ZM7.5 21.25H18.75V32.5H9.58333C8.41814 32.5 7.5 31.5819 7.5 30.4167V21.25ZM21.25 21.25H32.5V30.4167C32.5 31.5819 31.5819 32.5 30.4167 32.5H21.25V21.25Z"
      fill={fillColor}
    />
  </svg>
);
