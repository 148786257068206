import React, { ReactElement } from 'react';
import { IconProps } from '../../../features';

export const IconSelfDevelopmentComponent = ({
  width = 24,
  height = 24,
  fillColor = '#713AE0',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.833 1.667c-2.746 0-5 2.253-5 5h-5.416A2.936 2.936 0 0 0 7.5 9.583V15c-2.747 0-5 2.253-5 5s2.253 5 5 5v5.417a2.936 2.936 0 0 0 2.917 2.916h5.416c0 2.747 2.254 5 5 5 2.747 0 5-2.253 5-5h5.417a2.936 2.936 0 0 0 2.917-2.916V23.75a1.25 1.25 0 0 0-1.25-1.25h-2.084a2.482 2.482 0 0 1-2.5-2.5c0-1.395 1.105-2.5 2.5-2.5h2.084a1.25 1.25 0 0 0 1.25-1.25V9.583a2.936 2.936 0 0 0-2.917-2.916h-5.417c0-2.747-2.253-5-5-5Zm0 2.5c1.395 0 2.5 1.105 2.5 2.5v1.25a1.25 1.25 0 0 0 1.25 1.25h6.667c.246 0 .417.17.417.416V15h-.834c-2.746 0-5 2.253-5 5s2.254 5 5 5h.834v5.417c0 .246-.171.416-.417.416h-6.667a1.25 1.25 0 0 0-1.25 1.25v1.25c0 1.395-1.105 2.5-2.5 2.5a2.482 2.482 0 0 1-2.5-2.5v-1.25a1.25 1.25 0 0 0-1.25-1.25h-6.666a.398.398 0 0 1-.417-.416V23.75a1.25 1.25 0 0 0-1.25-1.25H7.5A2.482 2.482 0 0 1 5 20c0-1.395 1.105-2.5 2.5-2.5h1.25A1.25 1.25 0 0 0 10 16.25V9.583c0-.246.17-.416.417-.416h6.666a1.25 1.25 0 0 0 1.25-1.25v-1.25c0-1.395 1.105-2.5 2.5-2.5Z"
        fill={fillColor}
      />
    </svg>
  );
};
