import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconGiftComponent = ({
  width = 24,
  height = 24,
  strokeWidth = '2',
  strokeColor = '#713AE0',
}: IconProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <path
      stroke={strokeColor}
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth={strokeWidth}
      d="M21.054 11.5H4.145a.544.544 0 0 1-.545-.542V7.163c0-.3.244-.542.545-.542h16.91c.3 0 .545.243.545.542v3.795c0 .3-.245.542-.546.542Z"
    />
    <path
      stroke={strokeColor}
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth={strokeWidth}
      d="M20.509 11.5v7.59a2.72 2.72 0 0 1-2.727 2.71H7.418a2.72 2.72 0 0 1-2.727-2.71V11.5M9.873 6.621a2.72 2.72 0 0 1-2.728-2.71A2.72 2.72 0 0 1 9.873 1.2 2.72 2.72 0 0 1 12.6 3.91M12.6 3.91a2.72 2.72 0 0 1 2.727-2.71 2.72 2.72 0 0 1 2.728 2.71 2.72 2.72 0 0 1-2.728 2.711M12.6 6.7V3.64"
    />
  </svg>
);
