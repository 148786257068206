import {
  GenericInputClearStyled,
  getSearchTermValueSelector,
  handleSetSearchTerm,
  handleToggleSearchForm,
  IconCloseComponent,
  IconMagnifierComponent,
  Nullable,
  useTheme,
  useTranslations,
} from '@lerna-core';
import React, { MutableRefObject, ReactElement, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchContainerStyled, SearchTextStyled } from './search.styled';

export const SearchContainer = (): Nullable<ReactElement> => {
  const translations = useTranslations();
  const dispatch = useDispatch();
  const theme = useTheme();

  const suggest = useSelector(getSearchTermValueSelector);

  const inputWrapRef = useRef<Nullable<HTMLElement>>(
    null
  ) as MutableRefObject<HTMLInputElement>;

  const searchPlaceholder = theme.IS_MOBILE
    ? translations?.search_placeholder_mobile
    : translations?.search_placeholder;

  const getSuggestColor = (): string =>
    !!suggest ? theme.COLOR.TEXT_DARK_COLOR : theme.COLOR.TEXT_LIGHT_COLOR;

  const searchContainerText = suggest || searchPlaceholder;

  const handleOpen = (): void => {
    let currentPosition = null;
    if (inputWrapRef) {
      const position = (
        inputWrapRef.current as Element
      ).getBoundingClientRect();
      currentPosition = {
        top: position.top,
        left: position.left,
        width: position.width,
      };
    }
    dispatch(handleToggleSearchForm(true, currentPosition));
  };

  const handleClear = (): void => {
    dispatch(handleSetSearchTerm(''));
    setTimeout(handleOpen, 0);
  };

  return (
    <SearchContainerStyled
      onClick={handleOpen}
      ref={inputWrapRef}
      hasSuggest={!!suggest}
      color={getSuggestColor()}
    >
      <IconMagnifierComponent
        width={24}
        height={24}
        fillColor={getSuggestColor()}
        strokeColor={getSuggestColor()}
      />
      <SearchTextStyled>{searchContainerText}</SearchTextStyled>
      <GenericInputClearStyled
        visibleClearControl={!!suggest}
        onClick={handleClear}
      >
        <IconCloseComponent
          width={8}
          height={8}
          strokeWidth={'4'}
          strokeColor={theme.COLOR.WHITE}
        />
      </GenericInputClearStyled>
    </SearchContainerStyled>
  );
};
