import React, { useContext } from 'react';
import { CoursesModel, initialPaginationMeta, Nullable } from '@lerna-core';
import { useCoursesContext } from '../courses.context';

export const CoursesByTitleContext =
  React.createContext<Nullable<CoursesModel>>(null);

export const useCoursesByTitleContext = (): CoursesModel => {
  const { courses } = useCoursesContext();
  const value = useContext(CoursesByTitleContext);

  if (!value || courses.meta.total > 20) {
    return {
      data: [],
      meta: initialPaginationMeta,
    } as CoursesModel;
  }

  return {
    data: value.data
      .filter((x) => !courses.data.some((course) => course.uuid === x.uuid))
      .slice(0, 4),
    meta: value.meta,
  };
};
