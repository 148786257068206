import React, { ReactElement, useContext } from 'react';
import { useSelector } from 'react-redux';
import {
  DiscountProgramContext,
  filterRouterService,
  getAdditionalColors,
  getSelectedFiltersCountSelector,
  IconDeleteComponent,
  pushDataLayer,
  urlService,
  usePageRouter,
  useTheme,
  useTranslations,
  useUser,
} from '@lerna-core';
import { useCoursesPageContext } from '../../coursesPage';
import {
  CounterStyled,
  FiltersCoursesCounterStyled,
  IconStyled,
  WrapperStyled,
} from './filtersCoursesCounter.styled';
import { FiltersCoursesCounterContainerProps } from './filtersCoursesCounter.model';
import { CounterLoaderContainer } from './counterLoader';
import { useRouter } from 'next/router';
import { pageUrls } from '@constants';
import {
  FilterEvent,
  FilterEventCategories,
  FilterEventParams,
  getFilterSimpleEventData,
} from '@features/common/filters';
import { useCoursesContext } from '../../courses.context';

export const FiltersCoursesCounterContainer = ({
  isSticky,
}: FiltersCoursesCounterContainerProps): ReactElement => {
  const translations = useTranslations();
  const { courses } = useCoursesContext();

  const { filters } = useCoursesPageContext();
  const discountProgram = useContext(DiscountProgramContext);
  const { user } = useUser();
  const { filtersAccentColor } = getAdditionalColors(
    discountProgram,
    user?.company?.uuid
  );
  const router = useRouter();
  const theme = useTheme();
  const filtersSelectedCount = useSelector(getSelectedFiltersCountSelector);
  const isRouting = usePageRouter();

  const handleResetAllFilters = (): void => {
    const defaultPathname = router.query?.fastlink
      ? pageUrls.courses
      : router.pathname;

    const newQuery = filterRouterService.clearFilters(filters, router.query);

    pushDataLayer(
      getFilterSimpleEventData(
        FilterEvent.constructorFiltersMain,
        FilterEventCategories.allCategory,
        FilterEventParams.allParam
      )
    );

    router.push(
      urlService.createUrlFromPathAndQuery(defaultPathname, router, newQuery),
      undefined,
      { scroll: false }
    );
  };

  return (
    <FiltersCoursesCounterStyled
      isSticky={isSticky}
      isRouting={isRouting}
      hasSelectedFilters={!!filtersSelectedCount}
    >
      <CounterStyled isRouting={isRouting}>
        {translations?.found} {courses.meta.total}{' '}
        {translations?.course_label_many?.toLowerCase()}
      </CounterStyled>

      {isRouting && (
        <WrapperStyled>
          <CounterLoaderContainer customColor={filtersAccentColor} />
        </WrapperStyled>
      )}

      {!!filtersSelectedCount && !isRouting && (
        <WrapperStyled>
          <IconStyled onClick={handleResetAllFilters}>
            <IconDeleteComponent
              width={20}
              height={20}
              strokeColor={filtersAccentColor || theme.COLOR.PRIMARY[500]}
            />
          </IconStyled>
        </WrapperStyled>
      )}
    </FiltersCoursesCounterStyled>
  );
};
