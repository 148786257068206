import { Nullable } from '@lerna-core';
import { ReactElement } from 'react';
import { HeaderBreadcrumbModel } from '@features/common/headerBreadcrumbs/headerBreadcrumbs.model';

export enum HeaderLinkTypes {
  switcher = 'switcher',
  b2bLanding = 'b2bLanding',
  catalog = 'catalog',
  none = 'none',
}

export type HeaderContainerProps = {
  breadcrumbs?: HeaderBreadcrumbModel[];
  showSearch: boolean;
  showFilters: boolean;
  hasSticky: boolean;
  handleSticky?: (stickyStatus: boolean) => void;
  handleOpenContactUs?: () => void;
  handleErrorContactUs?: () => void;
  handleSuccessContactUs?: () => void;
  handleCloseContactUs?: () => void;
  alwaysShowSearch?: boolean;
  alwaysShowHelpButton?: boolean;
  headerLinkType?: HeaderLinkTypes;
  withMobileMenu?: boolean;
  withLogin?: boolean;
  useLoginText?: boolean;
  homePageHref?: string;
  discountProgramLogo?: Nullable<ReactElement>;
};
