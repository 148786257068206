export type CoursesFilter = {
  [key: string]: CoursesFilterOption[];
};

export type CoursesFilterOption = {
  [key: string]: string;
};

export type CoursesFilterOptionsApi = {
  data: CoursesFilterOptionApi[];
};

export type CoursesFilterOptionsApiResponse = {
  data: {
    data: {
      options: CoursesFilterOptionsApi;
    };
  };
};

export type CoursesFilterOptionApi = {
  slug: string;
  value: string;
};

export type CoursesFilterApi = {
  slug: string;
  options: CoursesFilterOptionsApi;
};

export type CoursesFiltersApi = {
  data: CoursesFilterApi[];
};

export enum CoursesFiltersConstants {
  REQUEST_COURSES_FILTERS_START = 'REQUEST_COURSES_FILTERS_START',
  REQUEST_COURSES_FILTERS_SUCCESS = 'REQUEST_COURSES_FILTERS_SUCCESS',
  REQUEST_COURSES_FILTERS_ERROR = 'REQUEST_COURSES_FILTERS_ERROR',
}

export type CoursesFiltersActions = {
  type: CoursesFiltersConstants;
  payload?: CoursesFilter;
};

export type CoursesFiltersState = {
  status: {
    isLoading: boolean;
    isLoaded: boolean;
  };
  filters: CoursesFilter;
};
