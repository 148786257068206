import { Nullable } from '../../../models';
import { UserAggregateModel } from '../../user';
import { CertificateModel } from '../certificate.model';
import { CourseItemModel, isCertificateCanBeGranted } from '../../courses';

export const showCertificateWarning = (
  user: Nullable<UserAggregateModel>,
  certificate: Nullable<CertificateModel>,
  course: CourseItemModel
): boolean =>
  !certificate?.isFileGenerated &&
  isCertificateCanBeGranted(
    user,
    certificate,
    course.type,
    course.vendor,
    course.access.accessType,
    course.access.isHomeworkAvailable
  ) &&
  course.progress?.progressPercent !== 100;
