import styled from 'styled-components';
import { fontSettings, positionSticky, transition } from '../../../../styles';
import { getTabStyles } from './fastLinksMenuTabs.mixin';

export const FastLinksMenuTabs = styled.div`
  margin: 0 -16px;
  padding: 8px 20px 8px;
  top: 53px;
  background-color: ${({ theme }): string => theme.COLOR.WHITE};
  ${positionSticky()};
  z-index: 1;
`;

export const FastLinksMenuTabsItems = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: ${({ theme }): string => theme.COLOR.BLACK_4};
  padding: 2px;
  border-radius: 100px;
`;

export const FastLinksMenuTabsItem = styled.div<{ isActive: boolean }>`
  text-align: center;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
  font-weight: 500;
  padding: 10px;
  border-radius: 100px;
  background-color: transparent;
  ${transition('background-color')}
  ${({ isActive, theme }) => getTabStyles(isActive, theme)}
`;
