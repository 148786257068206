import { publicConfig, RequestHeader, serverConfig } from '../../../services';
import { RequestClient } from '../../../services/request/request.service';
import {
  FastLinkMenuApiResponseModel,
  FastLinkMenuModel,
} from './fastLinksMenu.model';
import { fastLinksMenuResponseAdapter } from './fastLinksMenu.adapter';

export class FastLinksMenuClient extends RequestClient<
  null,
  FastLinkMenuApiResponseModel,
  FastLinkMenuModel[]
> {
  protected path;
  protected requestParameters = null;
  protected adapter = fastLinksMenuResponseAdapter;

  constructor(path: string, requestHeaders?: RequestHeader) {
    super(
      publicConfig.API_GATEWAY_URL,
      serverConfig?.PRIVATE_API_GATEWAY_INTERNAL_URL,
      requestHeaders
    );
    this.path = path;
    this.requestParameters = null;
  }
}
