import styled, { css } from 'styled-components';
import { breakpoint, fontSettings, lineClamping } from '../../../styles';

export const CourseItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-shadow: 0 5px 25px -5px rgba(12, 11, 13, 0.04);
  border-radius: 16px;
  background-color: ${({ theme }): string => theme.COLOR.WHITE};
  width: 100%;
  padding: 20px;
  box-sizing: border-box;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;

export const CourseItemRow = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  &:empty {
    display: none;
  }
`;

export const CourseItemContent = styled.div`
  margin-left: 20px;
  flex: 1 1 100%;
`;

export const CourseItemInfo = styled.div``;

export const CourseItemTitle = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_20, theme.FONT)}
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  font-weight: 500;
  ${lineClamping(2)}
  max-height: 48px;
`;

export const CourseItemSubTitle = styled.div`
  margin-top: 8px;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)}
  color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};
`;

export const CourseItemLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 72px;
  border-radius: 8px;
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const CourseItemContentWrapper = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
`;

export const CourseItemDescription = styled.div`
  display: flex;

  ${CourseItemSubTitle} {
    &:nth-of-type(2) {
      position: relative;

      &:before {
        content: '·';
        padding: 0 5px;
        font-weight: 700;
      }
    }
  }
`;

export const CoursesItemButtons = styled.div`
  align-self: flex-start;
  display: flex;
  gap: 8px;

  ${breakpoint(
    'xlargeDown',
    css`
      flex-direction: column;
      align-items: flex-end;
    `
  )}
`;
