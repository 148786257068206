import {
  CourseModel,
  CourseOptionLogicTypes,
  getCoursePageHref,
  getCourseVendorById,
  VendorModel,
} from '../../features';
import { ProductMetaModel, ProductMetaTypes } from './headProductMeta.model';
import { NextRouter } from 'next/router';
import { FullPresetsModel, publicConfig } from '../../services';
import { getCourseImage } from '../headCoursesMeta';
import { Nullable } from '../../models';

const getCourseDefaultDescription = (
  course: CourseModel,
  vendor: Nullable<VendorModel>
): string =>
  `Онлайн-курс ${course.title}${
    vendor?.title ? ` от ${vendor.title}` : ''
  } на платформе ${publicConfig.APP_NAME}.${publicConfig.REGION}`;

const mapRatingCountBySphere = (course: CourseModel): string => {
  const sphere = course.options.find(
    (option) => option.logicType === CourseOptionLogicTypes.spheres
  );
  switch (sphere?.slug) {
    case 'code':
      return '512';
    case 'marketing':
      return '486';
    case 'digital-design':
      return '462';
    case 'analytics-datascience':
      return '439';
    case 'finance-accounting':
      return '417';
    case 'for-children':
      return '396';
    case 'gamedev':
      return '376';
    case 'business-management':
      return '358';
    case 'project-product':
      return '340';
    case 'media':
      return '323';
    case 'environment-design':
      return '307';
    case 'english':
      return '291';
    case 'self-development':
      return '277';
    case 'fashion-photography':
      return '263';
    case 'staff-management':
      return '250';
    case 'artificial-intelligence':
      return '237';
    case 'sport':
      return '225';
    case 'team-management':
      return '214';
    case 'soft-skills':
      return '203';
    case 'another':
      return '193';
    default:
      return '499';
  }
};

export const mapProductMetaTag = (
  course: CourseModel,
  vendors: VendorModel[],
  router: NextRouter,
  mediaPresets?: FullPresetsModel
): ProductMetaModel => {
  const courseVendor = getCourseVendorById(vendors, course.vendorUuid);
  const image = getCourseImage(
    mediaPresets,
    course.logo?.imageResource ||
      courseVendor?.logo?.imageResource ||
      courseVendor?.coursePlug?.imageResource
  );

  return {
    '@context': 'https://schema.org/',
    '@type': ProductMetaTypes.product,
    name: course.title,
    description:
      course.description || getCourseDefaultDescription(course, courseVendor),
    url: getCoursePageHref({ course, router, urlParams: {} }),
    image,
    aggregateRating: {
      '@type': ProductMetaTypes.rating,
      ratingValue: '4.87',
      ratingCount: mapRatingCountBySphere(course),
      bestRating: '5',
    },
    offers: {
      '@type': ProductMetaTypes.offer,
      lowPrice: (course.price.installment
        ? course.price.installment.priceWithTotalDiscount.amount
        : course.price.price.amount
      ).replaceAll(' ', ''),
      priceCurrency: course.price.price.currency,
    },
  };
};
