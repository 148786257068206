import React, { ReactElement } from 'react';
import { IconProps } from '@lerna-core';

export const IconForChildrenComponent = ({
  width = 40,
  height = 40,
  fillColor = '#713AE0',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fillColor}
      d="M22.495 1.7c-2.741 0-4.99 2.25-4.99 4.99H12.1a2.93 2.93 0 0 0-2.91 2.912v5.407C6.448 15.01 4.2 17.26 4.2 20c0 2.742 2.249 4.99 4.99 4.99v5.408a2.93 2.93 0 0 0 2.91 2.911h5.405c0 2.742 2.249 4.991 4.99 4.991 2.74 0 4.99-2.25 4.99-4.99h5.404a2.93 2.93 0 0 0 2.911-2.912v-6.655a1.248 1.248 0 0 0-1.247-1.247h-2.08A2.477 2.477 0 0 1 29.98 20a2.477 2.477 0 0 1 2.495-2.495h2.079a1.248 1.248 0 0 0 1.247-1.248V9.602a2.93 2.93 0 0 0-2.91-2.911h-5.406c0-2.742-2.249-4.991-4.99-4.991Zm0 2.495a2.477 2.477 0 0 1 2.494 2.496v1.248a1.248 1.248 0 0 0 1.248 1.247h6.652c.246 0 .416.17.416.416v5.407h-.831c-2.741 0-4.99 2.25-4.99 4.991 0 2.742 2.249 4.99 4.99 4.99h.831v5.408c0 .245-.17.416-.416.416h-6.652a1.248 1.248 0 0 0-1.248 1.247v1.248a2.477 2.477 0 0 1-2.494 2.496A2.477 2.477 0 0 1 20 33.309v-1.248a1.249 1.249 0 0 0-1.247-1.247H12.1a.397.397 0 0 1-.416-.416v-6.655a1.248 1.248 0 0 0-1.247-1.247H9.189A2.477 2.477 0 0 1 6.695 20a2.477 2.477 0 0 1 2.494-2.495h1.248a1.248 1.248 0 0 0 1.247-1.248V9.602c0-.245.17-.416.416-.416h6.653A1.248 1.248 0 0 0 20 7.94V6.69a2.477 2.477 0 0 1 2.495-2.496Z"
    />
  </svg>
);
