import { publicConfig, RequestClient } from '@lerna-core';
import { mapGoogleSheetLoggerRequestParams } from './googleSheetLogger.helper';

const PATH = '{{PATH}}';

export class GoogleSheetLoggerClient extends RequestClient<any, any, any> {
  requestParameters: any;
  path = `${PATH}/exec`;

  constructor(path: string, requestParams: any) {
    super(
      'https://script.google.com/macros/s/',
      '',
      {
        'Content-Type': `multipart/form-data`,
      },
      { withCredentials: false }
    );

    this.path = this.path.replace(PATH, path);
    this.requestParameters = mapGoogleSheetLoggerRequestParams(requestParams);
  }
}

export class ProxyLoggerClient extends RequestClient<any, any, any> {
  requestParameters: any;
  path = `analytics-api/v1/orders/${PATH}`;

  constructor(path: string, requestParams: any) {
    super(
      publicConfig.API_GATEWAY_URL,
      '',
      {
        'Content-Type': `multipart/form-data`,
      },
      { withCredentials: false }
    );

    this.path = this.path.replace(PATH, path);
    this.requestParameters = mapGoogleSheetLoggerRequestParams(requestParams);
  }
}
