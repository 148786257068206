import React, { ReactElement } from 'react';
import { Column } from 'react-table';
import {
  SphereAllocatedFilterContainer,
  SphereUnallocatedFilterContainer,
} from './transferSphere';
import {
  VendorsAllocatedFilterContainer,
  VendorsUnallocatedFilterContainer,
} from './transferVendors';
import { LocationType, TransferControlsContainer } from '../../transfer';
import {
  CoursesTableAccessors,
  CourseTableData,
  CourseTableRow,
} from './transferTable';
import { CurrencyModel, TranslationModel } from '../../../models';
import { CellCourseContainer, TableSearchButtonContainer } from '../../table';
import { TransferLeftContainer } from './transferLeft';
import { TransferRightContainer } from './transferRight';
import {
  TypeAllocatedFilterContainer,
  TypeUnallocatedFilterContainer,
} from './transferType';
import { getCourseVendorById } from '../../course';
import { CellExternalIdContainer } from './transferCell';
import { EMPTY } from '../../../constants';
import { CoursesSearchTerms } from './coursesTransfer.model';
import { VendorModel } from '../../vendors';
import { setPriceValue } from '../../../adapters';

const ID = 'ID';

export const searchInitialState = {
  isOpen: false,
  searchTerm: CoursesSearchTerms.title,
};

export const TabCoursesTransferComponents = {
  TransferLeftContainer,
  TransferRightContainer,
  TransferControlsContainer,
};

export const coursesColumnsWidth = {
  [CoursesTableAccessors.externalId]: 60,
  [CoursesTableAccessors.title]: 168,
  [CoursesTableAccessors.sphere]: 160,
  [CoursesTableAccessors.vendor]: 112,
  [CoursesTableAccessors.price]: 120,
  [CoursesTableAccessors.type]: 120,
};

export const getCoursesTableColumns = (
  translations: TranslationModel,
  accessors: CoursesTableAccessors[],
  side: LocationType,
  changeSearch: (term: CoursesSearchTerms) => void
): Column<Record<string, unknown>>[] => {
  const columns = [];

  if (accessors.includes(CoursesTableAccessors.externalId)) {
    columns.push({
      Header(): ReactElement {
        const handleClick = (): void => {
          changeSearch(CoursesSearchTerms.id);
        };
        switch (side) {
          case LocationType.unallocated:
            return (
              <TableSearchButtonContainer title={ID} onClick={handleClick} />
            );
          case LocationType.allocated:
            return (
              <TableSearchButtonContainer title={ID} onClick={handleClick} />
            );
        }
      },
      width: coursesColumnsWidth[CoursesTableAccessors.externalId],
      minWidth: coursesColumnsWidth[CoursesTableAccessors.externalId],
      accessor: CoursesTableAccessors.externalId,
    });
  }

  columns.push({
    Header(): ReactElement {
      const handleClick = (): void => {
        changeSearch(CoursesSearchTerms.title);
      };
      switch (side) {
        case LocationType.unallocated:
          return (
            <TableSearchButtonContainer
              title={translations.title}
              onClick={handleClick}
            />
          );
        case LocationType.allocated:
          return (
            <TableSearchButtonContainer
              title={translations.title}
              onClick={handleClick}
            />
          );
      }
    },
    width: coursesColumnsWidth[CoursesTableAccessors.title],
    accessor: CoursesTableAccessors.title,
  });

  if (accessors.includes(CoursesTableAccessors.sphere)) {
    columns.push({
      Header(): ReactElement {
        switch (side) {
          case LocationType.unallocated:
            return (
              <SphereUnallocatedFilterContainer translations={translations} />
            );
          case LocationType.allocated:
            return (
              <SphereAllocatedFilterContainer translations={translations} />
            );
        }
      },
      width: coursesColumnsWidth[CoursesTableAccessors.sphere],
      accessor: CoursesTableAccessors.sphere,
    });
  }
  if (accessors.includes(CoursesTableAccessors.vendor)) {
    columns.push({
      Header(): ReactElement {
        switch (side) {
          case LocationType.unallocated:
            return (
              <VendorsUnallocatedFilterContainer translations={translations} />
            );
          case LocationType.allocated:
            return (
              <VendorsAllocatedFilterContainer translations={translations} />
            );
        }
      },
      width: coursesColumnsWidth[CoursesTableAccessors.vendor],
      accessor: CoursesTableAccessors.vendor,
    });
  }

  if (accessors.includes(CoursesTableAccessors.price)) {
    columns.push({
      Header(): ReactElement {
        return <>{translations.price}</>;
      },

      width: coursesColumnsWidth[CoursesTableAccessors.price],
      accessor: CoursesTableAccessors.price,
    });
  }

  if (accessors.includes(CoursesTableAccessors.type)) {
    columns.push({
      Header(): ReactElement {
        return <>{translations.type}</>;
      },

      width: coursesColumnsWidth[CoursesTableAccessors.type],
      accessor: CoursesTableAccessors.type,
    });
  }

  if (accessors.includes(CoursesTableAccessors.typeFilter)) {
    columns.push({
      Header(): ReactElement {
        switch (side) {
          case LocationType.unallocated:
            return (
              <TypeUnallocatedFilterContainer translations={translations} />
            );
          case LocationType.allocated:
            return <TypeAllocatedFilterContainer translations={translations} />;
        }
      },

      width: coursesColumnsWidth[CoursesTableAccessors.type],
      accessor: CoursesTableAccessors.type,
    });
  }

  return columns;
};

export const getCoursesTableRows = (
  data: CourseTableData[],
  vendors: VendorModel[],
  currencies: CurrencyModel[],
  baseDomain?: string
): CourseTableRow[] => {
  return data.map((row) => {
    return {
      ...row,
      price: setPriceValue(row.price, currencies) || EMPTY,
      sphere: row.sphere || EMPTY,
      vendor: getCourseVendorById(vendors, row.vendorUuid)?.title || EMPTY,
      type: row.typeTitle,
      externalId: <CellExternalIdContainer course={row} />,
      title: (
        <CellCourseContainer
          course={{
            ...row,
            title: row.title as string,
          }}
          baseDomain={baseDomain}
        />
      ),
    };
  });
};
