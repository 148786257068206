import styled, { css } from 'styled-components';
import Link from 'next/link';
import { breakpoint, fontSettings } from '../../../../../styles';

export const AccordionTitle = styled.div`
  display: flex;
  align-items: center;
  margin: -8px 0;
`;
export const AccordionTitleImage = styled.div`
  height: 24px;
  width: 24px;
  margin-right: 12px;
`;
export const AccordionTitleText = styled.span`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
  font-weight: 500;
`;

export const AccordionContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 4px 0 -16px;

  ${breakpoint(
    'mediumDown',
    css`
      margin-top: 20px;
    `
  )}
`;

export const AccordionContentItem = styled(Link)`
  padding-left: 36px;
  color: ${({ theme }): string => theme.COLOR.TEXT['100']};
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)};
  font-weight: 500;
  text-decoration: none;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
`;
