import { FastLinksMenuConstants } from './fastLinksMenu.constants';
import {
  FastLinkMenuItemModel,
  FastLinkMenuModel,
  FastLinksMenuAction,
} from './fastLinksMenu.model';

export const setFastLinksMenu = (
  payload: FastLinkMenuModel[]
): FastLinksMenuAction => ({
  type: FastLinksMenuConstants.setFastlinks,
  payload,
});

export const setFastLinksMenuSchools = (
  payload: FastLinkMenuItemModel[]
): FastLinksMenuAction => ({
  type: FastLinksMenuConstants.setSchools,
  payload,
});

export const openFastLinksMenu = (payload: boolean): FastLinksMenuAction => ({
  type: FastLinksMenuConstants.open,
  payload,
});
