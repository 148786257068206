import { FastLinksMenuSchoolsClient } from './fastLinksMenuSchools.client';
import { Nullable } from '../../../../models';
import { UserAggregateModel } from '../../../../features';
import { RequestHeader } from '../../../../services';
import { FastLinkMenuItemModel } from '../fastLinksMenu.model';

class FastLinksMenuSchoolsProvider {
  protected request: Nullable<FastLinksMenuSchoolsClient>;
  protected coursesPath = 'course-api/v1/catalog/fastlinks/menu/schools';
  protected usersPath = 'user-api/v1/catalog/fastlinks/menu/schools';

  constructor() {
    this.request = null;
  }

  public async get(
    user: Nullable<UserAggregateModel>,
    requestHeaders?: RequestHeader
  ): Promise<FastLinkMenuItemModel[]> {
    const path = !!user?.company?.uuid ? this.usersPath : this.coursesPath;

    this.request = new FastLinksMenuSchoolsClient(path, requestHeaders);

    const response = this.request.get();

    return response as unknown as Promise<FastLinkMenuItemModel[]>;
  }
}

export const FastLinksMenuSchoolsClientProvider =
  new FastLinksMenuSchoolsProvider();
