import styled, { css } from 'styled-components';
import { SwitcherItemStyledProps } from './switcherItem.model';
import { getActiveLinkStyle, getAdaptiveLinkStyle } from './switcherItem.mixin';
import { fontSettings, transition } from '../../../../styles';
import { rgba } from 'polished';

export const SwitcherItemLabelStyled = styled.span`
  flex: 0 0 auto;
  font-weight: 500;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  white-space: nowrap;
`;

export const SwitcherItemValueStyled = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  padding: 3px 7px;
  box-sizing: border-box;
  font-weight: 700;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_13, theme.FONT)};
  background-color: ${(props): string => props.theme.COLOR.PURPLE};
  color: ${(props): string => props.theme.COLOR.WHITE};
  min-width: 22px;
  border-radius: 100px;
  flex: 0 0 auto;
`;

export const SwitcherItemStyled = styled.span<SwitcherItemStyledProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 20px;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  border-radius: inherit;
  height: 100%;
  box-sizing: border-box;
  ${transition('background-color')};

  &:not(:first-child) {
    &::before {
      content: '';
      position: absolute;
      left: 0px;
      top: 50%;
      transform: translateY(-50%);
      height: 22px;
      width: 1px;
      background-color: ${({ theme }): string => rgba(theme.COLOR.BLACK, 0.08)};
    }
  }

  &:hover {
    background-color: ${({ theme }): string => rgba(theme.COLOR.BLACK, 0.04)};
  }

  ${({ isActive }): ReturnType<typeof css> => getActiveLinkStyle(isActive)}
  ${({ isAdaptive }): ReturnType<typeof css> =>
    getAdaptiveLinkStyle(isAdaptive)}
`;
