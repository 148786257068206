import { AccordionSpoilerIconVersionModel } from '../accordion.model';
import React, { ReactElement } from 'react';
import {
  IconArrowComponent,
  IconMinusComponent,
  IconPlusComponent,
} from '../../icons';

export const getAccordionSpoilerIcon = (
  spoilerIconVersion: AccordionSpoilerIconVersionModel,
  isOpened: boolean
): ReactElement => {
  switch (spoilerIconVersion) {
    case 'v1':
      return <IconArrowComponent width={24} height={24} />;
    case 'v2':
      if (isOpened) {
        return (
          <IconMinusComponent width={24} height={24} strokeColor="#FFFFF" />
        );
      }

      return <IconPlusComponent width={24} height={24} strokeColor="#FFFFF" />;
    case 'v3':
      return (
        <svg
          width="16"
          height="16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m3 6 5 5 5-5"
            stroke="#78747A"
            strokeWidth="2"
            strokeLinecap="round"
          />
        </svg>
      );
  }
};
