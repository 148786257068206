import { CareerModel, checkUserRole, getUserRole } from '../user';

/**
 * Check for points on the user
 * @return {boolean}
 * @params - user
 * */
export const hasBonusPoints = (
  career?: CareerModel,
  isMobile = false
): boolean => {
  const userRole = getUserRole(career);

  return (
    !isMobile &&
    !career?.isBonusPointsHiddenOnListing &&
    checkUserRole(userRole, career)
  );
};
