import { CompanyUuidEnum } from './company.enum';
import { Nullable } from '../../models';
import { DiscountProgramModel } from '../../catalog';
import { CompanyCdekColors, CompanyMBMColors } from './company.meta';
import { rgba } from 'polished';
import { AdditionalColorsModel } from './company.model';

export const isMBMCompany = (companyId?: string) =>
  companyId === CompanyUuidEnum.mbm;

export const getAdditionalColors = (
  discountProgram: Nullable<DiscountProgramModel>,
  companyId?: string
): AdditionalColorsModel => {
  if (companyId === CompanyUuidEnum.mbm) {
    return {
      filtersAccentColor: CompanyMBMColors.accentColor,
      buttonAdditionalColors: {
        backgroundHoverColor: rgba(CompanyMBMColors.secondaryColor, 0.2),
        backgroundColor: rgba(CompanyMBMColors.secondaryColor, 0.1),
        color: CompanyMBMColors.secondaryColor,
      },
    };
  }
  if (companyId === CompanyUuidEnum.cdek) {
    return {
      filtersAccentColor: CompanyCdekColors.accentColor,
      buttonAdditionalColors: {
        backgroundHoverColor: rgba(0, 0, 0, 0.08),
        backgroundColor: rgba(0, 0, 0, 0.04),
        color: CompanyCdekColors.secondaryColor,
      },
    };
  }

  if (!!discountProgram?.colors.filtersAccentColor) {
    return {
      filtersAccentColor: discountProgram.colors.filtersAccentColor,
      buttonAdditionalColors: discountProgram?.colors
        ? {
            backgroundHoverColor: rgba(
              discountProgram.colors.secondaryColor,
              0.4
            ),
            backgroundColor: rgba(discountProgram.colors.secondaryColor, 0.1),
            color: discountProgram.colors.secondaryColor,
          }
        : undefined,
    };
  }

  return {};
};
