import { useContext, useState } from 'react';
import { subscriptionClaimProvider } from '@features/common/subscriptionClaim';
import { useAppDispatch } from '@store/store.hooks';
import { useCourseActiveContext } from '@features/courses/coursesActive';
import {
  browserService,
  CommonEventTypes,
  DeviceInfoContext,
  getCurrentPopupFromOpen,
  getVendors,
  logger,
  PopupsEnum,
  publicConfig,
  RegionsEnum,
  requestClosePopup,
  useUser,
} from '@lerna-core';
import { useSelector } from 'react-redux';
import {
  getFormSubmitStatusEvent,
  pushFormSubmitStatusEvent,
} from './granuleFreeForm.helper';
import { FormGranuleHookModel } from './granuleFreeForm.model';
import { UserOrderFormSubmitStatusTypes } from '../../userOrderForm.model';
import { FormGranuleContainerProps } from '../formGranule.model';
import {
  OrderFormEvents,
  orderFormPushDataLayer,
  OrderFormRequestTypes,
  useGoogleSheetLoggerContext,
} from '@features/common/forms';

export const useFormGranule = (
  props: FormGranuleContainerProps
): FormGranuleHookModel => {
  const { user } = useUser();
  const vendors = useSelector(getVendors);
  const deviceInfoContext = useContext(DeviceInfoContext);
  const { googleSheetLogger } = useGoogleSheetLoggerContext();
  const { removeNumericId } = useCourseActiveContext();
  const isMobile = browserService.mobileDetectFromContext(deviceInfoContext);
  const dispatch = useAppDispatch();
  const currentPopup = useSelector(
    getCurrentPopupFromOpen(PopupsEnum.userOrderFormPopup)
  );

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isSuccessDisplayed, setIsSuccessDisplayed] = useState<boolean>(false);
  const [isErrorDisplayed, setIsErrorDisplayed] = useState<boolean>(false);
  const [isExistOrder, setIsExistOrder] = useState<boolean>(false);

  const showFormStatus = isSuccessDisplayed || isErrorDisplayed || isExistOrder;
  const isRuRegion = publicConfig?.REGION === RegionsEnum.ru;

  const handleSubmit = (): void => {
    setIsSubmitting(true);

    const formEventValues = getFormSubmitStatusEvent(props.course);
    googleSheetLogger(formEventValues);
    subscriptionClaimProvider(props.course.uuid)
      .then(onSuccess)
      .catch((error): void => {
        if (error?.response?.status === 410) {
          onExistError();
        } else {
          onError();
        }
        logger.error(`[ERROR]: error sending request. ${error.response?.data}`);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const clearData = (): void => {
    setIsSubmitting(false);
    setIsErrorDisplayed(false);
    setIsSuccessDisplayed(false);
    setIsExistOrder(false);
  };
  const onSuccess = (): void => {
    props.handleFormSent();
    pushFormSubmitStatusEvent(
      UserOrderFormSubmitStatusTypes.success,
      props.course,
      vendors,
      props.eventPosition,
      undefined,
      user?.career?.companyId
        ? OrderFormRequestTypes.b2b
        : OrderFormRequestTypes.b2c
    );

    setIsSuccessDisplayed(true);
  };
  const onError = (): void => {
    pushFormSubmitStatusEvent(
      UserOrderFormSubmitStatusTypes.error,
      props.course,
      vendors,
      props.eventPosition
    );

    setIsErrorDisplayed(true);
  };
  const onExistError = (): void => {
    pushFormSubmitStatusEvent(
      UserOrderFormSubmitStatusTypes.exist,
      props.course,
      vendors,
      props.eventPosition
    );

    setIsExistOrder(true);
  };

  const handlePopupClose = async (): Promise<void> => {
    orderFormPushDataLayer(
      OrderFormEvents.formEnrollClose,
      CommonEventTypes.coursesEvents,
      props.eventPosition,
      props.course,
      vendors
    );

    await dispatch(requestClosePopup(PopupsEnum.userOrderFormPopup)).then(
      () => {
        if (typeof removeNumericId === 'function') {
          removeNumericId();
        }
      }
    );
    clearData();
  };

  return {
    ...props,
    currentPopup,
    handleSubmit,
    clearData,
    handlePopupClose,
    showFormStatus,
    isSuccessDisplayed,
    isErrorDisplayed,
    isExistOrder,
    isSubmitting,
    isRuRegion,
    isMobile,
  };
};
