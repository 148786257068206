import React, { ReactElement, useContext } from 'react';

import { ProfileButtonContainerStyled } from './headerProfile.styled';
import {
  UserAvatarSize,
  UserBarMobileContainer,
  useUser,
  useUserMenu,
} from '@lerna-core';
import { HeaderProfileButtonContainerProps } from './headerProfile.model';
import { PageNameContext } from '@contexts';

export const HeaderProfileContainer = ({
  withLogin,
}: HeaderProfileButtonContainerProps): ReactElement => {
  const pageName = useContext(PageNameContext);
  const { user } = useUser();
  const { menu } = useUserMenu();

  const projectPageName = pageName ? pageName : undefined;

  return (
    <ProfileButtonContainerStyled hasMargin={Boolean(user)}>
      <UserBarMobileContainer
        avatarSize={UserAvatarSize.xsmall}
        isRound={true}
        menu={menu}
        projectPageName={projectPageName}
        withLogin={withLogin}
      />
    </ProfileButtonContainerStyled>
  );
};
