import {
  CertificatesClient,
  CreateCertificateClient,
  UpdateCertificateClient,
} from './certificate.client';
import { CertificateModel } from './certificate.model';
import { publicConfig, RequestHeader } from '../../services';

export const updateCertificateClientProvider = async (
  certificateId: string,
  blob: Blob
): Promise<CertificateModel> => {
  const response = await new UpdateCertificateClient(certificateId, blob).put();

  return response as unknown as Promise<CertificateModel>;
};

export const downloadCertificateClientProvider = async (
  certificateId: string
): Promise<Blob> => {
  return fetch(
    `${publicConfig.API_GATEWAY_URL}certificates-api/v1/me/certificates/${certificateId}/download`
  ).then((response) => {
    if (response.status === 200) {
      return response.blob();
    } else {
      throw new Error('can not download certificate');
    }
  });
};

export const createCertificateClientProvider = async (
  studentCourseId: string
): Promise<CertificateModel> => {
  const response = await new CreateCertificateClient(studentCourseId).post();

  return response as unknown as Promise<CertificateModel>;
};

export const certificatesClientProvider = async (
  requestHeaders?: RequestHeader
): Promise<CertificateModel[]> => {
  const response = await new CertificatesClient(requestHeaders).get();

  return response as unknown as Promise<CertificateModel[]>;
};
