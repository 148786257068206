import { Nullable, useTranslations, useUser } from '@lerna-core';
import { ReactElement, useContext } from 'react';
import { PageNameContext } from '../../../../../contexts';
import { getDesktopLinks } from './desktopLinks.meta';
import { DesktopLinksContainerProps } from './desktopLinks.model';
import { useRouter } from 'next/router';

export const DesktopLinksContainer = ({
  headerLinkType,
}: DesktopLinksContainerProps): Nullable<ReactElement> => {
  const router = useRouter();
  const { user, userCoursesCount } = useUser();
  const translations = useTranslations();
  const pageName = useContext(PageNameContext);

  return getDesktopLinks(
    headerLinkType,
    user,
    userCoursesCount,
    translations,
    pageName,
    router
  );
};
