import { Nullable, SeoModel, UserAggregateModel } from '@lerna-core';

export const checkCollapseSeoText = (
  container: Nullable<HTMLDivElement>
): boolean => {
  const containerHeight = container?.getBoundingClientRect().height || 0;
  const innerHeight =
    container?.children[0]?.getBoundingClientRect().height || 0;

  return containerHeight < innerHeight;
};

export const getIsCoursesSeoHidden = (
  coursesSeo: SeoModel,
  user: Nullable<UserAggregateModel>,
  isDiscountProgramPage: boolean
) => !coursesSeo.text || user?.career?.companyId || isDiscountProgramPage;
