import { FastLinksMenuConstants } from './fastLinksMenu.constants';
import {
  FastLinkMenuItemModel,
  FastLinkMenuModel,
  FastLinksMenuAction,
  FastLinksMenuReducerState,
} from './fastLinksMenu.model';

const defaultState: FastLinksMenuReducerState = {
  fastlinks: {
    data: [],
    status: {
      isLoaded: false,
    },
  },
  schools: {
    data: [],
    status: {
      isLoaded: false,
    },
  },
  status: {
    isOpened: false,
  },
};

export const fastLinksMenuReducer = (
  state: FastLinksMenuReducerState = defaultState,
  action: FastLinksMenuAction
): FastLinksMenuReducerState => {
  switch (action.type) {
    case FastLinksMenuConstants.setFastlinks: {
      return {
        ...state,
        fastlinks: {
          data: action.payload as FastLinkMenuModel[],
          status: { ...state.fastlinks.status, isLoaded: true },
        },
      };
    }
    case FastLinksMenuConstants.setSchools: {
      return {
        ...state,
        schools: {
          data: action.payload as FastLinkMenuItemModel[],
          status: { ...state.fastlinks.status, isLoaded: true },
        },
      };
    }
    case FastLinksMenuConstants.open: {
      return {
        ...state,
        status: { ...state.status, isOpened: action.payload as boolean },
      };
    }
    default: {
      return state;
    }
  }
};
