import { CourseOptionModel } from './courseOptions.model';
import {
  CourseOptionLogicTypes,
  CourseOptionsSlug,
} from './courseOptions.enum';

export const getIsCourseBestseller = (options: CourseOptionModel[]): boolean =>
  options.some(
    (option) =>
      option.logicType === CourseOptionLogicTypes.collections &&
      option.slug === CourseOptionsSlug.bestseller
  );

export const getIsCourseWithEmployment = (
  options: CourseOptionModel[]
): boolean =>
  options.some(
    (option) =>
      option.logicType === CourseOptionLogicTypes.collections &&
      option.slug === CourseOptionsSlug.withEmployment
  );

export const getIsCourseHit = (options: CourseOptionModel[]): boolean =>
  options.some(
    (option) =>
      option.logicType === CourseOptionLogicTypes.collections &&
      option.slug === CourseOptionsSlug.hit
  );
