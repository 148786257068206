import React, { ReactElement, useMemo } from 'react';
import {
  FooterBottomDefaultStyled,
  FooterBottomInfoStyled,
  FooterBottomInfoTextStyled,
  FooterBottomLogoStyled,
  FooterBottomWrapperStyled,
  FooterCompanyStyled,
} from './footerBottomDefault.styled';
import { FooterBottomContainerProps } from './footerBottomDefault.model';
import { useTheme } from '../../../../hooks';
import { IconLernaLogoComponent } from '../../../icons';
import { FooterImagesContainer } from '../../footerImages';
import { useTranslations } from '../../../translations';

export const FooterBottomDefaultContainer = ({
  footerImages,
}: FooterBottomContainerProps): ReactElement => {
  const theme = useTheme();
  const translations = useTranslations();

  const currentYear = useMemo(() => new Date().getFullYear(), []);
  const companyText =
    translations?.company && `© ${translations.company}, ${currentYear}`;

  return (
    <FooterBottomDefaultStyled>
      <FooterBottomWrapperStyled>
        <FooterBottomInfoStyled>
          <FooterBottomLogoStyled>
            <IconLernaLogoComponent
              fillColor={theme.COLOR.WHITE}
              width={83}
              height={39}
            />
          </FooterBottomLogoStyled>
          <FooterBottomInfoTextStyled>
            {translations?.exclusive_partner}
          </FooterBottomInfoTextStyled>
        </FooterBottomInfoStyled>
        <FooterCompanyStyled isMobile>{companyText}</FooterCompanyStyled>
      </FooterBottomWrapperStyled>
      <FooterImagesContainer images={footerImages} />
      <FooterCompanyStyled>{companyText}</FooterCompanyStyled>
    </FooterBottomDefaultStyled>
  );
};
