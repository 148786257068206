import { CustomerApiModel, CustomerModel } from '../customer';
import {
  ApiPriceModel,
  MetaPaginationApiModel,
  MetaPaginationModel,
  Nullable,
  PriceValueModel,
} from '../../models';
import { AxiosError } from 'axios';
import { ApiVendorModel, VendorModel } from '../vendors';
import { ApiCourseModel, CourseModel } from '../course';

/**
 * API MODELS ARE PLACED BELLOW
 */

export enum CompensationRequestsApiStatuses {
  pending = 'pending_approval',
  accepted = 'accepted',
  rejected = 'rejected',
  accepted_with_library_claim = 'accepted_with_library_claim',
}

export enum AdjustmentApiType {
  compensationPercent = 'compensation_percent',
  compensationAbsolute = 'compensation_absolute',
  customCompensationPercent = 'custom_compensation_percent',
  customCompensationAbsolute = 'custom_compensation_absolute',
}

export enum AdjustmentType {
  absolute = 'absolute',
  percent = 'percent',
}

export type AdjustmentApiModel = {
  uuid: string;
  type: AdjustmentApiType;
  value: ApiPriceModel;
  price_influence_percent: number;
};

export type AdjustmentModel = {
  uuid: string;
  type: AdjustmentType;
  value: PriceValueModel;
  priceInfluencePercent: number;
};

export type CompensationRequestApiModel = {
  uuid: string;
  status: CompensationRequestsApiStatuses;
  processed_at: string | null;
  created_at: string;
  adjustment: Nullable<AdjustmentApiModel>;
  is_available_in_library: boolean;
  is_custom_compensation: boolean;
  initial_price: PriceValueModel;
  customer_price: PriceValueModel;
  company_price: PriceValueModel;
  b2b_price: Nullable<PriceValueModel>;
  customer: CustomerApiModel;
  course: ApiCourseModel;
  vendor: ApiVendorModel;
  comment: Nullable<string>;
};

export type CompensationRequestsApiModel = {
  data: CompensationRequestApiModel[];
  meta: {
    pagination: MetaPaginationApiModel;
  };
};

export type CompensationRequestsResponse = {
  data: CompensationRequestsApiModel;
};

/**
 * CLIENT MODELS ARE PLACED BELLOW
 */

export enum CompensationRequestsStatuses {
  pending = 'pending',
  accepted = 'accepted',
  rejected = 'rejected',
  acceptedWithLibraryClaim = 'acceptedWithLibraryClaim',
}

export type CompensationRequestModel = {
  uuid: string;
  status: CompensationRequestsStatuses;
  processedAt: Nullable<string>;
  createdAt: string;
  startDate: string;
  adjustment: Nullable<AdjustmentModel>;
  isAvailableInLibrary: boolean;
  isCustomCompensation: boolean;
  initialPrice: PriceValueModel;
  customerPrice: PriceValueModel;
  b2bPrice: Nullable<PriceValueModel>;
  companyPrice: PriceValueModel;
  customer: CustomerModel;
  course: CourseModel;
  vendor: Nullable<VendorModel>;
  comment: Nullable<string>;
};

export type CompensationRequestsModel = {
  data: CompensationRequestModel[];
  meta: MetaPaginationModel;
};

export type CompensationsRequestsParams = {
  page?: number;
  status: CompensationRequestsApiStatuses | null;
};

export type CompensationsRequestsApiParams = CompensationsRequestsParams;

export type CompensationRequestsActions = {
  type: string;
  payload?:
    | CompensationRequestsModel
    | CompensationsRequestsParams
    | AxiosError;
};
