import { useCallback, useMemo } from 'react';
import {
  getFormCareerInitialValues,
  getFormCareerValidationSchema,
} from './formCareer.helper';
import { useTranslations } from '../../../translations';
import { useFormik } from 'formik';
import { SelectedValue } from '../../../form';
import { useRegisterPopupContext } from '../../registerPopup';
import { updateCareerProvider } from '../../../updateCareer';
import {
  handleRegisterErrors,
  mapCareerDataRequestParams,
} from '../registerForm.helper';
import { CreateUserError } from '../../../createUser';
import { useUser } from '../../../user';
import { getSimpleEvent, pushDataLayer } from '../../../../services';
import { RegisterEvents } from '../../register.analytics';
import { CommonEventTypes } from '../../../../analytics';

export const useFormCareer = () => {
  const { user } = useUser();
  const {
    actionCallbacks,
    handleCloseRegister,
    handleWarningVisibility,
    departments,
    positions,
    registerInitiator,
  } = useRegisterPopupContext();
  const translations = useTranslations();

  const initialValues = useMemo(
    () =>
      getFormCareerInitialValues(
        departments,
        positions,
        user?.career?.companyTitle
      ),
    []
  );

  const validationSchema = useMemo(
    () => getFormCareerValidationSchema(translations),
    []
  );

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    isSubmitting,
    isValid,
    errors,
    touched,
    status,
    setFieldValue,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: async (values, formikHelpers) => {
      await updateCareerProvider(mapCareerDataRequestParams(values))
        .then(() => {
          pushDataLayer(
            getSimpleEvent(
              RegisterEvents.registrationSuccess,
              CommonEventTypes.registration,
              registerInitiator || undefined
            )
          );
          handleCloseRegister();
          handleWarningVisibility(false);
          typeof actionCallbacks.onSuccess === 'function' &&
            actionCallbacks.onSuccess();
        })
        .catch((errors) => {
          pushDataLayer(
            getSimpleEvent(
              RegisterEvents.registrationError,
              CommonEventTypes.registration,
              registerInitiator || undefined
            )
          );
          handleRegisterErrors(errors as CreateUserError, formikHelpers);
        });
    },
  });

  const departmentValue = useMemo(
    () => departments.find(({ key }) => key === values.departmentUuid),
    [departments, values]
  );

  const positionValue = useMemo(
    () => positions.find(({ key }) => key === values.positionUuid),
    [positions, values]
  );

  const handleSelect = useCallback(({ name = '', value }: SelectedValue) => {
    setFieldValue(name, value.key);
  }, []);

  return {
    handleSubmit,
    handleChange,
    handleSelect,
    handleBlur,
    values,
    isSubmitting,
    isValid,
    errors,
    touched,
    status,
    setFieldValue,
    departmentValue,
    departments,
    positionValue,
    positions,
  };
};
