import React, { ReactElement } from 'react';
import { CourseItemComponent } from './courseItem.component';
import { CourseItemMobileComponent } from './courseItemMobile';
import { CourseItemContainerProps } from './courseItem.model';

export const CourseItemContainer = ({
  course,
  isMobile,
  button,
  certificateButton,
  className,
}: CourseItemContainerProps): ReactElement => {
  const CourseItem = isMobile ? CourseItemMobileComponent : CourseItemComponent;

  return (
    <CourseItem
      className={className}
      course={course}
      certificateButton={certificateButton}
      button={button}
    />
  );
};
