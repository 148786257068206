import { FiltersSlugEnum } from '../filters';
import { Nullable } from '../../models';
import { ReactElement } from 'react';

export type FastLinkApiModel = {
  title: string;
  slug: string;
  retail_rocket_id: number;
  is_visible?: boolean;
  options: Nullable<Record<FiltersSlugEnum, string[]>>;
  mindbox_id: number;
};

export type FastLinkModel = {
  title: string;
  slug: string;
  options: Nullable<Record<FiltersSlugEnum, string[]>>;
  isVisible: boolean;
  mindboxId?: number;
  retailRocketId?: number;
};

export type FastLinkWithPathModel = FastLinkModel & {
  path: string;
};

export type FastLinkWithActiveModel = FastLinkModel & {
  isActive: boolean;
};

export enum FastLinksIconsEnum {
  english = 'english',
  another = 'another',
  gamedev = 'gamedev',
  marketing = 'marketing',
  media = 'media',
  code = 'code',
  sport = 'sport',
  'artificial-intelligence' = 'artificial-intelligence',
  'soft-skills' = 'soft-skills',
  'analytics-datascience' = 'analytics-datascience',
  'environment-design' = 'environment-design',
  'for-children' = 'for-children',
  'fashion-photography' = 'fashion-photography',
  'project-product' = 'project-product',
  'self-development' = 'self-development',
  'team-management' = 'team-management',
  'staff-management' = 'staff-management',
  'finance-accounting' = 'finance-accounting',
  'digital-design' = 'digital-design',
  'business-management' = 'business-management',
}

export type FastLinksIconsModel = Record<
  keyof typeof FastLinksIconsEnum,
  ReactElement
>;
