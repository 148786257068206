import React, { ReactElement } from 'react';
import { IconProps } from '@lerna-core';

export const IconEnglishComponent = ({
  width = 40,
  height = 40,
  fillColor = '#713AE0',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fillColor}
      d="M20 3.3C10.792 3.3 3.3 10.792 3.3 20c0 9.208 7.492 16.7 16.7 16.7 9.208 0 16.7-7.492 16.7-16.7 0-9.208-7.492-16.7-16.7-16.7Zm0 2.505c.612 0 1.233.255 1.915.882.681.628 1.373 1.625 1.965 2.913.476 1.036.827 2.364 1.148 3.72H14.972c.321-1.356.672-2.684 1.148-3.72.592-1.288 1.284-2.285 1.965-2.913.682-.627 1.303-.882 1.915-.882Zm-5.196.992a14.67 14.67 0 0 0-.96 1.758c-.636 1.383-1.113 3.02-1.483 4.765h-4.89a14.188 14.188 0 0 1 7.333-6.523Zm10.392 0a14.188 14.188 0 0 1 7.332 6.523H27.64c-.37-1.745-.847-3.382-1.482-4.765a14.67 14.67 0 0 0-.961-1.758ZM6.43 15.825h5.605c-.172 1.363-.385 2.699-.385 4.175s.213 2.812.385 4.175H6.43a14.242 14.242 0 0 1 0-8.35Zm8.115 0h10.91c.187 1.347.39 2.69.39 4.175 0 1.486-.203 2.828-.39 4.175h-10.91c-.186-1.347-.39-2.69-.39-4.175 0-1.486.204-2.828.39-4.175Zm13.42 0h5.605a14.242 14.242 0 0 1 0 8.35h-5.605c.172-1.363.385-2.699.385-4.175s-.213-2.812-.385-4.175ZM7.472 26.68h4.89c.37 1.745.846 3.382 1.482 4.765.291.635.615 1.216.96 1.758a14.188 14.188 0 0 1-7.332-6.523Zm7.5 0h10.056c-.321 1.356-.672 2.684-1.148 3.72-.592 1.288-1.284 2.285-1.965 2.913-.682.627-1.303.882-1.915.882-.612 0-1.233-.255-1.915-.882-.681-.628-1.373-1.625-1.965-2.913-.476-1.036-.827-2.364-1.148-3.72Zm12.667 0h4.89a14.188 14.188 0 0 1-7.333 6.523c.345-.542.669-1.123.96-1.758.636-1.383 1.113-3.02 1.483-4.765Z"
    />
  </svg>
);
