import { CSSVariables } from '../../../../models';
import { BODY_CLASS_NAME } from '../../../../features';

export const cleanDOM = (): void => {
  document.documentElement.style.setProperty(
    CSSVariables.scrollbarPlugWidth,
    '0px'
  );
  document.body.classList.remove(BODY_CLASS_NAME);
};
