import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import {
  checkedFilter,
  filterRouterService,
  FiltersModel,
  FilterTypes,
  getCoursesPath,
  getFilters,
  pushDataLayer,
  urlService,
  useFastLinksContext,
} from '@lerna-core';
import { FilterToggleContainerProps } from './filterToggle.model';
import {
  FilterToggleContainerStyled,
  FilterToggleDescription,
  FilterToggleWrapperStyled,
  ToggleStyled,
} from './filterToggle.styled';
import { FilterEvent, getMainFilterEventData } from '../filters.analytics';
import { ToggleContainer } from '@features/common/toggle';
import { isCustomCheckboxFilter, selectFilterOption } from '../filters.helper';
import { FilterIconContainer } from '../filterIcon';
import {
  TooltipDesktopContainer,
  TooltipDesktopPositions,
} from '../filterTooltip';

export const FilterToggleContainer = ({
  filter,
  withTooltip = true,
  analyticsType = FilterTypes.main,
}: FilterToggleContainerProps): ReactElement => {
  const router = useRouter();
  const fastLinks = useFastLinksContext();
  const filters = useSelector(getFilters);
  const coursesPath = getCoursesPath(router.query);

  const [isActive, setIsActive] = useState(() => checkedFilter(filter));

  const filterColor = filter.activeButtonColor;
  const filterBackgroundColor = filter.backgroundColor;
  const filterIconColor = filter.iconColor;
  const isCustomFilter = useMemo(() => isCustomCheckboxFilter(filter.slug), []);
  const filterIcon = (
    <FilterIconContainer
      filterSlug={filter.slug}
      background={filterIconColor}
    />
  );

  const clearFilter = (filter: FiltersModel): void => {
    let newQuery = filterRouterService.clearFilter(filter, router.query);
    newQuery = {
      ...newQuery,
      ...filterRouterService.getNewRouteFromFilters(
        newQuery,
        filters.filter((currentFilter) => currentFilter.slug !== filter.slug)
      ),
    };

    router.push(
      urlService.createUrlFromPathAndQuery(coursesPath, router, newQuery),
      undefined,
      { scroll: false }
    );

    if (analyticsType === FilterTypes.main) {
      pushDataLayer(
        getMainFilterEventData(
          FilterEvent.constructorFiltersMain,
          filter,
          undefined,
          true
        )
      );
    }
  };

  const handleClick = (): void => {
    if (isActive) {
      clearFilter(filter);
    } else {
      selectFilterOption(
        router,
        isActive,
        filter,
        filter.options[0],
        filters,
        fastLinks,
        coursesPath
      );

      if (analyticsType === FilterTypes.main) {
        pushDataLayer(
          getMainFilterEventData(
            FilterEvent.constructorFiltersMain,
            filter,
            undefined,
            false
          )
        );
      }
    }
  };

  useEffect(() => {
    setIsActive(checkedFilter(filter));
  }, [filter]);

  return (
    <FilterToggleContainerStyled
      background={filterBackgroundColor}
      isCustomFilter={isCustomFilter}
    >
      <FilterToggleWrapperStyled>
        {filterIcon && filterIcon}
        {filter.title}
        {filter.description && withTooltip && (
          <TooltipDesktopContainer
            content={filter.description}
            defaultPosition={TooltipDesktopPositions.bottom}
          />
        )}
        <ToggleStyled>
          <ToggleContainer
            isActive={isActive}
            onClick={handleClick}
            activeColor={filterColor}
          />
        </ToggleStyled>
      </FilterToggleWrapperStyled>
      {filter.description && !withTooltip && (
        <FilterToggleDescription>{filter.description}</FilterToggleDescription>
      )}
    </FilterToggleContainerStyled>
  );
};
