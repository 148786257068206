import { ParsedUrlQuery } from 'querystring';
import { stringService } from '../../services';
import { FilterRangePrefixEnum, FilterTypeEnum } from './filters.enum';
import {
  FilterModel,
  FilterRangeModel,
  FiltersModel,
  SlugsPair,
} from './filters.model';

export const FILTER_QUERY_VALUE_SEPARATOR = ',';

export class FiltersRouterService {
  private static instance: FiltersRouterService;

  public static getInstance(): FiltersRouterService {
    if (!FiltersRouterService.instance) {
      FiltersRouterService.instance = new FiltersRouterService();
    }

    return FiltersRouterService.instance;
  }

  public getNewRouteTab(
    query: ParsedUrlQuery,
    filterSlug: string,
    valueSlug: string
  ): ParsedUrlQuery {
    delete query[filterSlug];
    this.removeUnusedParams(query);

    return FiltersRouterService.addFilterToPath(query, filterSlug, valueSlug);
  }

  public getNewRouteCheckbox(
    query: ParsedUrlQuery,
    isActive: boolean,
    filterSlug: string,
    valueSlug: string
  ): ParsedUrlQuery {
    let newRoute;
    if (isActive) {
      newRoute = FiltersRouterService.removeFilterFromPath(
        query,
        filterSlug,
        valueSlug
      );
    } else {
      newRoute = FiltersRouterService.addFilterToPath(
        query,
        filterSlug,
        valueSlug
      );
    }
    this.removeUnusedParams(newRoute);

    return newRoute;
  }

  public getNewRouteFromFilters(
    query: ParsedUrlQuery,
    filters: FiltersModel[]
  ): ParsedUrlQuery {
    let newRoute = {};
    filters.forEach((filter) => {
      if (filter.type === FilterTypeEnum.range) {
        const rangeFilter = filter as FilterRangeModel;
        newRoute = this.changeRangeToPath(
          query,
          rangeFilter.slug,
          rangeFilter.options.from,
          rangeFilter.options.to
        );
      } else {
        const currentFilter = filter as FilterModel;
        currentFilter.options.forEach((option) => {
          if (option.checked) {
            newRoute = FiltersRouterService.addFilterToPath(
              query,
              filter.slug,
              option.slug
            );
          }
        });
      }
    });
    this.removeUnusedParams(newRoute);

    return newRoute;
  }

  public getNewRouteRange(
    query: ParsedUrlQuery,
    filterSlug: string,
    fromValue: string,
    toValue: string
  ): ParsedUrlQuery {
    return this.changeRangeToPath(query, filterSlug, fromValue, toValue);
  }

  public updateSelectedOptions(
    filters: FilterModel[],
    query: ParsedUrlQuery
  ): void {
    const slugPairs = FiltersRouterService.getSlugPairsFromQuery(query);
    FiltersRouterService.updateFiltersBySlugs(filters, slugPairs);
  }

  public clearFilters(
    filters: FiltersModel[],
    query: ParsedUrlQuery
  ): ParsedUrlQuery {
    filters.forEach((filter) => this.clearFilter(filter, query));

    return query;
  }

  public clearFilter(
    filter: FiltersModel,
    query: ParsedUrlQuery
  ): ParsedUrlQuery {
    if (filter.type === FilterTypeEnum.range) {
      delete query[
        `${filter.slug}${stringService.capitalizeFirstLetter(
          FilterRangePrefixEnum.from
        )}`
      ];
      delete query[
        `${filter.slug}${stringService.capitalizeFirstLetter(
          FilterRangePrefixEnum.to
        )}`
      ];
    }
    if (
      filter.type === FilterTypeEnum.checkbox ||
      filter.type === FilterTypeEnum.tab ||
      filter.type === FilterTypeEnum.toggle
    ) {
      delete query[filter.slug];
    }

    this.removeUnusedParams(query);

    return query;
  }

  public clearRangeFilter(
    filter: FiltersModel,
    query: ParsedUrlQuery
  ): ParsedUrlQuery {
    delete query[
      `${filter.slug}${stringService.capitalizeFirstLetter(
        FilterRangePrefixEnum.from
      )}`
    ];
    delete query[
      `${filter.slug}${stringService.capitalizeFirstLetter(
        FilterRangePrefixEnum.to
      )}`
    ];

    return query;
  }

  public clearToggleFilter(
    filter: FiltersModel,
    query: ParsedUrlQuery
  ): ParsedUrlQuery {
    delete query[filter.slug];
    this.removeUnusedParams(query);

    return query;
  }

  public clearFilterOption(
    filterSlug: string,
    optionSlug: string,
    query: ParsedUrlQuery
  ): ParsedUrlQuery {
    const newRoute = FiltersRouterService.removeFilterFromPath(
      query,
      filterSlug,
      optionSlug
    );
    this.removeUnusedParams(newRoute);

    return newRoute;
  }

  public selectFilter(
    filter: FilterModel,
    query: ParsedUrlQuery
  ): ParsedUrlQuery {
    delete query[filter.slug];
    this.removeUnusedParams(query);

    return filter.options.reduce((acc, option) => {
      acc = FiltersRouterService.addFilterToPath(
        query,
        filter.slug,
        option.slug
      );

      return acc;
    }, {});
  }

  private static removeFilterFromPath(
    query: ParsedUrlQuery,
    filterSlug: string,
    valueSlug: string
  ): ParsedUrlQuery {
    query[filterSlug] = FiltersRouterService.convertQueryValue(
      query[filterSlug]
    )
      .split(FILTER_QUERY_VALUE_SEPARATOR)
      .filter((value) => value !== valueSlug)
      .join(FILTER_QUERY_VALUE_SEPARATOR);

    if (query[filterSlug] === '') {
      delete query[filterSlug];
    }

    return query;
  }

  private static addFilterToPath(
    query: ParsedUrlQuery,
    filterSlug: string,
    valueSlug: string
  ): ParsedUrlQuery {
    const routerValues = FiltersRouterService.convertQueryValue(
      query[filterSlug]
    ).split(FILTER_QUERY_VALUE_SEPARATOR);
    if (!routerValues.includes(valueSlug)) {
      routerValues.push(valueSlug);
    }
    query[filterSlug] = (routerValues.filter((value) => !!value) || []).join(
      FILTER_QUERY_VALUE_SEPARATOR
    );

    return query;
  }

  private static changeRangeTypeFromPath(
    route: ParsedUrlQuery,
    filterSlug: string,
    fromValue: string
  ): ParsedUrlQuery {
    if (fromValue !== '') {
      route[
        `${filterSlug}${stringService.capitalizeFirstLetter(
          FilterRangePrefixEnum.from
        )}`
      ] = `${fromValue}`;
    } else {
      delete route[
        `${filterSlug}${stringService.capitalizeFirstLetter(
          FilterRangePrefixEnum.from
        )}`
      ];
    }

    return route;
  }

  private static changeRangeTypeToPath(
    route: ParsedUrlQuery,
    filterSlug: string,
    toValue: string
  ): ParsedUrlQuery {
    if (toValue !== '') {
      route[
        `${filterSlug}${stringService.capitalizeFirstLetter(
          FilterRangePrefixEnum.to
        )}`
      ] = `${toValue}`;
    } else {
      delete route[
        `${filterSlug}${stringService.capitalizeFirstLetter(
          FilterRangePrefixEnum.to
        )}`
      ];
    }

    return route;
  }

  private changeRangeToPath(
    query: ParsedUrlQuery,
    filterSlug: string,
    fromValue: string,
    toValue: string
  ): ParsedUrlQuery {
    FiltersRouterService.changeRangeTypeFromPath(query, filterSlug, fromValue);
    FiltersRouterService.changeRangeTypeToPath(query, filterSlug, toValue);
    this.removeUnusedParams(query);

    return query;
  }

  private static updateFiltersBySlugs(
    filters: FilterModel[],
    slugPairs: SlugsPair[]
  ): void {
    slugPairs.forEach((slugPair) => {
      FiltersRouterService.updateFilterBySlug(filters, slugPair);
    });
  }

  private static updateFilterBySlug(
    filters: FilterModel[],
    slugPair: SlugsPair
  ): FilterModel | undefined {
    const filter = filters.find(
      (filter) => filter.slug === slugPair.filterSlug
    );
    if (filter) {
      slugPair.valueSlugs
        .split(FILTER_QUERY_VALUE_SEPARATOR)
        .forEach((optionSlug) =>
          FiltersRouterService.addFilterOptionFromSlug(filter, optionSlug)
        );
    }

    return filter;
  }

  private static addFilterOptionFromSlug(
    filter: FilterModel,
    option: string
  ): FilterModel {
    const optionIndex = filter.options.findIndex(
      (filterOption) => filterOption.slug === option
    );
    if (optionIndex > -1) {
      filter.options[optionIndex] = {
        ...filter.options[optionIndex],
        checked: !filter.options[optionIndex].checked,
      };
    }

    return filter;
  }

  private static getSlugPairsFromQuery(query: ParsedUrlQuery): SlugsPair[] {
    const pairs: SlugsPair[] = [];
    for (const key in query) {
      if (query.hasOwnProperty(key)) {
        pairs.push({
          filterSlug: key,
          valueSlugs: FiltersRouterService.convertQueryValue(query[key]),
        });
      }
    }

    return pairs;
  }

  private static removePageParam(route: ParsedUrlQuery): ParsedUrlQuery {
    if (route.page) {
      delete route.page;
    }

    return route;
  }

  private static removeLangParam(route: ParsedUrlQuery): ParsedUrlQuery {
    if (route.lang) {
      delete route.lang;
    }

    return route;
  }

  private static removeFastLinkParam(route: ParsedUrlQuery): ParsedUrlQuery {
    if (route.fastlink) {
      delete route.fastlink;
    }

    return route;
  }

  private static removeInitiatorParam(route: ParsedUrlQuery): ParsedUrlQuery {
    if (route.initiator) {
      delete route.initiator;
    }

    return route;
  }

  private static removePromoParam(route: ParsedUrlQuery): ParsedUrlQuery {
    if (route.discount) {
      delete route.discount;
    }

    return route;
  }

  public removeUnusedParams(route: ParsedUrlQuery): ParsedUrlQuery {
    FiltersRouterService.removePageParam(route);
    FiltersRouterService.removeLangParam(route);
    FiltersRouterService.removeFastLinkParam(route);
    FiltersRouterService.removeInitiatorParam(route);
    FiltersRouterService.removePromoParam(route);

    return route;
  }

  private static convertQueryValue(
    queryValue: string | string[] | undefined
  ): string {
    if (typeof queryValue === 'string') {
      return queryValue;
    } else if (Array.isArray(queryValue)) {
      return queryValue.join(FILTER_QUERY_VALUE_SEPARATOR);
    } else {
      return '';
    }
  }
}

export const filterRouterService = FiltersRouterService.getInstance();
