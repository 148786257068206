import { css } from 'styled-components';
import { ITheme } from '../../../../features';

export const getTabStyles = (
  isActive: boolean,
  theme: ITheme
): ReturnType<typeof css> => {
  if (isActive) {
    return css`
      background-color: ${theme.COLOR.WHITE};
      box-shadow: 0 5px 25px -5px rgba(12, 11, 13, 0.16);
    `;
  }

  return css``;
};
