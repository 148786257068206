import React, { ReactElement } from 'react';
import * as S from './search.styled';
import {
  GenericInputClearStyled,
  IconCloseComponent,
  IconMagnifierComponent,
  useTranslations,
} from '../../../../../features';
import {
  getSearchTermValueSelector,
  handleSetSearchTerm,
  handleToggleSearchForm,
} from '../../../../search';
import { useDispatch, useSelector } from 'react-redux';
import { openFastLinksMenu } from '../../fastLinksMenu.actions';
import { useTheme } from '../../../../../hooks';

export const SearchContainer = (): ReactElement => {
  const translations = useTranslations();
  const dispatch = useDispatch();
  const theme = useTheme();
  const suggest = useSelector(getSearchTermValueSelector);
  const suggestColor = !!suggest
    ? theme.COLOR.TEXT_DARK_COLOR
    : theme.COLOR.TEXT_LIGHT_COLOR;

  const handleOpen = (): void => {
    dispatch(handleToggleSearchForm(true, null));
    dispatch(openFastLinksMenu(false));
  };

  const handleClear = (): void => {
    handleOpen();
    dispatch(handleSetSearchTerm(''));
  };

  return (
    <S.Search>
      <S.SearchWrapper onClick={handleOpen}>
        <IconMagnifierComponent
          width={24}
          height={24}
          fillColor={suggestColor}
          strokeColor={suggestColor}
        />
        <S.SearchValue color={suggestColor}>
          {suggest || translations.search_placeholder_mobile}
        </S.SearchValue>
        <GenericInputClearStyled
          visibleClearControl={!!suggest}
          onClick={handleClear}
        >
          <IconCloseComponent
            width={8}
            height={8}
            strokeWidth={'4'}
            strokeColor={theme.COLOR.WHITE}
          />
        </GenericInputClearStyled>
      </S.SearchWrapper>
    </S.Search>
  );
};
