import React, { ReactElement } from 'react';
import { useTheme } from '../../../hooks';
import { useUser } from '../../user';
import { Nullable } from '../../../models';
import { useTranslations } from '../../translations';
import {
  ButtonChildrenPosition,
  ButtonColor,
  ButtonContainer,
} from '../../button';
import { IconDownloadComponent } from '../../icons';
import { CertificateButtonModel } from './certificateButton.model';
import { isCertificateCanBeGranted } from '../certificate.helper';
import { useCertificateGeneration } from '../certificateGeneration';

export const CertificateButton = ({
  course,
  handleClick,
}: CertificateButtonModel): Nullable<ReactElement> => {
  const { user } = useUser();
  const { certificate } = useCertificateGeneration();
  const translations = useTranslations();
  const theme = useTheme();

  if (
    !certificate?.isFileGenerated &&
    (course.progress?.progressPercent !== 100 ||
      !isCertificateCanBeGranted(
        user,
        certificate,
        course.type,
        course.vendor,
        course.access.accessType,
        course.access.isHomeworkAvailable
      ))
  ) {
    return null;
  }

  return (
    <ButtonContainer
      title={translations.certificate}
      color={ButtonColor.brightPurple}
      onClick={handleClick}
      childrenPosition={ButtonChildrenPosition.left}
      children={
        <IconDownloadComponent
          fillColor={theme.COLOR.PRIMARY['500']}
          height={24}
          width={24}
        />
      }
    />
  );
};
