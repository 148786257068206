import { Nullable } from '../../../../models';
import { FastLinkMenuItemModel } from '../fastLinksMenu.model';
import { ReactElement } from 'react';

export enum FastLinksMenuTabs {
  sphere = 'sphere',
  schools = 'schools',
}

export type FastLinksMenuMobileModel = {
  filtersAccentColor?: string;
  hasSearch: boolean;
};

export type AccordionTitleModel = {
  title: string;
  icon: Nullable<ReactElement>;
};

export type AccordionContentModel = {
  items: FastLinkMenuItemModel[];
};
