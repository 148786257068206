import React, { cloneElement, ReactElement, useContext } from 'react';
import { useRouter } from 'next/router';
import {
  ContentWrapperStyled,
  DiscountProgramContext,
  FastLinksMenuButtonContainer,
  FastLinksMenuContainer,
  getAdditionalColors,
  HeaderLoaderContainer as HeaderLoader,
  IconHelpComponent,
  LogoContainer,
  PRESETS,
  publicConfig,
  QueryParams,
  RegisterWarningContainer,
  useTheme,
  useUser,
} from '@lerna-core';
import { useDiscountProgram } from '@features/common/discountProgram';
import { getHomePageLink, isCollaborateWithLerna } from '../header.helpers';
import { HeaderDesktopContainerProps } from './headerDesktop.model';
import {
  HeaderDesktopButtonsContainerStyled,
  HeaderDesktopContainerStyled,
  HeaderDesktopContainerWrapperStyled,
  HeaderDesktopIconStyled,
  HeaderDesktopLeftStyled,
  HeaderDesktopStickyCenterStyled,
} from './headerDesktop.styled';
import {
  SearchButtonContainer,
  SearchContainer,
} from '@features/common/search';
import { DesktopLinksContainer } from './desktopLinks';
import { HeaderProfileContainer } from './headerProfile';

const { w: logoWidth, h: logoHeight } = PRESETS.logoCompany.opts;

export const HeaderDesktopContainer = ({
  showSearch,
  isSticky,
  hasSticky,
  handleOpenContactUsPopup,
  showFilters,
  headerLinkType,
  useLoginText,
  withLogin,
  handleLogoClick,
  discountProgramLogo,
  homePageHref,
}: HeaderDesktopContainerProps): ReactElement => {
  const router = useRouter();
  const theme = useTheme();
  const { user } = useUser();
  const discountProgram = useContext(DiscountProgramContext);
  const { filtersAccentColor } = getAdditionalColors(
    discountProgram,
    user?.company?.uuid
  );
  const { isDiscountProgramPage } = useDiscountProgram();
  const homePageLink = homePageHref || getHomePageLink(router);
  const withCompany = !!user?.career?.companyId;

  const showProfileButton =
    publicConfig.HAS_ACCOUNT === 'true' &&
    !router.query[QueryParams.discountProgram];
  const logo =
    isDiscountProgramPage && !!discountProgramLogo ? (
      cloneElement(discountProgramLogo, { href: homePageLink })
    ) : (
      <LogoContainer
        href={homePageLink}
        logo={user?.company?.logo}
        callback={handleLogoClick}
        withCompany={!isDiscountProgramPage && withCompany}
        width={logoWidth}
        height={logoHeight}
        isCollaborateWithLerna={isCollaborateWithLerna(
          user,
          isDiscountProgramPage
        )}
      />
    );

  return (
    <>
      <HeaderDesktopContainerStyled
        isSticky={isSticky}
        showSearch={showSearch}
        hasSticky={hasSticky}
        withCompany={withCompany}
      >
        <ContentWrapperStyled margin="0 auto">
          <HeaderDesktopContainerWrapperStyled>
            <HeaderDesktopLeftStyled>{logo}</HeaderDesktopLeftStyled>
            <HeaderDesktopStickyCenterStyled
              withCompany={withCompany}
              isSticky={isSticky}
              showSearch={showSearch}
            >
              <FastLinksMenuButtonContainer isMobile={false} />
              {showSearch && <SearchContainer showSearch={showSearch} />}
              {showFilters && (
                <SearchButtonContainer
                  isFullButton={false}
                  isSticky={isSticky}
                />
              )}
            </HeaderDesktopStickyCenterStyled>
            <HeaderDesktopButtonsContainerStyled>
              <DesktopLinksContainer headerLinkType={headerLinkType} />
              <HeaderDesktopIconStyled onClick={handleOpenContactUsPopup}>
                <IconHelpComponent
                  fillColor={theme.COLOR.TEXT_DARK_COLOR}
                  strokeColor={theme.COLOR.TEXT_DARK_COLOR}
                />
              </HeaderDesktopIconStyled>
              {showProfileButton && (
                <HeaderProfileContainer
                  withLogin={withLogin}
                  useLoginText={useLoginText}
                />
              )}
            </HeaderDesktopButtonsContainerStyled>
          </HeaderDesktopContainerWrapperStyled>
          <RegisterWarningContainer />
        </ContentWrapperStyled>
        <HeaderLoader withCompany={withCompany} />
        <FastLinksMenuContainer filtersAccentColor={filtersAccentColor} />
      </HeaderDesktopContainerStyled>
    </>
  );
};
