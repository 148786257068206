import { ReactElement, useEffect, useRef } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFastLinkHref,
  getFastLinksCoursesPath,
} from '../../../fastLinks.helper';
import { openFastLinksMenu } from '../../fastLinksMenu.actions';
import { ImageContainer, useTranslations } from '../../../../../features';
import * as S from './schools.styled';
import { SchoolsModel } from './schools.model';
import { urlService } from '../../../../../services';
import { getFastLinksMenuStatusSelector } from '../../fastLinksMenu.selectors';
import { Nullable } from '../../../../../models';

export const SchoolsContainer = ({
  activeSphere,
}: SchoolsModel): ReactElement => {
  const translations = useTranslations();
  const router = useRouter();
  const dispatch = useDispatch();
  const wrapperRef = useRef<Nullable<HTMLDivElement>>(null);
  const { isOpened } = useSelector(getFastLinksMenuStatusSelector);
  const coursesPath = getFastLinksCoursesPath(router.query);

  const handleCloseFastLinksMenu = () => {
    dispatch(openFastLinksMenu(false));
  };

  useEffect(() => {
    wrapperRef.current?.scroll(0, 0);
  }, [isOpened, activeSphere]);

  return (
    <S.Schools>
      <S.SchoolsTitle>{translations.schools}</S.SchoolsTitle>
      <S.SchoolsItems ref={wrapperRef}>
        {activeSphere.schools.map((item) => (
          <Link
            key={item.slug}
            href={urlService.getFullUrlToPage(
              getFastLinkHref(item.slug, coursesPath),
              router
            )}
            onClick={handleCloseFastLinksMenu}
          >
            <S.SchoolsItem>
              <S.SchoolsItemImage>
                {item.image && (
                  <ImageContainer
                    height={48}
                    width={48}
                    preset="logoCourseSmall"
                    imageResource={item.image.imageResource}
                  />
                )}
              </S.SchoolsItemImage>
              <S.SchoolsItemTitle>{item.title}</S.SchoolsItemTitle>
            </S.SchoolsItem>
          </Link>
        ))}
        <S.SchoolsItem />
      </S.SchoolsItems>
    </S.Schools>
  );
};
