import styled, { css } from 'styled-components';

import { breakpoint } from '../../../../styles';
import { getStylesByMode } from '../popup.service';
import { PopupColorModeModel } from '../popup.model';

type PopupCloseContainerStyledProps = {
  rotate: string;
  isMobile: boolean;
  colorMode: PopupColorModeModel;
  closeOutside?: boolean;
  closePositionRight?: boolean;
  fullHeightMobile: boolean;
};

export const PopupCloseContainerStyled = styled.div<PopupCloseContainerStyledProps>`
  cursor: pointer;
  ${({ theme, colorMode }): string => getStylesByMode(theme, colorMode)}
  width: 32px;
  height: 32px;
  position: absolute;
  border-radius: 100px;
  top: 20px;
  right: 20px;
  transform: rotate(${({ rotate }): string => rotate}deg);
  z-index: ${({ theme }) => theme.ZINDEX.TOP_BLOCK};
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ closeOutside, theme }): string =>
    closeOutside
      ? `
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 9px;
        top: 0;
        right: -40px;
        background: ${theme.COLOR.BLACK_4};
        border-radius: 50%;
        width: 32px;
        height: 32px;
        box-sizing: border-box; 
      `
      : `
        top: 20px;
        right: 20px;
        `}

  ${({
    isMobile,
    closeOutside,
    closePositionRight,
    fullHeightMobile,
  }): ReturnType<typeof css> | string => {
    return breakpoint(
      'smallDown',
      css`
        ${!closeOutside &&
        fullHeightMobile &&
        `${
          closePositionRight
            ? 'right: 16px; top: 12px;'
            : `left: 16px; top: 12px;`
        }`}
      `,
      isMobile
    );
  }}

    ${({
    isMobile,
    closeOutside,
    closePositionRight,
  }): ReturnType<typeof css> | string => {
    return breakpoint(
      'mediumDown',
      css`
        ${closeOutside &&
        `${
          closePositionRight
            ? 'right: 12px; top: 12px;'
            : `left: 12px; top: 12px;`
        }`}
      `,
      isMobile
    );
  }}
`;
