import React, { ReactElement } from 'react';
import { IconProps } from '@lerna-core';

export const IconMediaComponent = ({
  width = 40,
  height = 40,
  fillColor = '#713AE0',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.08333 5.79999C4.56685 5.79999 2.5 7.86441 2.5 10.3779V29.522C2.5 32.0356 4.56685 34.1 7.08333 34.1H32.9167C35.4331 34.1 37.5 32.0356 37.5 29.522V10.3779C37.5 7.86441 35.4331 5.79999 32.9167 5.79999H7.08333ZM7.08333 8.29705H32.9167C34.0819 8.29705 35 9.21411 35 10.3779V29.522C35 30.6859 34.0819 31.6029 32.9167 31.6029H7.08333C5.91814 31.6029 5 30.6859 5 29.522V10.3779C5 9.21411 5.91814 8.29705 7.08333 8.29705ZM17.4788 14.1235C17.1933 14.1272 16.9094 14.2033 16.6553 14.3527C16.1461 14.6524 15.8333 15.1989 15.8333 15.7882V24.1118C15.8333 24.7011 16.1461 25.2476 16.6553 25.5472C16.9153 25.7004 17.2075 25.7765 17.5 25.7765C17.7783 25.7765 18.0573 25.7057 18.3089 25.5667L25.8089 21.405C26.3381 21.1112 26.6667 20.5551 26.6667 19.95C26.6667 19.3449 26.3381 18.7888 25.8089 18.495L18.3089 14.3332C18.051 14.1901 17.7644 14.1199 17.4788 14.1235Z"
      fill={fillColor}
    />
  </svg>
);
