import { SearchButtonContainerStyled } from '../../search';
import { breakpoint, CSSVariables, positionSticky } from '@lerna-core';
import styled, { css } from 'styled-components';
import { HeaderMobileContainerStyledProps } from './headerMobile.model';

export const headerIconContainer = (): ReturnType<typeof css> => css`
  width: 24px;
  height: 24px;
  position: relative;
`;

export const HeaderMobileContainerStyled = styled.div<HeaderMobileContainerStyledProps>`
  box-sizing: border-box;
  height: 64px;
  padding: 0 20px;
  position: relative;
  z-index: ${(props): string => props.theme.ZINDEX.TOP_PAGE_LAYER};
  background: rgb(255, 255, 255);
  ${({ isSticky }): ReturnType<typeof css> =>
    isSticky
      ? css`
          top: 0;
          ${positionSticky()};
        `
      : css``};

  &:after {
    content: '';
    background: rgb(255, 255, 255);
    width: var(${CSSVariables.scrollbarPlugWidth});
    right: calc(0px - var(${CSSVariables.scrollbarPlugWidth}));
    position: absolute;
    height: 100%;
    top: 0;
  }

  ${breakpoint(
    'mediumDown',
    css`
      height: 56px;
    `
  )}
`;

export const HeaderMobileWrapperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 100%;
`;

export const HeaderMobileIconStyled = styled.div`
  ${headerIconContainer()};

  &:not(:last-child) {
    margin-right: 20px;
  }

  ${SearchButtonContainerStyled} {
    margin: 0;

    > button {
      width: 100%;
      height: 100%;
      padding: 0;
    }
  }
`;

export const HeaderMobileBoxStyled = styled.div`
  display: flex;
  align-items: center;
  z-index: 200;
`;
