import React, { ReactElement, useContext } from 'react';
import { FiltersShowAllContainerProps } from './filtersShowAll.model';
import { ArrowStyled, FiltersShowAllStyled } from './filtersShowAll.styled';
import {
  DiscountProgramContext,
  getAdditionalColors,
  IconArrowLongComponent,
  useTheme,
  useTranslations,
  useUser,
} from '@lerna-core';

export const FiltersShowAllContainer = ({
  handleClick,
}: FiltersShowAllContainerProps): ReactElement => {
  const translations = useTranslations();
  const theme = useTheme();

  const discountProgram = useContext(DiscountProgramContext);
  const { user } = useUser();
  const { filtersAccentColor } = getAdditionalColors(
    discountProgram,
    user?.company?.uuid
  );

  return (
    <FiltersShowAllStyled
      customColor={filtersAccentColor}
      onClick={handleClick}
    >
      {translations?.show_all_filters}
      <ArrowStyled>
        <IconArrowLongComponent
          width={16}
          height={16}
          strokeColor={filtersAccentColor || theme.COLOR.PRIMARY[500]}
        />
      </ArrowStyled>
    </FiltersShowAllStyled>
  );
};
