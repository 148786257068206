import { logoDeleteAdapter } from './logoDelete.adapter';
import { getLogoDeleteRequestParams } from './logoDelete.helper';
import { publicConfig } from '../../../../services';
import { RequestClient } from '../../../../services/request/request.service';
import { ImageResourceModel, Nullable } from '../../../../models';

type ImageEntity = {
  entity: string;
  filename: string;
  path: string;
  server?: Nullable<string>;
};

export type DeleteLogoParams = {
  image: ImageEntity;
  source: ImageEntity;
};

export type DeleteLogoApiModel = {
  data: {
    response: { deleted: boolean };
    status: string;
  };
};

export class LogoDeleteClient extends RequestClient<
  DeleteLogoParams,
  DeleteLogoApiModel,
  boolean
> {
  protected path = 'ms-uploader-api/v1/image/delete';
  protected requestParameters: DeleteLogoParams;
  protected adapter = logoDeleteAdapter;

  constructor(host = publicConfig.API_GATEWAY_URL, params: ImageResourceModel) {
    super(host, '', { 'Content-Type': `multipart/form-data` });
    this.requestParameters = getLogoDeleteRequestParams(params);
  }
}
