import React, { ReactElement } from 'react';
import { IconProps } from '@lerna-core';

export const IconSportComponent = ({
  width = 40,
  height = 40,
  fillColor = '#713AE0',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.0333 3.33496C10.8249 3.33496 3.33325 10.8266 3.33325 20.035C3.33325 29.2433 10.8249 36.735 20.0333 36.735C29.2416 36.735 36.7333 29.2433 36.7333 20.035C36.7333 10.8266 29.2416 3.33496 20.0333 3.33496ZM19.0613 5.88889C19.4014 9.64568 21.0093 13.315 23.8804 16.1861C26.7518 19.0575 30.4227 20.6555 34.1793 20.9955C33.7135 27.9698 28.2514 33.5434 21.3216 34.1647C21.0397 30.3075 19.4363 26.5225 16.491 23.5772C13.5457 20.6318 9.76122 19.0252 5.90349 18.7433C6.52595 11.8188 12.0934 6.3598 19.0613 5.88889ZM21.5907 5.92966C28.1993 6.6481 33.4263 11.8773 34.1402 18.4873C31.0399 18.1495 28.0349 16.7951 25.6532 14.4134C23.2727 12.0329 21.9288 9.02826 21.5907 5.92966ZM5.90022 21.2467C9.11719 21.521 12.2537 22.882 14.7199 25.3483C17.1866 27.815 18.5389 30.9507 18.8134 34.168C11.9345 33.5841 6.48029 28.1266 5.90022 21.2467Z"
      fill={fillColor}
    />
  </svg>
);
