import {
  FastLinkMenuApiModel,
  FastLinkMenuApiResponseModel,
  FastLinkMenuItemApiModel,
  FastLinkMenuItemModel,
  FastLinkMenuModel,
} from './fastLinksMenu.model';
import { mapImage } from '../../../features';

export const fastLinkMenuItemAdapter = (
  item: FastLinkMenuItemApiModel
): FastLinkMenuItemModel => ({
  title: item.title,
  sort: item.sort,
  slug: item.slug,
  image: item.image ? mapImage(item.image) : null,
});

export const fastLinkMenuAdapter = (
  data: FastLinkMenuApiModel
): FastLinkMenuModel => ({
  ...fastLinkMenuItemAdapter(data),
  fastlinks: data.fastlinks?.map(fastLinkMenuItemAdapter) || [],
  schools: data.schools?.map(fastLinkMenuItemAdapter) || [],
});

export const fastLinksMenuResponseAdapter = ({
  data,
}: FastLinkMenuApiResponseModel): FastLinkMenuModel[] =>
  data.data.map(fastLinkMenuAdapter);
