import { ParsedUrlQuery } from 'querystring';
import {
  cookieService,
  publicConfig,
  urlService,
  utmService,
} from '../../../services';
import { projectSubDomains } from '../../../constants';
import { NextRouter } from 'next/router';
import { Nullable, QueryParams, TranslationModel } from '../../../models';
import { UserAggregateModel } from '../../../features';
import { FastLinkStaticItemModel } from './fastLinksMenu.model';
import {
  AllSpheresIconComponent,
  PeopleIconComponent,
} from '../fastLinksIcons';

export enum B2bLandingLinkUtmEnum {
  utmMedium = '(none)',
  utmCampaign = 'all_business-information_{{domain}}_(none)_header_platform_{{region}}_b2b_lernacorp',
}

const getB2BLandingLinkQueries = (): ParsedUrlQuery => {
  const domain = publicConfig?.BASE_DOMAIN.replace('.', '-');

  return {
    source: domain,
    medium: B2bLandingLinkUtmEnum.utmMedium,
    campaign: B2bLandingLinkUtmEnum.utmCampaign
      .replace('{{domain}}', domain)
      .replace('{{region}}', publicConfig?.REGION),
  };
};

export const getB2BLandingLink = (): string =>
  `${urlService.getFullUrlToSubDomain(
    projectSubDomains.corp,
    null
  )}?${utmService.getUtmQueryStringFromObject(getB2BLandingLinkQueries())}`;

export const getStaticItems = (
  router: NextRouter,
  translations: TranslationModel,
  user: Nullable<UserAggregateModel>,
  filtersAccentColor?: string
): FastLinkStaticItemModel[] => {
  const items = [
    {
      title: translations.all_categories,
      icon: (
        <AllSpheresIconComponent
          fillColor={filtersAccentColor}
          height={24}
          width={24}
        />
      ),
      link: urlService.getFullUrlToPage('/courses/', router),
    },
  ];

  if (!user?.personal && publicConfig?.HAS_B2B_LANDING === 'true') {
    items.push({
      title: translations.b2b_link_label,
      icon: (
        <PeopleIconComponent
          fillColor={filtersAccentColor}
          strokeColor={filtersAccentColor}
          height={24}
          width={24}
        />
      ),
      link: getB2BLandingLink(),
    });
  }

  return items;
};

export const DEBUG_FASTLINKS_MENU_COOKIE_NAME = 'debugFastlinks';

export const canShowFastLinksMenu = (
  user: Nullable<UserAggregateModel>,
  router: NextRouter
): boolean => {
  const isPromoCatalog =
    router.asPath.startsWith('/promo/') ||
    router.query[QueryParams.discountProgram];
  const debugFastLinksMenuCookie = cookieService.getCookie(
    DEBUG_FASTLINKS_MENU_COOKIE_NAME
  );

  return (
    debugFastLinksMenuCookie === '1' &&
    !(user?.career?.isHeaderFastLinkMenuHidden || isPromoCatalog)
  );
};
