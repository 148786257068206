import React, { ReactElement } from 'react';
import { IconProps } from '@lerna-core';

export const IconAnalyticsDatascienceComponent = ({
  width = 40,
  height = 40,
  fillColor = '#713AE0',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={fillColor}
      d="M31.638 3c-2.283 0-4.16 1.88-4.16 4.167v24.966c0 2.286 1.877 4.167 4.16 4.167 2.284 0 4.162-1.88 4.162-4.167V7.167C35.8 4.88 33.922 3 31.638 3Zm0 2.497a1.65 1.65 0 0 1 1.668 1.67v24.966a1.65 1.65 0 0 1-1.668 1.67 1.649 1.649 0 0 1-1.666-1.67V7.167c0-.937.73-1.67 1.666-1.67Zm-23.278 5c-2.283 0-4.16 1.878-4.16 4.164v17.472c0 2.286 1.877 4.167 4.16 4.167 2.283 0 4.162-1.88 4.162-4.167V14.661c0-2.286-1.879-4.165-4.162-4.165Zm0 2.496c.936 0 1.668.732 1.668 1.668v17.472a1.65 1.65 0 0 1-1.668 1.67 1.649 1.649 0 0 1-1.666-1.67V14.661c0-.936.73-1.668 1.666-1.668Zm11.644 4.99c-2.283 0-4.162 1.88-4.162 4.167v9.983c0 2.286 1.879 4.167 4.162 4.167s4.162-1.88 4.162-4.167V22.15c0-2.287-1.879-4.167-4.162-4.167Zm0 2.497a1.65 1.65 0 0 1 1.668 1.67v9.983a1.65 1.65 0 0 1-1.668 1.67 1.65 1.65 0 0 1-1.668-1.67V22.15c0-.937.732-1.67 1.668-1.67Z"
    />
  </svg>
);
