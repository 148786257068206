import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconBillComponent = ({
  width = 24,
  height = 24,
  strokeColor = '#713AE0',
}: IconProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
  >
    <path
      stroke={strokeColor}
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M4.5 19.514V1.8l2.576 1.143L9.938 1.8 12.8 2.943 15.662 1.8l2.862 1.143L21.1 1.8v17.714a2.288 2.288 0 0 1-2.29 2.286H6.79a2.288 2.288 0 0 1-2.29-2.286Z"
    />
    <path
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M7.934 8.657h9.732M7.934 13.8h5.152M16.52 13.8h1.146M7.934 17.229h5.152M16.52 17.229h1.146"
    />
  </svg>
);
