import { css } from 'styled-components';
import { ITheme } from '../../../../features';

export const getActiveStyles = (
  theme: ITheme,
  isActive?: boolean
): ReturnType<typeof css> => {
  if (isActive) {
    return css`
      background-color: ${theme.COLOR.BLACK_4};
    `;
  }

  return css``;
};
