import { Nullable, TranslationModel } from '../../models';
import astana_hub from './astana_hub.json';
import jti from './jti.json';
import { CompanyUuidEnum } from '../../features';

export const getTranslationsByCompany = (
  id?: string
): Nullable<TranslationModel> => {
  switch (id) {
    case CompanyUuidEnum.astanaHub: {
      return astana_hub;
    }
    case CompanyUuidEnum.jti: {
      return jti;
    }
    default: {
      return null;
    }
  }
};
