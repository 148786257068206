import { createSelector } from 'reselect';
import { FastLinksMenuReducerState } from './fastLinksMenu.model';
import { RootState } from '../../../models';

export const getFastLinksMenuSelector = createSelector(
  (state: RootState<FastLinksMenuReducerState>) => state.fastLinksMenu,
  (reducer: FastLinksMenuReducerState) => reducer.fastlinks.data
);

export const getFastLinksMenuSchoolsSelector = createSelector(
  (state: RootState<FastLinksMenuReducerState>) => state.fastLinksMenu,
  (reducer: FastLinksMenuReducerState) => reducer.schools.data
);

export const getFastLinksMenuStatusSelector = createSelector(
  (state: RootState<FastLinksMenuReducerState>) => state.fastLinksMenu,
  (reducer: FastLinksMenuReducerState) => reducer.status
);
