import { ReactElement, useContext } from 'react';
import { useSelector } from 'react-redux';
import Script from 'next/script';
import { useRouter } from 'next/router';
import { getVendors } from '../../features';
import { PresetsContext } from '../../contexts';
import { HeadCoursesMetaModel } from './headCoursesMeta.model';
import { mapCoursesMetaTag } from './headCoursesMeta.helper';

export const HeadCoursesMetaTags = ({
  courses,
}: HeadCoursesMetaModel): ReactElement => {
  const vendors = useSelector(getVendors);
  const router = useRouter();
  const context = useContext(PresetsContext);
  const mediaPresets = context?.mediaPresets;
  const content = mapCoursesMetaTag(courses, vendors, router, mediaPresets);

  return (
    // eslint-disable-next-line @next/next/no-before-interactive-script-outside-document
    <Script
      strategy="beforeInteractive"
      type="application/ld+json"
      id="Schema_courses"
    >
      {JSON.stringify(content)}
    </Script>
  );
};
