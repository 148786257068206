import React, { ReactElement } from 'react';
import * as S from './certificateWarning.styled';
import { useTranslations } from '../../translations';
import { showCertificateWarning } from './certificateWarning.helper';
import { Nullable } from '../../../models';
import { useUser } from '../../user';
import { useCertificateGeneration } from '../certificateGeneration';
import { CertificateWarningModel } from './certificateWarning.model';

export const CertificateWarning = ({
  course,
}: CertificateWarningModel): Nullable<ReactElement> => {
  const { user } = useUser();
  const { certificate } = useCertificateGeneration();
  const translations = useTranslations();

  if (!showCertificateWarning(user, certificate, course)) {
    return null;
  }

  return (
    <S.CoursesItemCertificateWarning>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="11.9999" cy="12" r="10" fill="#713AE0" />
        <path
          d="M12 7L12 12"
          stroke="white"
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <circle cx="12" cy="16" r="1" fill="white" />
      </svg>

      <span>{translations.certificate_can_be_granted}</span>
    </S.CoursesItemCertificateWarning>
  );
};
