import { CertificatePopupModel } from './certificatePopup.model';
import { PopupContainer } from '../../popups';
import * as S from './certificatePopup.styled';
import { useTranslations } from '../../translations';
import React, { ReactElement, useEffect } from 'react';
import { CertificateModel } from '../certificate.model';
import {
  ButtonChildrenPosition,
  ButtonColor,
  ButtonContainer,
} from '../../button';
import { IconDownloadComponent } from '../../icons';
import { useTheme } from '../../../hooks';
import { getSimpleEvent, logger, pushDataLayer } from '../../../services';
import { downloadCertificateClientProvider } from '../certificate.clientProvider';
import { useCertificateGeneration } from '../certificateGeneration';
import { CommonEventTypes } from '../../../analytics';
import { CertificateEvents } from '../certificate.analytics';

const PendingContent = (): ReactElement => {
  const translations = useTranslations();

  return (
    <>
      <S.LoaderDots>
        <S.LoaderDotsItem key={'dots-item-1'} />
        <S.LoaderDotsItem key={'dots-item-2'} />
        <S.LoaderDotsItem key={'dots-item-3'} />
      </S.LoaderDots>
      <S.CertificateTitle>
        {translations.certificate_popup_pending_title}
      </S.CertificateTitle>
      <S.CertificateSubtitle>
        {translations.certificate_popup_pending_subtitle}
      </S.CertificateSubtitle>
    </>
  );
};

const SupportContent = ({
  handleClose,
}: {
  handleClose: () => void;
}): ReactElement => {
  const translations = useTranslations();

  return (
    <>
      <S.CertificateTitle>
        {translations.certificate_popup_support_title}
      </S.CertificateTitle>
      <S.CertificateSubtitle>
        {translations.certificate_popup_support_subtitle}
      </S.CertificateSubtitle>

      <ButtonContainer
        title={translations.button_ok_title}
        color={ButtonColor.purple}
        onClick={handleClose}
      />
    </>
  );
};

const CertificateContent = ({
  certificate,
  handleClose,
}: {
  certificate: CertificateModel;
  handleClose: () => void;
}): ReactElement => {
  const theme = useTheme();
  const translations = useTranslations();
  const { handleSetCertificateError, eventPosition } =
    useCertificateGeneration();

  const handleDownloadCertificate = () => {
    if (eventPosition) {
      pushDataLayer(
        getSimpleEvent(
          CertificateEvents.certificateDownloadButton,
          CommonEventTypes.overallEvents,
          eventPosition
        )
      );
    }
    downloadCertificateClientProvider(certificate.id)
      .then((response) => {
        const blob = new Blob([response], { type: 'application/pdf' });
        const downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(blob);
        downloadLink.download = certificate.certificateNumber;
        downloadLink.click();
        downloadLink.remove();
        handleClose();
      })
      .catch((error) => {
        logger.error(`[ERROR] ${error}`);
        handleSetCertificateError();
      });
  };

  useEffect(() => {
    if (eventPosition) {
      pushDataLayer(
        getSimpleEvent(
          CertificateEvents.certificateSuccess,
          CommonEventTypes.overallEvents,
          eventPosition
        )
      );
    }
  }, []);

  return (
    <>
      <S.CertificateTitle>{translations.congratulations}!</S.CertificateTitle>
      <S.CertificateSubtitle>
        {translations.your_certificate_is_ready}
      </S.CertificateSubtitle>

      <ButtonContainer
        fullWidth
        title={translations.download_certificate}
        color={ButtonColor.purple}
        onClick={handleDownloadCertificate}
        childrenPosition={ButtonChildrenPosition.left}
        children={
          <IconDownloadComponent
            fillColor={theme.COLOR.WHITE}
            height={24}
            width={24}
          />
        }
      />
    </>
  );
};

const ErrorContent = (): ReactElement => {
  const translations = useTranslations();
  const { eventPosition, retryCounter, handleRetryGenerateCertificate } =
    useCertificateGeneration();

  useEffect(() => {
    if (eventPosition) {
      pushDataLayer(
        getSimpleEvent(
          CertificateEvents.certificateError,
          CommonEventTypes.overallEvents,
          eventPosition
        )
      );
    }
  }, []);

  return (
    <>
      <S.CertificateTitle>
        {translations.certificate_popup_error_title}
      </S.CertificateTitle>
      <S.CertificateSubtitle>
        {translations.certificate_popup_error_subtitle}
      </S.CertificateSubtitle>
      {retryCounter < 3 && (
        <ButtonContainer
          title={translations.try_once_more}
          color={ButtonColor.purple}
          onClick={handleRetryGenerateCertificate}
        />
      )}
    </>
  );
};

export const CertificatePopup = ({
  isOpened,
  handleClose,
  certificate,
  hasError,
  isCertificatePending,
  isCertificateSendToSupport,
}: CertificatePopupModel) => (
  <PopupContainer
    width="600px"
    isOpened={isOpened}
    fullHeightMobile={false}
    handleClose={handleClose}
  >
    {isCertificatePending && <PendingContent />}
    {isCertificateSendToSupport && <SupportContent handleClose={handleClose} />}
    {!hasError && certificate?.isFileGenerated && !isCertificatePending && (
      <CertificateContent handleClose={handleClose} certificate={certificate} />
    )}
    {hasError && !isCertificatePending && <ErrorContent />}
  </PopupContainer>
);
