import { ParsedUrlQuery } from 'querystring';
import { QueryParams } from '../../models';
import { mainPath } from '../../constants';

export const getCoursesPath = (query: ParsedUrlQuery): string => {
  const discount = query[QueryParams.discountProgram] as string;
  const iframeInitiator = query[QueryParams.initiator] as string;

  switch (true) {
    case !!discount && !!iframeInitiator: {
      return mainPath.iframePromo
        .replace(`[${QueryParams.initiator}]`, iframeInitiator)
        .replace(`[${QueryParams.discountProgram}]`, discount);
    }
    case !!iframeInitiator: {
      return mainPath.iframeCourses.replace(
        `[${QueryParams.initiator}]`,
        iframeInitiator
      );
    }
    case !!discount: {
      return mainPath.promo.replace(
        `[${QueryParams.discountProgram}]`,
        discount
      );
    }
    default:
      return mainPath.courses;
  }
};
