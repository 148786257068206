import styled, { css } from 'styled-components';
import {
  breakpoint,
  customizeScrollBar,
  fontSettings,
  lineClamping,
  transition,
} from '../../../../../styles';
import { getActiveStyles } from '../fastLinksMenuDesktop.mixins';
import { MAX_HEIGHT } from '../fastLinksMenuDesktop.styled';

export const FastLinks = styled.div`
  margin-bottom: auto;
`;

export const FastLinksTitle = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_22, theme.FONT)};
  font-weight: 600;
  margin-bottom: 28px;
  ${lineClamping(1)};
`;

export const FastLinksColumn = styled.div`
  flex: 1;
  word-break: break-word;
`;

export const FastLinksItems = styled.div`
  display: flex;
  flex-direction: column;
  max-height: ${MAX_HEIGHT};
  ${customizeScrollBar()};
  padding-right: 20px;
  margin: -12px -12px 0;
  gap: 0;

  a:not(:last-child) {
    display: block;
    margin-bottom: 4px;
  }

  ${breakpoint(
    '2xlargeUp',
    css`
      flex-direction: row;
      gap: 24px;
    `
  )}
`;

export const FastLinksItem = styled.div<{ noPointer?: boolean }>`
  padding: 12px;
  box-sizing: border-box;
  border-radius: 8px;
  cursor: ${({ noPointer }) => (noPointer ? 'default' : 'pointer')};
  align-items: center;
  ${transition('background-color', 0.2)};

  span {
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
    line-height: 24px;
    color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
    font-weight: 500;
    ${lineClamping(3)};
  }

  &:hover {
    ${({ theme, noPointer = false }) => getActiveStyles(theme, !noPointer)};
  }
`;
