import { mapImage } from '../../features';
import {
  DiscountProgramColorsApiModel,
  DiscountProgramColorsModel,
  DiscountProgramModel,
  DiscountProgramResponseApiModel,
} from './discountProgram.model';

//todo: временно добавлено в либу
const mapDiscountProgramColors = (
  colors: DiscountProgramColorsApiModel
): DiscountProgramColorsModel => ({
  buttonAccentColor: colors.button_accent_color,
  buttonAccentBackground: colors.button_accent_background,
  filtersAccentColor: colors.filters_accent_color,
  secondaryColor: colors.secondary_color,
});

export const discountProgramAdapter = ({
  data,
}: DiscountProgramResponseApiModel): DiscountProgramModel => ({
  name: data.name,
  slug: data.slug,
  colors: mapDiscountProgramColors(data.colors),
  isHiddenAuthorization: data.is_hidden_authorization,
  logo: data?.logo ? mapImage(data.logo) : null,
  favicon: data?.favicon ? mapImage(data.favicon) : null,
});
