import React, { ReactElement } from 'react';

import { AccordionContentStyled } from './accordionContent.styled';
import { AccordionContentContainerProps } from './accordionContent.model';

export const AccordionContentContainer = ({
  content,
  isOpened,
  version,
}: AccordionContentContainerProps): ReactElement | null => {
  switch (true) {
    case typeof content === 'string':
      return (
        <AccordionContentStyled
          dangerouslySetInnerHTML={{ __html: content as string }}
          isOpened={isOpened}
          version={version}
        />
      );
    case typeof content === 'object':
      return (
        <AccordionContentStyled isOpened={isOpened} version={version}>
          {content}
        </AccordionContentStyled>
      );
    default:
      return null;
  }
};
