import { TranslationModel } from '../../../../models';
import { FastLinksMenuTabModel } from '../fastLinksMenuTabs';
import { FastLinksMenuTabs } from './fastLinksMenuMobile.model';

export const getFastLinksMenuTabs = (
  translations: TranslationModel
): FastLinksMenuTabModel[] => [
  {
    id: FastLinksMenuTabs.sphere,
    title: translations.sphere,
  },
  {
    id: FastLinksMenuTabs.schools,
    title: translations.schools || 'Школы',
  },
];
