import { FastLinksMenuClient } from './fastLinksMenu.client';
import { FastLinkMenuModel } from './fastLinksMenu.model';
import { RequestHeader } from '../../../services';
import { Nullable } from '../../../models';
import { UserAggregateModel } from '../../../features';

class FastLinksMenuProvider {
  protected request: Nullable<FastLinksMenuClient>;
  protected coursesPath = 'course-api/v1/catalog/fastlinks/menu';
  protected usersPath = 'user-api/v1/catalog/fastlinks/menu';

  constructor() {
    this.request = null;
  }

  public async get(
    user: Nullable<UserAggregateModel>,
    requestHeaders?: RequestHeader
  ): Promise<FastLinkMenuModel[]> {
    const path = !!user?.company?.uuid ? this.usersPath : this.coursesPath;

    this.request = new FastLinksMenuClient(path, requestHeaders);

    const response = this.request.get();

    return response as unknown as Promise<FastLinkMenuModel[]>;
  }
}

export const FastLinksMenuClientProvider = new FastLinksMenuProvider();
