import {
  AggregateResponseApiErrorModel,
  bannerAdapter,
  CatalogPageTypes,
  checkError,
  courseAdapter,
  coursesAdapter,
  CurrencyModel,
  discountProgramAdapter,
  fastLinksAdapter,
  footerAdapter,
  mapCompany,
  mapCurrencies,
  mapMetaPaginationAdapter,
  mapUser,
  promoCodeWithErrorAdapter,
  TranslationModel,
  vendorsAdapter,
} from '@lerna-core';
import {
  CourseDataErrorModel,
  CourseDataModel,
  CoursePageApiModel,
} from './course.model';

export const coursePageAdapter = (
  response: CoursePageApiModel
): CourseDataModel | CourseDataErrorModel => {
  const { data } = response;
  const statusCode = checkError(
    data as unknown as AggregateResponseApiErrorModel,
    [
      'course',
      'currencies',
      'footer',
      'similar_courses',
      'statics',
      'vendors',
      'seo',
    ]
  );

  const company = !!data.company ? mapCompany(data.company) : null;

  if (statusCode) {
    return {
      statusCode,
      company,
    };
  }

  const currencies: CurrencyModel[] = mapCurrencies(
    data.currencies?.data || []
  );
  const translations: TranslationModel = data.statics.data.translations;
  const vendors = vendorsAdapter(data.vendors.data || []);
  const footer = footerAdapter(data.footer);

  const promoCode = promoCodeWithErrorAdapter(
    data?.promo_code?.data,
    data.error_promo_code
  );

  return {
    course: courseAdapter(data.course?.data),
    fastLinks: response.data.fastlinks
      ? fastLinksAdapter(response.data.fastlinks.data)
      : null,
    similarCourses: data?.similar_courses
      ? {
          data: coursesAdapter(data.similar_courses?.data || []) || [],
          pagination: mapMetaPaginationAdapter(
            data.similar_courses.meta.pagination
          ),
        }
      : null,
    footer,
    translations,
    company,
    seo: data.seo?.data || {},
    user:
      !!data?.personal && !!data?.bonuses
        ? mapUser(
            data?.personal,
            data.bonuses,
            data?.career,
            data?.company,
            data.me_courses
          )
        : null,
    pageName: CatalogPageTypes.coursePage,
    vendors,
    currencies,
    discountProgram: data?.discount_programs
      ? discountProgramAdapter(data.discount_programs)
      : null,
    banners: data?.banners ? data.banners.data.map(bannerAdapter) : null,
    promoCode,
  };
};
