import { VendorModel } from '../../vendors';
import { ReactElement } from 'react';
import { ImageResourceModel, Nullable } from '../../../models';
import { CourseItemApiVendorModel } from '../../customer';

export type CourseItemContainerProps = {
  course: CourseItemModel;
  isMobile?: boolean;
  button?: ReactElement;
  certificateButton?: ReactElement;
  className?: string;
};

export type CourseItemComponentProps = {
  course: CourseItemModel;
  button?: ReactElement;
  certificateButton?: ReactElement;
  className?: string;
};

export type CourseItemApiModel = {
  id: string;
  lms_course: {
    id: string;
    vendor_course_id: string;
    lms_course_id: string;
    title: string;
    vendor: CourseItemApiVendorModel;
    course: Nullable<{
      id: string;
      title: string;
      url: string;
      type: string;
      type_title: string;
      study_type: {
        title: string;
        slug: string;
      };
      logo: ImageResourceModel;
    }>;
  };
  progress: Nullable<CourseItemProgressApiModel>;
  access: {
    is_homework_available: boolean;
    access_type: number;
  };
};

export type CourseItemProgressItemApiModel = {
  total: number;
  done: number;
};

export type CourseItemProgressApiModel = {
  progress_percent: number;
  diplomas: CourseItemProgressItemApiModel;
  modules: CourseItemProgressItemApiModel;
  practical: CourseItemProgressItemApiModel;
  tests: CourseItemProgressItemApiModel;
  videos: CourseItemProgressItemApiModel;
  total: CourseItemProgressItemApiModel;
};

type CourseItemProgressItemModel = {
  total: number;
  done: number;
};

export type CourseItemProgressModel = {
  progressPercent: number;
  diplomas: CourseItemProgressItemModel;
  modules: CourseItemProgressItemModel;
  practical: CourseItemProgressItemModel;
  tests: CourseItemProgressItemModel;
  videos: CourseItemProgressItemModel;
  total: CourseItemProgressItemModel;
};

export enum CourseAccessTypes {
  b2b = 'b2b',
  b2c = 'b2c',
}

export type CourseItemAccessModel = {
  isHomeworkAvailable: boolean;
  accessType: Nullable<CourseAccessTypes>;
};

export type CourseItemModel = {
  uuid: string;
  access: CourseItemAccessModel;
  title: string;
  studyType: string;
  type: string;
  typeTitle: string;
  courseUrl?: string;
  courseId: string;
  lmsCourseId: string;
  order?: number;
  vendor: VendorModel;
  logo: Nullable<ImageResourceModel>;
  progress: Nullable<CourseItemProgressModel>;
};
