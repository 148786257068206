import { css } from 'styled-components';

export const getActiveLinkStyle = (
  isActive = false
): ReturnType<typeof css> => {
  if (isActive) {
    return css`
      pointer-events: none;
      box-shadow: 0 5px 25px -5px rgba(12, 11, 13, 0.04);
      background-color: ${({ theme }): string => theme.COLOR.WHITE};
    `;
  }

  return css``;
};

export const getAdaptiveLinkStyle = (
  isAdaptive: boolean
): ReturnType<typeof css> => {
  if (isAdaptive) {
    return css`
      flex: 1;
    `;
  }

  return css``;
};
