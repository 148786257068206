import React, { ReactElement, useMemo } from 'react';
import { MenuMobileStyled } from './menuMobile.styled';
import { PopupContainer } from '../../../popups';
import { MenuMobileContainerProps } from './menuMobile.model';
import { MobileDynamicLinksContainer } from './mobileDynamicLinks';
import { MobileStaticLinksContainer } from './mobileStaticLinks';
import {
  getUserMenuBottomStaticLinks,
  getUserMenuTopStaticLinks,
} from '../userMenu.helpers';
import { useRouter } from 'next/router';
import { UserInfoContainer } from './mobileUserInfo';
import { useTranslations } from '../../../translations';
import { useUser } from '../../user.hook';

export const MenuMobileContainer = ({
  menu,
  isMobile,
  isOpened,
  handleClose,
  bottomStaticLinks,
  projectPageName,
  withLogin,
}: MenuMobileContainerProps): ReactElement => {
  const translations = useTranslations();
  const router = useRouter();
  const { user } = useUser();

  const staticLinksTop = useMemo(
    () => getUserMenuTopStaticLinks(translations, router, user, withLogin),
    []
  );

  const staticLinksBottom = useMemo(
    () =>
      getUserMenuBottomStaticLinks(
        translations,
        router,
        user,
        bottomStaticLinks
      ),
    [bottomStaticLinks]
  );

  return (
    <PopupContainer
      isOpened={isOpened}
      handleClose={handleClose}
      isMobile={isMobile}
    >
      <MenuMobileStyled>
        <UserInfoContainer />
        <MobileStaticLinksContainer
          links={staticLinksTop}
          handleClose={handleClose}
          projectPageName={projectPageName}
        />
        <MobileDynamicLinksContainer
          menu={menu}
          handleClose={handleClose}
          projectPageName={projectPageName}
        />
        <MobileStaticLinksContainer
          links={staticLinksBottom}
          handleClose={handleClose}
          projectPageName={projectPageName}
        />
      </MenuMobileStyled>
    </PopupContainer>
  );
};
