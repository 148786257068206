import React, { ReactElement } from 'react';
import { IconProps } from '@lerna-core';

export const IconAIComponent = ({
  width = 40,
  height = 40,
  fillColor = '#713AE0',
}: IconProps): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
  >
    <path
      fill={fillColor}
      d="M9.957 2.002a1.246 1.246 0 0 0-1.121.853l-1.57 4.72-4.711 1.572a1.248 1.248 0 0 0-.855 1.186 1.252 1.252 0 0 0 .855 1.187l4.71 1.574 1.57 4.718a1.25 1.25 0 0 0 1.185.857 1.246 1.246 0 0 0 1.184-.857l1.57-4.72 4.711-1.572a1.248 1.248 0 0 0 .855-1.187 1.251 1.251 0 0 0-.855-1.186l-4.711-1.572-1.57-4.72a1.25 1.25 0 0 0-1.247-.853Zm.063 5.203.583 1.753a1.25 1.25 0 0 0 .79.791l1.75.584-1.75.585a1.246 1.246 0 0 0-.79.79l-.583 1.754-.583-1.753a1.25 1.25 0 0 0-.79-.791l-1.75-.585 1.75-.584a1.247 1.247 0 0 0 .79-.791l.583-1.753Zm16.17 2.297a1.247 1.247 0 0 0-1.138.865l-2.433 7.588-7.574 2.439a1.249 1.249 0 0 0-.626 1.925c.155.214.375.373.626.454l7.574 2.438 2.433 7.588a1.25 1.25 0 0 0 1.922.627 1.25 1.25 0 0 0 .454-.627l2.433-7.588 7.574-2.438a1.248 1.248 0 0 0 .865-1.19 1.252 1.252 0 0 0-.865-1.19l-7.574-2.438-2.433-7.587a1.25 1.25 0 0 0-1.238-.866Zm.05 5.332 1.443 4.497a1.25 1.25 0 0 0 .805.807l4.49 1.445-4.49 1.446a1.247 1.247 0 0 0-.805.807l-1.443 4.497-1.443-4.497a1.251 1.251 0 0 0-.806-.807l-4.488-1.446 4.488-1.445a1.247 1.247 0 0 0 .806-.807l1.443-4.497ZM8.709 22.835a1.247 1.247 0 0 0-1.121.853l-1.26 3.782-3.773 1.26a1.248 1.248 0 0 0-.855 1.187 1.252 1.252 0 0 0 .855 1.186l3.774 1.26 1.259 3.782a1.25 1.25 0 0 0 1.184.857 1.247 1.247 0 0 0 1.185-.857l1.257-3.782 3.776-1.26a1.249 1.249 0 0 0 .618-1.918 1.248 1.248 0 0 0-.618-.455l-3.774-1.261-1.26-3.781a1.25 1.25 0 0 0-1.247-.853Zm.063 5.202.272.817a1.25 1.25 0 0 0 .79.79l.813.273-.814.271a1.248 1.248 0 0 0-.79.792l-.27.817-.274-.817a1.25 1.25 0 0 0-.79-.791l-.813-.272.814-.272a1.248 1.248 0 0 0 .79-.791l.272-.817Z"
    />
  </svg>
);
