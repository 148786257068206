import {
  CompanyCdekColors,
  CompanyMBMColors,
  CompanyUuidEnum,
  ITheme,
  Nullable,
  UserAggregateModel,
} from '@lerna-core';
import { CoursesPageWithErrorModel } from './courses.model';

export const getCoursesCustomTheme = (
  props: CoursesPageWithErrorModel,
  theme: ITheme
): ITheme => {
  const discountColors = props.discountProgram?.colors;
  const companyId = props.company?.uuid;

  switch (companyId) {
    case CompanyUuidEnum.mbm:
      return {
        ...theme,
        COLOR: {
          ...theme.COLOR,
          PURPLE: CompanyMBMColors.secondaryColor,
          BUTTON_PURPLE_TEXT: '#FFFFFF',
          PURPLE_SUBSCRIPTION: CompanyMBMColors.secondaryColor,
        },
      };
    case CompanyUuidEnum.cdek:
      return {
        ...theme,
        COLOR: {
          ...theme.COLOR,
          PURPLE: CompanyCdekColors.secondaryColor,
          BUTTON_PURPLE_TEXT: '#FFFFFF',
          PRIMARY: {
            ...theme.COLOR.PRIMARY,
            500: CompanyCdekColors.secondaryColor,
          },
          PURPLE_SUBSCRIPTION: CompanyCdekColors.secondaryColor,
        },
      };
    default:
      return {
        ...theme,
        COLOR: {
          ...theme.COLOR,
          PURPLE: discountColors?.buttonAccentBackground || theme.COLOR.PURPLE,
          BUTTON_PURPLE_TEXT:
            discountColors?.buttonAccentColor || theme.COLOR.BUTTON_PURPLE_TEXT,
        },
      };
  }
};

export const getCoursesRequestPath = (
  user: Nullable<UserAggregateModel>,
  discountProgramQuery?: string
): string => {
  switch (true) {
    case !!discountProgramQuery:
      return `course-api/v1/catalog/courses/discount-programs/${discountProgramQuery}`;
    case !!user?.career?.companyId:
      return 'user-api/v1/catalog/courses';
    default:
      return 'course-api/v2/catalog/courses';
  }
};
