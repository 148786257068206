import styled from 'styled-components';
import {
  customizeScrollBar,
  fontSettings,
  lineClamping,
  transition,
} from '../../../../../styles';
import { getActiveStyles } from '../fastLinksMenuDesktop.mixins';
import { SPHERES_MAX_HEIGHT } from '../fastLinksMenuDesktop.styled';

export const Spheres = styled.div`
  border-right: 1px solid ${({ theme }) => theme.COLOR.BLACK_08};
  padding-right: 20px;
  margin: -8px -12px 0;
  max-height: ${SPHERES_MAX_HEIGHT};
  ${customizeScrollBar()};
`;

export const SpheresItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-bottom: 1px solid ${({ theme }) => theme.COLOR.BLACK_08};
`;

export const SpheresItem = styled.div<{ isActive?: boolean }>`
  display: flex;
  gap: 12px;
  padding: 12px;
  box-sizing: border-box;
  cursor: pointer;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  ${({ isActive, theme }) => getActiveStyles(theme, isActive)};
  ${transition('background-color', 0.2)};
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 16px;
  }
`;

export const SpheresItemImage = styled.div`
  height: 24px;
  width: 24px;
  flex: 0 0 auto;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const SpheresItemTitle = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
  font-weight: 500;
  ${lineClamping(1)};
`;
