import {
  Action,
  AnyAction,
  applyMiddleware,
  combineReducers,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit';
import { createWrapper, HYDRATE } from 'next-redux-wrapper';
import {
  companyReducer,
  currenciesReducer,
  faqReducer,
  fastLinksMenuReducer,
  filterReducer,
  popupsReducer,
  searchReducer,
  showcaseReducer,
  userMenuReducer,
  userReducer,
  vendorsReducer,
} from '@lerna-core';
import thunk from 'redux-thunk';
import { Reducer } from 'redux';

const combinedReducer = combineReducers({
  filters: filterReducer,
  search: searchReducer,
  user: userReducer,
  popups: popupsReducer,
  faq: faqReducer,
  company: companyReducer,
  vendors: vendorsReducer,
  currencies: currenciesReducer,
  showcase: showcaseReducer,
  userMenu: userMenuReducer,
  fastLinksMenu: fastLinksMenuReducer,
});

const reducer = (
  state: ReturnType<typeof combinedReducer>,
  action: AnyAction
) => {
  if (action.type === HYDRATE) {
    return {
      ...state,
      ...action.payload,
      // очень плохо, но баг в пакете next-redux-wrapper резетит стейт при роутинге с помощью getServerSideProps
      // https://github.com/kirill-konshin/next-redux-wrapper/issues/280
      popups: state.popups,
    };
  } else {
    return combinedReducer(state, action);
  }
};

export const makeStore = () =>
  configureStore({
    reducer: reducer as Reducer,
    devTools: process.env.NODE_ENV !== 'production',
    enhancers: [applyMiddleware(thunk)],
  });

export type Store = ReturnType<typeof makeStore>;

export type AppDispatch = Store['dispatch'];
export type RootState = ReturnType<Store['getState']>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export const wrapper = createWrapper(makeStore);
