export * from './defaultIcon.component';
export * from './iconSoftSkills.component';
export * from './iconAnalyticsDatascience.component';
export * from './iconEnglish.component';
export * from './iconEnvironmentDesign.component';
export * from './iconForChildren.component';
export * from './iconAnother.component';
export * from './iconGamedev.component';
export * from './iconMarketing.component';
export * from './iconFashionPhotography.component';
export * from './iconMedia.component';
export * from './iconCode.component';
export * from './iconProjectProduct.component';
export * from './iconSelfDevelopment.component';
export * from './iconSport.component';
export * from './iconBusinessManagement.component';
export * from './iconTeamManagement.component';
export * from './iconStaffManagement.component';
export * from './iconFinanceAccounting.component';
export * from './iconDigitalDesign.component';
export * from './iconAI.component';
export * from './peoplesIcon.component';
export * from './allSpheresIcon.component';
