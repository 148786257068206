import { NextRouter } from 'next/router';
import {
  CourseDurationModel,
  CourseModel,
  CourseOptionLogicTypes,
  CourseOptionsSlug,
  CoursesModel,
  getCoursePageHref,
  getCourseVendorById,
  getSvgSrc,
  VendorModel,
} from '../../features';
import { FullPresetsModel, mediaPreset } from '../../services';
import { ImageResourceModel, Nullable, PriceValueModel } from '../../models';
import {
  CourseMetaItemInstanceModel,
  CourseMetaItemModel,
  CourseMetaItemOffersModel,
  CourseMetaItemProviderModel,
  CourseMetaModel,
  CoursesMetaDurations,
  CoursesMetaModel,
  CoursesMetaTypes,
} from './headCoursesMeta.model';

const getCourseDefaultDescription = (
  course: CourseModel,
  vendor: Nullable<VendorModel>
): string => {
  const courseSphere = course.options.find(
    (option) =>
      option.logicType === CourseOptionLogicTypes.spheres &&
      option.slug !== CourseOptionsSlug.another
  );

  return `Онлайн-курсы${courseSphere ? ` ${courseSphere.value} |` : ''} ${
    course.title
  }${vendor?.title ? ` от ${vendor.title}` : ''}`;
};

export const getCourseImage = (
  mediaPresets?: FullPresetsModel,
  imageResource?: ImageResourceModel
): string => {
  if (mediaPresets && imageResource) {
    const isSvg = imageResource?.filename?.slice(-4) === '.svg' || false;

    switch (true) {
      case isSvg:
        return getSvgSrc(imageResource);
      default:
        const imageSet = mediaPreset.getImageSet(
          mediaPresets['logoCourseXBig'],
          imageResource
        );

        return imageSet?._3x || imageSet?._2x || imageSet?._1x || '';
    }
  }

  return '';
};

const mapDurationUnit = (unit: string): Nullable<CoursesMetaDurations> => {
  switch (true) {
    case unit === 'год':
      return CoursesMetaDurations.year;
    case unit === 'мес':
      return CoursesMetaDurations.month;
    case unit === 'нед':
      return CoursesMetaDurations.week;
    case unit === 'день':
    case unit === 'дня':
    case unit === 'дней':
      return CoursesMetaDurations.day;
    case unit === 'ч':
      return CoursesMetaDurations.hours;
    case unit === 'мин':
      return CoursesMetaDurations.minutes;
    default:
      return null;
  }
};

const getCourseWorkload = (
  durations: Nullable<CourseDurationModel[]>
): string => {
  if (durations) {
    return durations.reduce((acc, duration) => {
      acc = `${acc}${Math.ceil(
        +duration.amount.replaceAll(',', '.')
      )}${mapDurationUnit(duration.unit)}`;

      return acc;
    }, 'P');
  } else {
    return `P1${CoursesMetaDurations.month}`;
  }
};

const mapCourseMetaItemInstance = ({
  durations,
  startDateRaw,
}: CourseModel): CourseMetaItemInstanceModel => ({
  '@type': CoursesMetaTypes.courseInstance,
  courseMode: 'Online',
  courseWorkload: getCourseWorkload(durations),
  startDate: startDateRaw ? startDateRaw.split(' ')[0] : null,
});

const mapCourseMetaItemOffers = (
  price: PriceValueModel
): CourseMetaItemOffersModel => ({
  '@type': CoursesMetaTypes.offer,
  category: price.amount === '0' ? 'Free' : 'Paid',
  price: price.amount.replaceAll(' ', ''),
  priceCurrency: price.currency,
});

const mapCourseMetaProvider = (
  vendor: VendorModel
): CourseMetaItemProviderModel => ({
  '@type': CoursesMetaTypes.organization,
  name: `Онлайн-школа ${vendor.title}`,
});

const mapCourseMetaItemTag = (
  course: CourseModel,
  vendors: VendorModel[],
  router: NextRouter,
  mediaPresets?: FullPresetsModel
): CourseMetaItemModel => {
  const courseVendor = getCourseVendorById(vendors, course.vendorUuid);
  const image = getCourseImage(
    mediaPresets,
    course.logo?.imageResource ||
      courseVendor?.logo?.imageResource ||
      courseVendor?.coursePlug?.imageResource
  );

  return {
    '@type': CoursesMetaTypes.course,
    url: getCoursePageHref({ course, router, urlParams: {} }),
    image,
    name: course.title,
    description:
      course.description || getCourseDefaultDescription(course, courseVendor),
    hasCourseInstance: mapCourseMetaItemInstance(course),
    offers: mapCourseMetaItemOffers(course.price.price),
    provider: courseVendor ? mapCourseMetaProvider(courseVendor) : null,
  };
};

const mapCourseMetaTag = (
  course: CourseModel,
  vendors: VendorModel[],
  router: NextRouter,
  index: number,
  mediaPresets?: FullPresetsModel
): CourseMetaModel => ({
  '@type': CoursesMetaTypes.item,
  position: index + 1,
  item: mapCourseMetaItemTag(course, vendors, router, mediaPresets),
});

export const mapCoursesMetaTag = (
  courses: CoursesModel,
  vendors: VendorModel[],
  router: NextRouter,
  mediaPresets?: FullPresetsModel
): CoursesMetaModel => ({
  '@context': 'https://schema.org/',
  '@type': CoursesMetaTypes.list,
  numberOfItems: courses.meta.total,
  itemListElement: courses.data.map((course, index) =>
    mapCourseMetaTag(course, vendors, router, index, mediaPresets)
  ),
});
