import React, { ReactElement } from 'react';
import * as S from './courseItemMobile.styled';
import * as SS from '../courseItem.styled';
import { ImageContainer } from '../../../image';
import { VendorEnum } from '../../../vendors';
import { CourseItemProgressComponent } from '../courseItemProgress';
import { CourseItemComponentProps } from '../courseItem.model';
import { CertificateWarning } from '../../../certificate';

export const CourseItemMobileComponent = ({
  course,
  button,
  certificateButton,
  className,
}: CourseItemComponentProps): ReactElement => {
  const courseItemImageResource =
    course?.logo || course?.vendor?.coursePlug?.imageResource;

  return (
    <S.CourseItemMobile className={className}>
      <S.CourseItemMobileContent>
        <S.CourseItemMobileInfo>
          <SS.CourseItemTitle>{course.title}</SS.CourseItemTitle>
          <SS.CourseItemDescription>
            {course?.vendor && (
              <S.CourseItemMobileSubTitle>
                {course.vendor.title}
              </S.CourseItemMobileSubTitle>
            )}
            {course?.typeTitle ? (
              <S.CourseItemMobileSubTitle>
                {course.typeTitle}
              </S.CourseItemMobileSubTitle>
            ) : null}
          </SS.CourseItemDescription>
        </S.CourseItemMobileInfo>

        {courseItemImageResource && (
          <S.CourseItemMobileLogo>
            <ImageContainer
              imageResource={courseItemImageResource}
              //TODO: think about naming presets
              preset={'logoCourseSmall'}
            />
          </S.CourseItemMobileLogo>
        )}
      </S.CourseItemMobileContent>

      <CourseItemProgressComponent
        vendorSlug={course.vendor?.slug as VendorEnum}
        progress={course.progress}
      />
      <CertificateWarning course={course} />
      <S.CourseItemMobileButtons>
        {certificateButton}
        {button}
      </S.CourseItemMobileButtons>
    </S.CourseItemMobile>
  );
};
