import { NextRouter } from 'next/router';
import {
  CompanyUuidEnum,
  Nullable,
  QueryParams,
  urlService,
  UserAggregateModel,
} from '@lerna-core';
import { pageUrls } from '@constants';
import { getDiscountProgramPageUrl } from '../discountProgram';

export const getHomePageLink = (router: NextRouter): string => {
  const discountProgramQuery = router.query[
    QueryParams.discountProgram
  ] as string;

  if (discountProgramQuery) {
    return getDiscountProgramPageUrl(router, discountProgramQuery);
  }

  return urlService.getFullUrlToPage(pageUrls.courses, router);
};

export const isCollaborateWithLerna = (
  user: Nullable<UserAggregateModel>,
  isDiscountProgramPage: boolean
): boolean =>
  user?.career?.companyId === CompanyUuidEnum.wildberries &&
  !isDiscountProgramPage;
