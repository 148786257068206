import { ReactElement, useEffect } from 'react';
import { useTheme } from '../../../hooks';
import { FastLinksMenuMobileContainer } from './fastLinksMenuMobile';
import { FastLinksMenuDesktopContainer } from './fastLinksMenuDesktop';
import { CSSVariables, Nullable } from '../../../models';
import { browserService } from '../../../services';
import { BODY_CLASS_NAME, useUser } from '../../../features';
import { cleanDOM } from './fastLinksMenuDesktop/fastLinksMenuDesktop.helper';
import { useSelector } from 'react-redux';
import { getFastLinksMenuStatusSelector } from './fastLinksMenu.selectors';
import { FastLinksMenuPropsModel } from './fastLinksMenu.model';
import { canShowFastLinksMenu } from './fastLinksMenu.helper';
import { useRouter } from 'next/router';

export const FastLinksMenuContainer = ({
  filtersAccentColor,
  hasMobileSearch = false,
  top,
}: FastLinksMenuPropsModel): Nullable<ReactElement> => {
  const { IS_MOBILE } = useTheme();
  const { user } = useUser();
  const router = useRouter();
  const { isOpened } = useSelector(getFastLinksMenuStatusSelector);

  useEffect(() => {
    if (isOpened) {
      document.documentElement.style.setProperty(
        CSSVariables.scrollbarWidth,
        `${browserService.getScrollbarWidth()}px`
      );
      document.documentElement.style.setProperty(
        CSSVariables.scrollbarPlugWidth,
        `${browserService.getScrollbarWidth()}px`
      );
      document.body.classList.add(BODY_CLASS_NAME);
    } else {
      cleanDOM();
    }
  }, [isOpened]);

  if (!canShowFastLinksMenu(user, router)) {
    return null;
  }

  if (IS_MOBILE) {
    return (
      <FastLinksMenuMobileContainer
        hasSearch={hasMobileSearch}
        filtersAccentColor={filtersAccentColor}
      />
    );
  }

  return (
    <FastLinksMenuDesktopContainer
      top={top}
      filtersAccentColor={filtersAccentColor}
    />
  );
};
