import { Nullable } from '../../models';
import { CoursesModel } from '../../features';

export enum CoursesMetaTypes {
  list = 'ItemList',
  item = 'ListItem',
  course = 'Course',
  courseInstance = 'CourseInstance',
  offer = 'Offer',
  organization = 'Organization',
}

export enum CoursesMetaDurations {
  year = 'Y',
  month = 'M',
  week = 'W',
  day = 'D',
  hours = 'H',
  minutes = 'M',
}

export type CourseMetaItemProviderModel = {
  '@type': CoursesMetaTypes.organization;
  name: string;
};

export type CourseMetaItemOffersModel = {
  '@type': CoursesMetaTypes.offer;
  category: string;
  price: string;
  priceCurrency: string;
};

export type CourseMetaItemInstanceModel = {
  '@type': CoursesMetaTypes.courseInstance;
  courseMode: string;
  courseWorkload: string;
  startDate: Nullable<string>;
};

export type CourseMetaItemModel = {
  '@type': CoursesMetaTypes.course;
  url: Nullable<string>;
  image: string;
  name: string;
  description: string;
  hasCourseInstance: Nullable<CourseMetaItemInstanceModel>;
  offers: CourseMetaItemOffersModel;
  provider: Nullable<CourseMetaItemProviderModel>;
};

export type CourseMetaModel = {
  '@type': CoursesMetaTypes.item;
  position: number;
  item: CourseMetaItemModel;
};

export type CoursesMetaModel = {
  '@context': string;
  '@type': CoursesMetaTypes.list;
  numberOfItems: number;
  itemListElement: CourseMetaModel[];
};

export type HeadCoursesMetaModel = {
  courses: CoursesModel;
};
