import { GetServerSidePropsContext, NextPageContext } from 'next';
import { redirectClientProvider } from './redirect.clientProvider';
import { logger, urlService } from '../../services';

export const redirectMiddleware = async (
  ctx: NextPageContext | GetServerSidePropsContext
): Promise<NextPageContext | GetServerSidePropsContext> => {
  if (ctx.req?.url) {
    const url = urlService.getFullUrlFromToPage(ctx.req.url);

    await redirectClientProvider(url)
      .then(({ code, toUrl }) => {
        ctx.res?.writeHead(code, {
          Location: toUrl,
        });
        ctx.res?.end();
      })
      .catch((error) => {
        logger.error('[ERROR]: can not get redirect from api', error);
      });
  }

  return ctx;
};
