import styled from 'styled-components';
import { fontSettings, lineClamping } from '../../../../../styles';

export const Schools = styled.div`
  margin-top: 16px;
`;

export const SchoolsItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
`;

export const SchoolsItem = styled.div`
  display: flex;
  gap: 12px;
  cursor: pointer;
  align-items: center;
`;

export const SchoolsItemImage = styled.div`
  height: 48px;
  width: 48px;
  border-radius: 8px;
  overflow: hidden;
  flex: 0 0 auto;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const SchoolsItemTitle = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  font-weight: 500;
  ${lineClamping(2)};
`;
