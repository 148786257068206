import { SelectControlProps } from './selectControl.model';
import { Label } from '../../../label';
import { Select } from '../../../formInputs';
import { FormControl, FormControlError } from '../../../formControl';
import React, { ReactElement } from 'react';
import { InputColors } from '../../../../../styles';

export const SelectControlComponent = ({
  label,
  options = [],
  name,
  errorMessage,
  statusMessage,
  value,
  onChange,
  disabled,
  placeholder,
  touched = true,
  color = InputColors.white,
  bordered = true,
  optionsComponent,
  selectLabelComponent,
  floatingPlaceholder = true,
  directionOpenTop = false,
  dropdownHeight,
  className,
  withSearch,
  hasError,
}: SelectControlProps): ReactElement => {
  const withError =
    Boolean(errorMessage || statusMessage || hasError) && touched;

  return (
    <FormControl>
      {label && !floatingPlaceholder && <Label htmlFor={name}>{label}</Label>}
      <Select
        color={color}
        name={name}
        options={options}
        value={value}
        bordered={bordered}
        onChange={onChange}
        disabled={disabled}
        placeholder={floatingPlaceholder ? label : placeholder}
        optionsComponent={optionsComponent}
        selectLabelComponent={selectLabelComponent}
        floatingPlaceholder={floatingPlaceholder}
        hasError={withError}
        directionOpenTop={directionOpenTop}
        dropdownHeight={dropdownHeight}
        className={className}
        withSearch={withSearch}
      />
      {errorMessage && touched ? (
        <FormControlError>{errorMessage}</FormControlError>
      ) : null}

      {statusMessage && touched ? (
        <FormControlError>{statusMessage}</FormControlError>
      ) : null}
    </FormControl>
  );
};
