import React, { ReactElement } from 'react';

import { useTheme } from '../../../../hooks';
import {
  CloseTypes,
  IconCloseComponent,
  IconNextComponent,
  PopupCloseContainerStyled,
  PopupColorModeModel,
} from '../../../index';

type PopupCloseContainerProps = {
  handleClose: (e: React.MouseEvent<Element, MouseEvent>) => void;
  width?: number;
  height?: number;
  closeType?: string;
  isMobile: boolean;
  colorMode: PopupColorModeModel;
  closeOutside?: boolean;
  closePositionRight?: boolean;
  fullHeightMobile?: boolean;
};

export const PopupCloseContainer = ({
  handleClose,
  width = 24,
  height = 24,
  closeType,
  isMobile,
  colorMode,
  closeOutside,
  closePositionRight,
  fullHeightMobile = true,
}: PopupCloseContainerProps): ReactElement => {
  const theme = useTheme();

  const darkStrokeColor =
    isMobile || closeOutside
      ? theme.COLOR.TEXT_DARK_COLOR
      : theme.COLOR.TEXT_LIGHT_COLOR;

  const iconProps = {
    width,
    height,
    strokeColor: colorMode === 'dark' ? theme.COLOR.WHITE : darkStrokeColor,
    strokeWidth: closeOutside ? '3' : '2',
  };

  const rotateValue = closeType === CloseTypes.back ? '180' : '0';

  const renderIcon = (closeType?: string): ReactElement => {
    switch (closeType) {
      case CloseTypes.back: {
        return <IconNextComponent {...iconProps} />;
      }
      case CloseTypes.close: {
        return <IconCloseComponent {...iconProps} />;
      }
      default: {
        return <IconCloseComponent {...iconProps} />;
      }
    }
  };

  return (
    <PopupCloseContainerStyled
      onClick={handleClose}
      rotate={rotateValue}
      isMobile={isMobile}
      colorMode={colorMode}
      closeOutside={closeOutside}
      closePositionRight={closePositionRight}
      fullHeightMobile={fullHeightMobile}
    >
      {renderIcon(closeType)}
    </PopupCloseContainerStyled>
  );
};
