import { UserAvatarStyled } from '@lerna-core';
import styled from 'styled-components';
import { ProfileButtonContainerStyledProps } from './headerProfile.model';

export const ProfileButtonContainerStyled = styled.div<ProfileButtonContainerStyledProps>`
  display: flex;
  position: relative;

  & > button {
    white-space: nowrap;
    overflow: hidden;
    padding: 14px 13px;
    width: 54px;
    color: ${(props): string => props.theme.COLOR.TEXT_DARK_COLOR};

    & > ${UserAvatarStyled} {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

export const ProfileButtonLoginLinkTextStyled = styled.span`
  font-weight: 400;
`;
