import {
  BreadcrumbMetaItemModel,
  BreadcrumbMetaModel,
  BreadcrumbModel,
  BreadcrumbsMetaModel,
  BreadcrumbsMetaTypes,
} from './headBreadcrumbsMeta.model';

const mapBreadcrumbMetaItemTag = (
  breadcrumb: BreadcrumbModel
): BreadcrumbMetaItemModel => ({
  '@id': breadcrumb.link,
  name: breadcrumb.title,
});

const mapBreadcrumbMetaTag = (
  breadcrumb: BreadcrumbModel,
  index: number
): BreadcrumbMetaModel => ({
  '@type': BreadcrumbsMetaTypes.item,
  position: index + 1,
  item: mapBreadcrumbMetaItemTag(breadcrumb),
});

export const mapBreadcrumbsMetaTag = (
  breadcrumbs: BreadcrumbModel[]
): BreadcrumbsMetaModel => ({
  '@context': 'https://schema.org/',
  '@type': BreadcrumbsMetaTypes.list,
  itemListElement: breadcrumbs.map((breadcrumb, index) =>
    mapBreadcrumbMetaTag(breadcrumb, index)
  ),
});
