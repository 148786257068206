import { NextRouter } from 'next/router';
import { Nullable } from '../../models';
import { publicConfig, urlService } from '../../services';
import { UserAggregateModel } from '../user';
import { FasttrackContextModel } from './fasttrackWidget.model';

export const getFastTrackContext = (
  user: Nullable<UserAggregateModel>,
  router: NextRouter
): FasttrackContextModel => {
  const fastTrackContext: FasttrackContextModel = {
    region: publicConfig.REGION,
    url: urlService.getFullUrlFromReq(router),
  };
  if (user?.personal) {
    fastTrackContext.name = `${user.personal.firstName} ${
      user.personal.patronymic || ''
    } ${user.personal.lastName}`.trim();
    fastTrackContext.phone = user.personal.phone;
    fastTrackContext.email = user.personal.email;
    fastTrackContext.client_id = user.personal.uuid;
  }
  if (user?.career?.companyTitle) {
    fastTrackContext.company_name = user.career.companyTitle;
  }
  if (user?.career?.companyId) {
    fastTrackContext.company_id = user.career.companyId;
  }
  if (user?.career?.position) {
    fastTrackContext.user_position = user.career.position?.title;
  }
  if (user?.bonuses) {
    fastTrackContext.bonus = user.bonuses.points;
  }

  return fastTrackContext;
};
