import { SelectOptionType } from './select.model';

export const filterOptionsByTerm = (
  term: string,
  options: SelectOptionType[]
): SelectOptionType[] => {
  if (!term) {
    return options;
  }

  return options.filter((option) =>
    option.value.toLowerCase().includes(term.toLowerCase())
  );
};
