import styled from 'styled-components';
import { fontSettings } from '../../../styles';

export const CoursesItemCertificateWarning = styled.div`
  color: ${({ theme }): string => theme.COLOR.PRIMARY['500']};
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1 1 100%;
  padding: 8px 12px;
  border-radius: 8px;
  background-color: ${({ theme }): string => theme.COLOR.PRIMARY['100']};

  span {
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)};
    font-weight: 400;
  }
`;
