import {
  DiscountProgramContext,
  getAdditionalColors,
  Nullable,
  SliderContainer,
  SliderControlIcon,
  SliderControlStyle,
  useUser,
} from '@lerna-core';
import React, { memo, ReactElement, useContext, useMemo } from 'react';
import { useCoursesPageContext } from '../../../coursesPage';
import { getFilterTabsIcons } from '../filtersTabs.meta';
import { FilterTabsIconsEnum } from '../filtersTabs.model';
import { RowItemContainer } from './rowItem';
import { RowItemDefaultContainer } from './rowItemDefault';
import { TAB_ITEMS_MIN_LENGTH } from './tabsRow.constants';
import { useTabsRow } from './tabsRow.hook';
import { TabsRowContainerProps } from './tabsRow.model';
import { TabsRowStyled } from './tabsRow.styled';

const TabsRow = ({ filter }: TabsRowContainerProps): Nullable<ReactElement> => {
  const { advantages } = useCoursesPageContext();

  const discountProgram = useContext(DiscountProgramContext);
  const { user } = useUser();
  const { filtersAccentColor } = getAdditionalColors(
    discountProgram,
    user?.company?.uuid
  );
  const {
    isMobile,
    spaceBetweenItems,
    sliderActiveIndex,
    sliderKey,
    handleItemClick,
    handleDefaultItemClick,
    itemsToScroll,
  } = useTabsRow(filter);

  const filterTabsIcons = useMemo(
    () => getFilterTabsIcons(filtersAccentColor),
    []
  );
  const filterOptionsTabs: ReactElement[] = filter.options.map((option) => (
    <RowItemContainer
      key={option.slug}
      item={option}
      itemIcon={
        filterTabsIcons[option.slug as keyof typeof FilterTabsIconsEnum]
      }
      itemColor={filtersAccentColor}
      filter={filter}
      handleClick={handleItemClick}
    />
  ));
  const filterTabsWithDefault: ReactElement[] = [
    <RowItemDefaultContainer
      key="default"
      filter={filter}
      handleClick={handleDefaultItemClick}
    />,
    ...filterOptionsTabs,
  ];

  if (filterOptionsTabs.length < TAB_ITEMS_MIN_LENGTH || !filter.isVisible)
    return null;

  return (
    <TabsRowStyled>
      <SliderContainer
        key={sliderKey}
        isMobile={isMobile}
        items={filterTabsWithDefault}
        spaceBetweenItems={spaceBetweenItems}
        controlsStyle={SliderControlStyle.circle}
        controlIcon={SliderControlIcon.chevron}
        centeredList={!advantages}
        activeIndex={sliderActiveIndex}
        itemsToScroll={itemsToScroll}
        controlsInside
        controlsOutsideContainer
      />
    </TabsRowStyled>
  );
};

export const TabsRowContainer = memo(TabsRow);
