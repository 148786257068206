import { ReactElement } from 'react';
import { useTheme } from '../../../../hooks';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import {
  ButtonChildrenPosition,
  ButtonColor,
  ButtonContainer,
  ButtonSize,
  IconBurgerComponent,
  IconCloseV2Component,
  useTranslations,
  useUser,
} from '../../../../features';
import { getFastLinksMenuStatusSelector } from '../fastLinksMenu.selectors';
import { openFastLinksMenu } from '../fastLinksMenu.actions';
import * as S from './fastLinksMenuButton.styled';
import { FastLinksMenuButtonModel } from './fastLinksMenuButton.model';
import { Nullable } from '../../../../models';
import { canShowFastLinksMenu } from '../fastLinksMenu.helper';

export const FastLinksMenuButtonContainer = ({
  margin,
  isMobile = true,
}: FastLinksMenuButtonModel): Nullable<ReactElement> => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const router = useRouter();
  const { user } = useUser();
  const translations = useTranslations();
  const { isOpened } = useSelector(getFastLinksMenuStatusSelector);

  const handleClick = () => {
    dispatch(openFastLinksMenu(!isOpened));
  };

  if (!canShowFastLinksMenu(user, router)) {
    return null;
  }

  return (
    <S.FastLinksMenuButton margin={margin} isMobile={isMobile}>
      <ButtonContainer
        onClick={handleClick}
        color={ButtonColor.purple}
        borderRadius={100}
        childrenPosition={ButtonChildrenPosition.left}
        title={translations.catalog}
        size={theme.IS_MOBILE ? ButtonSize.small : ButtonSize.normal}
        children={
          isOpened ? (
            <IconCloseV2Component strokeColor={theme.COLOR.WHITE} />
          ) : (
            <IconBurgerComponent
              strokeWidth={theme.IS_MOBILE ? '2.4' : '1.8'}
              strokeColor={theme.COLOR.WHITE}
            />
          )
        }
      />
    </S.FastLinksMenuButton>
  );
};
