import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconWalletV2Component = ({
  width = 32,
  height = 32,
  strokeColor = '#FBF7FF',
  fillColor = '#FBF7FF',
}: IconProps): ReactElement => (
  <svg
    height={height}
    width={width}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      stroke={strokeColor}
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M21.2 7.818v11.71c0 .923-.749 1.672-1.673 1.672H4.473A1.673 1.673 0 0 1 2.8 19.527V6.145h16.727c.924 0 1.673.75 1.673 1.673Z"
    />
    <path
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M18.412 2.8H4.472a1.673 1.673 0 1 0 0 3.345"
    />
    <path
      fill={fillColor}
      d="M17.297 14.788a1.394 1.394 0 1 0 0-2.788 1.394 1.394 0 0 0 0 2.788Z"
    />
    <path
      stroke={strokeColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M2.8 6.982v-2.51"
    />
  </svg>
);
