import React, { ReactElement } from 'react';
import {
  CatalogPageTypes,
  MenuSwitcherContainer,
  Nullable,
  QueryParams,
  TranslationModel,
  UserAggregateModel,
} from '@lerna-core';
import { HeaderLinkTypes } from '../../header.model';
import { NextRouter } from 'next/router';
import { getHeaderDesktopTopLinks } from '../headerDesktop.meta';

export const getDesktopLinks = (
  linkType: HeaderLinkTypes,
  user: Nullable<UserAggregateModel>,
  userCoursesCount: number,
  translations: TranslationModel,
  pageName: Nullable<CatalogPageTypes>,
  router: NextRouter
): Nullable<ReactElement> => {
  const discountProgramQuery = router.query[
    QueryParams.discountProgram
  ] as string;

  switch (true) {
    case linkType === HeaderLinkTypes.switcher && !discountProgramQuery:
      const managerMenuSwitcherLinks = getHeaderDesktopTopLinks(
        user,
        userCoursesCount,
        translations,
        pageName
      );
      const defaultMenuSwitcherLinks = getHeaderDesktopTopLinks(
        user,
        userCoursesCount,
        translations,
        pageName,
        true
      );
      return (
        <>
          <MenuSwitcherContainer
            links={managerMenuSwitcherLinks}
            hiddenAfter="2xlargeDown"
          />
          <MenuSwitcherContainer
            links={defaultMenuSwitcherLinks}
            hiddenAfter="2xlargeUp"
          />
        </>
      );
    case linkType === HeaderLinkTypes.none:
    default:
      return null;
  }
};
