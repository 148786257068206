import { RequestClient } from '../../../services/request/request.service';
import { objectService } from '../../../services';
import { HelpLoggerModel } from './helpLogger.model';
import { mapHelpLoggerRequestParams } from './helpLogger.helper';

export class HelpLoggerClient extends RequestClient<FormData, any, any> {
  requestParameters: FormData;
  path =
    'AKfycbxbZuIOrj_F2WE1fMLseylNbefw2dYc_5t_jKBnQIeavEeo9s5TQEH8MnjrWksBmncaEg/exec';

  constructor(requestParams: HelpLoggerModel) {
    super(
      'https://script.google.com/macros/s/',
      undefined,
      {
        'Content-Type': `multipart/form-data`,
      },
      { withCredentials: false }
    );

    this.requestParameters = new FormData();

    const apiRequestParams = mapHelpLoggerRequestParams(requestParams);
    const flatRequestParams =
      objectService.deepFlattenToObject(apiRequestParams);

    for (const key in flatRequestParams) {
      this.requestParameters.append(key, flatRequestParams[key]);
    }
  }
}
