import styled from 'styled-components';
import Link from 'next/link';

export const ListItemWholeLinkContainerStyled = styled(Link)`
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-radius: 16px;
  z-index: ${({ theme }): number => theme.ZINDEX.TOP_BLOCK};
`;
