import React, { ReactElement } from 'react';
import { IconProps } from '../../../features';

export const IconEnglishComponent = ({
  width = 24,
  height = 24,
  fillColor = '#713AE0',
}: IconProps): ReactElement => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.904 4.167a1.25 1.25 0 0 0-1.148.786L5.794 7.36l-.327-.327a1.25 1.25 0 0 0-2.134.884v5.628a1.25 1.25 0 0 0 0 .405V25.9a1.25 1.25 0 0 0-.833 1.384v3.007A2.784 2.784 0 0 1 3.75 30c.433 0 .858.1 1.25.283v-1.95h5.833v3.168c1.175-.259 1.717-.71 1.734-.718.225-.208.483-.383.766-.508V28.26c.14-.049.27-.122.384-.216l5-4.167a1.25 1.25 0 1 0-1.601-1.92L12.5 25.803v-6.637h15v6.635l-4.616-3.846a1.25 1.25 0 1 0-1.601 1.921l5 4.167c.113.096.243.172.384.223v2.04c.25.126.475.284.683.476.092.075.667.5 1.817.734v-3.184H35v1.918a2.926 2.926 0 0 1 1.167-.243c.467 0 .925.117 1.333.326v-3.25c0-.545-.349-1.002-.833-1.174V7.917a1.25 1.25 0 0 0-2.134-.884l-.327.327-.962-2.407a1.25 1.25 0 0 0-2.321 0L29.96 7.36l-.327-.327a1.25 1.25 0 0 0-2.134.884V15h-15V7.917a1.25 1.25 0 0 0-2.134-.884l-.327.327-.962-2.407a1.25 1.25 0 0 0-1.173-.786Zm.013 4.616.506 1.264A1.25 1.25 0 0 0 10 10.76V15H5.833v-4.24a1.25 1.25 0 0 0 1.577-.713l.507-1.264Zm24.166 0 .507 1.264a1.25 1.25 0 0 0 1.577.713V15H30v-4.24a1.25 1.25 0 0 0 1.577-.713l.506-1.264ZM5.833 15H10v2.712a1.25 1.25 0 0 0 0 .405v7.716H5.833V15Zm6.667 0h15v1.667h-15V15ZM30 15h4.167v10.833H30v-7.711a1.253 1.253 0 0 0 0-.405V15ZM3.72 31.654a1.25 1.25 0 0 0-.84 2.16s.618.582 1.658 1.069 2.583.95 4.616.95c2.032 0 3.574-.463 4.614-.95.494-.232.506-.306.79-.508.282.202.293.277.787.508 1.04.487 2.582.95 4.614.95 2.033 0 3.576-.463 4.616-.95.494-.232.505-.306.788-.508.283.202.295.276.79.508 1.04.487 2.581.95 4.614.95 2.032 0 3.574-.463 4.614-.95 1.04-.487 1.66-1.07 1.66-1.07a1.248 1.248 0 0 0-.367-2.058 1.251 1.251 0 0 0-1.374.265s-.24.25-.979.597c-.74.347-1.898.716-3.554.716s-2.816-.37-3.555-.716c-.74-.346-.978-.597-.978-.597a1.25 1.25 0 0 0-1.742 0s-.238.25-.978.597-1.899.716-3.555.716c-1.656 0-2.815-.37-3.554-.716-.74-.346-.977-.597-.977-.597a1.25 1.25 0 0 0-1.743 0s-.237.25-.977.597-1.899.716-3.554.716c-1.656 0-2.815-.37-3.555-.716-.74-.346-.978-.597-.978-.597a1.25 1.25 0 0 0-.902-.366Z"
        fill={fillColor}
      />
    </svg>
  );
};
