import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '../../../../../styles';
import { rgba } from 'polished';

export const FooterCyberPark = styled.div`
  padding-bottom: 24px;

  svg {
    margin-bottom: 16px;
  }

  ${breakpoint(
    'mediumDown',
    css`
      align-items: center;
      text-align: center;
    `
  )}
`;

export const FooterCyberParkText = styled.p`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_11, theme.FONT)};
  color: ${({ theme }): string => rgba(theme.COLOR.WHITE, 0.7)};
  white-space: pre-line;
  margin: 4px 0 0;

  b {
    font-weight: 600;
    color: ${({ theme }): string => theme.COLOR.WHITE};
  }
`;
